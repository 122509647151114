import betterFetch from "./betterFetch";

export const totalPart = ({basePart, dailyPart}, quantity, duration) => {
    return (basePart + dailyPart * duration) * quantity;
};

export const totalPrice = ({basePrice, dailyPrice}, quantity, duration) => {
    return {
        ht: totalPart({basePart: basePrice.ht, dailyPart: dailyPrice.ht}, quantity, duration),
        tax: totalPart({basePart: basePrice.tax, dailyPart: dailyPrice.tax}, quantity, duration)
    };
};

export const supplierPrice = (params) => betterFetch('/api/supplier-price', {
    qs: params
}).then(response => {
    if (!response.ok) {
        throw response.statusText;
    }
    return response.json();
});