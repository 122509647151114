<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgServietteX20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M12.7,44l2.1-2.8c0.3-0.4,0.2-1.1-0.2-1.4c-0.4-0.3-1.1-0.2-1.4,0.2l-3.3,4.5v-9.3h3.4l3.9,5.3c0.1-0.9,0.3-1.8,0.5-2.6
 l-2-2.7h3.1c0.4-0.8,1-1.4,1.7-2H8.4c-0.3,0-0.5-0.2-0.5-0.5v-3.2c0-0.3,0.2-0.5,0.5-0.5H24c0.3,0,0.5,0.2,0.5,0.5v2.1
 c0.7-0.2,1.4-0.3,2-0.4v-1.6c0-1.2-0.9-2.2-2-2.5V16.6c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1
 L17,5.1c-0.4-0.6-1.3-0.6-1.7,0L8,16c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1V27
 c-1.1,0.3-2,1.3-2,2.4v3.2c0,1.2,0.8,2.2,2,2.4v11.6c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1
 l7.3,10.9c0.2,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6-0.2,0.8-0.4l1-1.5c-0.4-1.9-0.8-4.1-1-6.6L12.7,44z M22.5,26.9h-7.1l7.1-8.5V26.9z M16.2,7.5L17.3,9l-5.8,8l-0.9-1.1c0,0,0,0,0,0L16.2,7.5z M9.9,18.3l3.3,4c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2
 c0.4-0.4,0.5-1,0.1-1.4l-2-2.4l5.7-7.8l3.5,5.2l-9.2,10.9H9.9V18.3z M16.2,55.8l-5.9-8.7c0.1-0.1,0.2-0.1,0.2-0.2l0.9-1.2l6,8.3
 L16.2,55.8z"/>
  <path d="M44.2,50.9l-1,0.4c0,0,0,0,0,0L36,53.9c0.3-1.8-0.4-3.1-1.3-4.1l1.8-0.6c-2.4-1.1-4.4-2.7-6.1-4.7l-7.5-2.3
 c-0.5-0.2-1.1,0.1-1.3,0.7s0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5c0,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.3-0.9-0.3
 l-8.8-2.7c-0.1,0-0.4-0.1-0.9-0.2c-1.1-0.6-1.4-1.4-1.1-3.1c0.2-1.2,1.1-1.3,1.5-1.3l6.8,2.1c-0.5-0.8-1-1.6-1.4-2.5l-5-1.6l-0.1,0
 c-0.2,0-0.9-0.3-1.1-0.6c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l5.1,1.6c-0.2-0.7-0.4-1.5-0.6-2.3
 l-1.7-0.5l1.5-0.6c-0.1-0.7-0.1-1.3-0.1-2c0,0,0,0,0,0l-5.4,2c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8
 c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2,0.1,3.4,1.1,4.4c-0.2,0.3-0.3,0.6-0.4,1
 c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2
 c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1
 c0.5-0.4,1.1-1,1.3-2c-0.7,0.1-1.5,0.2-2.3,0.2C44.4,50.8,44.3,50.9,44.2,50.9z M34,53.8c-0.1,0.5-0.3,0.9-0.6,1.1
 c-0.3,0.2-0.9,0.2-1.7-0.1L22.9,52c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5
 c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7
 c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0c-0.2,0-0.9-0.3-1.1-0.6c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.3,0.2-0.4
 c0.2,0.1,0.4,0.1,0.6,0.2l8.9,2.7c0.1,0,0.5,0.2,1,0.2C33.1,50.9,34.4,51.9,34,53.8z M43.7,54.3l-8.8,3.2c0-0.4-0.1-0.7-0.3-0.9
 l8.8-3.2C43.9,53.6,43.8,53.8,43.7,54.3z"/>
  <path d="M43.7,18.7c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9S51.9,18.7,43.7,18.7z M43.7,46.5
 c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9S50.8,46.5,43.7,46.5z"/>
  <path d="M36.3,35.5l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0-0.4,0.1s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2
 c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4
 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5
 c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L36.3,35.5z"/>
  <path d="M45.2,37h-3.7c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.3,0.8-0.8,1.5-1.4c0.7-0.6,1.2-1,1.5-1.3c0.3-0.3,0.6-0.6,0.9-1.1
 c0.3-0.5,0.4-1,0.4-1.6c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.3-0.3-0.7-0.6-0.9s-0.5-0.5-0.9-0.6c-0.5-0.2-1.1-0.3-1.8-0.3
 c-0.6,0-1.1,0.1-1.6,0.3c-0.4,0.2-0.8,0.4-1.1,0.7s-0.5,0.6-0.7,1s-0.2,0.7-0.2,1c0,0.3,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2
 c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.3-0.4,0.4-0.7s0.2-0.5,0.2-0.6c0.4-0.5,0.8-0.8,1.5-0.8c0.3,0,0.6,0.1,0.8,0.2
 c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.6-0.6,0.8c-0.3,0.3-0.6,0.6-1,0.8
 c-0.2,0.1-0.6,0.5-1.1,0.9c-0.5,0.5-1.1,1-1.7,1.7c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.7c0,0.3,0.1,0.5,0.3,0.7
 c0.2,0.2,0.5,0.3,0.8,0.3h5.2c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.5-0.3-0.6C45.9,37.1,45.6,37,45.2,37z"/>
  <path d="M54.3,30.3c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5s-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C54.8,32.1,54.7,31,54.3,30.3z M52.8,35.6c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3c-0.1-0.6-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4
 c0.2,0.3,0.4,0.7,0.5,1.2s0.2,1.3,0.2,2.1C52.9,34.3,52.8,35.1,52.8,35.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "serviettex20"
    }
</script>

<style scoped>

</style>