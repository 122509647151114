import jsonFetch from "../jsonFetch";
import RentalStore from "../store/RentalStore";
import {supplierPrice, totalPrice} from "../price";
import CartStore from "../store/CartStore";
import computeDuration from "./computeDuration";
import tryGoingToCart from "../tryGoingToCart";

const renewOrder = async secret => {
    const order = await jsonFetch(`/api/users/orders/${secret}`);

    RentalStore.merge({
        address: {
            lat: order.temp_lat,
            lng: order.temp_long,
            street: order.delivery_street,
            zip: order.delivery_zip,
            city: order.delivery_city,
        },
        supplier: order.supplier_id,
    });

    const {from, to} = RentalStore.get();

    const products = [...order.products, ...order.bundles].map(p => ({...p, id: p.product_id}));

    const productsWithPrice = await Promise.all(products.map(async product => {
        const price = await supplierPrice({
            supplierId: order.supplier_id,
            productId: product.id,
        });

        return {...product, price};
    }));

    CartStore.empty();
    productsWithPrice.forEach(product => {
        CartStore.addProduct(product.type === 'bundle', {
            ...product,
            totalPrice: totalPrice(product.price, product.qty, computeDuration(from, to)),
        });
    });

    tryGoingToCart();
};

export default renewOrder;