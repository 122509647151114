<template>
    <div class="form-group">
        <label :for="name">{{label}} le <strong>{{ dateToLocaleString(deliveryDate)}}</strong> à :</label>
        <select :name="name" :id="name" class="form-control" v-model="selected" :required="required">
            <option v-bind:key="timeFrame.value" v-for="timeFrame in timeFrames" :value="timeFrame.value">{{timeFrame.name}}</option>
        </select>
        <p class="help-block"></p>
    </div>
</template>
<script>
    import dateToLocaleString from '../dateToLocaleString';
    export default {
        name: 'timeFrameSelect',
        props: {
            deliveryDate: {},
            timeFrames: {},
            selectedValue: String,
            label: String,
            name: String,
            help: String,
            required: Boolean
        },
        data() {
            return {
                selected: this.selectedValue !== null ? this.selectedValue : null,
            };
        },
        methods: {
            dateToLocaleString: dateToLocaleString
        }
    }
</script>
