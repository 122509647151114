<template>
    <div class="panel panel-default">
        <Collapse>
            <template v-slot:toggle>
                <div class="panel-heading">
                    <a class="hlink">
                        <table>
                            <thead>
                            <tr>
                                <th>
                                    <i class="fa fa-caret-down"/>
                                    <slot name="heading" />
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </a>
                </div>
            </template>

            <template v-slot:body>
                <div class="panel-body">
                    <slot name="body" />
                </div>
            </template>
        </Collapse>
    </div>
</template>

<script>
    import Collapse from "./Collapse";
    export default {
        name: "TableCollapse",
        components: {Collapse},
    };
</script>

<style scoped>

</style>