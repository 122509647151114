import Vue from 'vue';
import UserSpecialCommissions from "./components/admin/UserSpecialCommissions";
import pushCartTag from './tracking/pushCartTag';
import pushDeliveryTag from "./tracking/pushDeliveryTag";
import pushPaymentTag from "./tracking/pushPaymentTag";
import DeliverySelect from './components/DeliverySelect';
import RadioWrapper from './components/RadioWrapper';
import ouibounce from 'ouibounce';
import DeliverySelectedStore from "./store/DeliverySelectedStore";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";
import configureDatePickerJquery from "./configureDatePickerJquery";
import RentalStore from "./store/RentalStore";
import floatLabel, {focusSearch} from './front';
import openSelect from './openSelect';
import collapseMobile from './collapseMobile';
import sliderSlick from './slider';
import menu from './menu';
import dropdown from "./dropdown";
import ProductVariants from "./components/ProductVariants";
import CartHeaderPicto from "./components/CartHeaderPicto";
import CartStore from "./store/CartStore";
import StayInformedModal from "./components/StayInformedModal";
import tryGoingToCart from "./tryGoingToCart";
import './components/icons/iconImports';
import IconSelect from "./components/admin/IconSelect";
import SimpleBar from 'simplebar';
import moment from "moment";
import "./misc/translator";
import SessionStorageStore from "./store/SessionStorageStore";
import QuantityInput from "./components/QuantityInput";
import RgpdMention from "./components/RgpdMention";
import NetworkMap from "./components/network/NetworkMap";
import StayInformedForm from "./components/StayInformedForm";
import cookieListener from "./cookieListener";
import gmapStyle from "./gmapStyle";
import FlowPage from "./components/flow/FlowPage";
import loadCustomerProductFlow from "./flow/loadCustomerProductFlow";
import submitOnce from "./submitOnce";
import renewOrder from "./ordering/renewOrder";
import {generate, randomPassword} from "./misc/generatePassword";
import loadLaundryProductFlow from "./flow/loadLaundryProductFlow";
import {logger, registerGlobalErrorLogger} from "./logger";

registerGlobalErrorLogger(logger);

moment.locale(window.navigator.userLanguage || window.navigator.language);

configureDatePickerJquery($.datepicker);

Vue.config.productionTip = false;

// On assigne les variables à window
// Cela permet de les utiliser n'importe où, par exemple depuis le html
Object.assign(window, {
    Vue,
    DeliverySelect,
    RadioWrapper,
    ouibounce,
    DeliverySelectedStore,
    RentalStore,
    $,
    jQuery: $,
    pushCartTag,
    pushDeliveryTag,
    pushPaymentTag,
    ProductVariants,
    CartHeaderPicto,
    CartStore,
    StayInformedModal,
    tryGoingToCart,
    IconSelect,
    UserSpecialCommissions,
    SessionStorageStore,
    QuantityInput,
    NetworkMap,
    StayInformedForm,
    gmapStyle,
    moment,
    FlowPage,
    renewOrder,
    generate,
    randomPassword,
    focusSearch,
    loadCustomerProductFlow,
    loadLaundryProductFlow,
    logger,
});

require('bootstrap');
require('bootstrap-select');
require('fullcalendar');
require('fullcalendar/dist/locale/fr');

$(document).ready(function () {

//FLOAT LABEL
    floatLabel();

    // Open Select Langage
    openSelect();

    // collapse Mobile
    if (window.matchMedia("(max-width: 1024px)").matches) {
        collapseMobile('.footerListMobile .titleFooterTop', '.footerListMobile', 'active');
    }


    // SLIDER
    sliderSlick();

    // MENU
    menu();

    // DROPDOWN
    dropdown();

    // Exemple d'utilisation: <span data-vue-component="draps"></span>
    // Affichera l'icône draps
    // Ne marche que pour les composants globaux (par défaut les composants son locaux)
    document.querySelectorAll('[data-vue-component]').forEach(
        element => new Vue({render: h => h(element.dataset.vueComponent)}).$mount(element)
    );

    document.querySelectorAll('[data-rgpd-mention]').forEach(
        element => new Vue({render: h => h('RgpdMention'), components: {RgpdMention}}).$mount(element)
    );

    //SIMPLEBAR
    document.querySelectorAll('[data-scrollbar]').forEach(
        element => new SimpleBar(element, { autoHide: false })
    );

    document.querySelectorAll('[data-date]').forEach(element => {
            element.textContent = moment(element.dataset.date, element.dataset.formatFrom).format(element.dataset.formatTo);
        }
    );

    document.querySelectorAll('[data-email-user]').forEach(
        element => element.innerHTML = `${element.dataset.emailUser}@${element.dataset.emailDomain}`
    );

    submitOnce();
});

const resetRental = () => RentalStore.set({});
const hubspotVisitorTracking = function() {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(["identify",{
        email: $('.user-information').data('email'),
    }]);
};

cookieListener
    .on('loggedOut', resetRental)
    .on('registered', resetRental)
    .on('registered', hubspotVisitorTracking)
    .on('changedCustomer', resetRental)
    .handle()
;
