<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgLinteaux50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M40.3,17.1c0,0.5,0.3,0.9,0.6,1.2c0.6-0.3,1.3-0.5,2-0.7c0.1-0.2,0.1-0.3,0.1-0.5c0-0.8-0.6-1.4-1.4-1.4
  C40.9,15.7,40.3,16.4,40.3,17.1z M41.9,17.1c0,0.3-0.5,0.3-0.5,0c0-0.1,0.1-0.2,0.2-0.2C41.8,16.9,41.9,17,41.9,17.1z"/>
<path d="M21.8,22.5V17c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v7C20.2,23.3,21,22.8,21.8,22.5z"/>
<path d="M59.2,17.8c0-1.5-1.2-2.7-2.7-2.7H45.8c-0.6-1.4-2-2.4-3.6-2.4h-4.9v1.6h-1.9c-0.5-1.7-1.7-2.9-3-2.9H2.7
  c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.3-0.8,0.6-1.1,1.1C0.2,13,0.1,13.2,0,13.4v3l6,0v0.1v12.1H0v2.2h6v1.1H0v2.9
  c0.5,0.6,1.3,1,2.2,1H5c0.3,0,0.7-0.1,1-0.2v10.2c0,1.3,1,2.3,2.3,2.3h9c0-0.5-0.1-1.1-0.1-1.6c0-0.1,0-0.4-0.1-0.6H8.3
  c0,0-0.1,0-0.1-0.1V16.5c0-1.1-0.2-2.1-0.5-3h24.6c0.3,0.1,1.1,1.1,1.1,3v3.6c0.8,0,1.5-0.1,2.2-0.1v-3.5c0,0,0,0,0-0.1h1.5v3.7
  c0.1,0,0.2,0,0.3,0c0.6-0.4,1.3-0.8,1.9-1.2v-4.1h2.6c0.9,0,1.7,0.8,1.7,1.7v0.8c1.2-0.3,2.4-0.4,3.6-0.4c1.1,0,2.1,0.1,3.1,0.3h5.8
  c0.3,0,0.5,0.2,0.5,0.5c0,0.2-0.1,0.3-0.2,0.4l0,0h-2.9c1.8,0.7,3.4,1.7,4.8,2.9c0-0.1,0-0.2,0-0.3c0-0.4-0.1-0.8-0.2-1.1
  C58.9,19.2,59.2,18.5,59.2,17.8z M3.3,11.4C3.3,11.4,3.3,11.4,3.3,11.4C3.4,11.4,3.4,11.4,3.3,11.4c0.1,0,0.1,0,0.2,0.1
  C3.4,11.4,3.3,11.4,3.3,11.4z"/>
<g>
  <path d="M31.6,25.5c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6c0,0,0.1,0,0.1,0c0.4-0.9,1-1.8,1.6-2.7L31.6,25.5z"/>
  <path d="M24,32.6c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l7.2,2.2c-0.1-0.7-0.1-1.3-0.1-2c0,0,0-0.1,0-0.1L24,32.6z"/>
  <path d="M47.8,51.9c0,0-0.1,0-0.1,0c-0.7,0-1.3,0-2-0.1c-0.1,0.2-0.2,0.2-0.3,0.3l-1,0.4c0,0,0,0,0,0l-7.1,2.6
 c0.3-2.1-0.6-3.6-1.8-4.6l3.2-1.2c-0.7-0.4-1.3-0.9-2-1.4l-0.6,0.2c0-0.4-0.1-0.7-0.3-0.9l0.1,0c-0.5-0.5-1-1-1.5-1.6
 c-0.3,0.1-0.8,0-1.4-0.1L24,42.7c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5
 c-0.1,0.1-0.7,0.1-1.1-0.1L24,46c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.3,0.1,0.6,0,0.8-0.1
 c-0.4-0.7-0.8-1.4-1.1-2.1l-8-2.5l-0.1,0c-0.2,0-0.9-0.3-1.1-0.6c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.4,0.2-0.4
 c0.1-0.1,0.4,0,0.5,0l8,2.5c-0.3-0.7-0.5-1.5-0.6-2.3L24,36c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l7,2.2
 c0.1-0.7,0.1-1.4,0.3-2L24,29.3l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l7.7,2.4
 c0.2-0.6,0.4-1.3,0.6-1.9l-5.5-1.7l7.5-2.7c0.1,0,0.4,0,0.5,0l0,0c0.5-0.6,1-1.1,1.5-1.6L34.9,22c-0.1-0.1-1-0.3-1.8,0c0,0,0,0,0,0
 c0,0,0,0,0,0l-10.9,3.9c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2,0.1,3.5,1.2,4.5c-0.2,0.3-0.4,0.7-0.5,1.2c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2.2,0.1,3.7,1.5,4.7c-0.3,0.3-0.6,0.7-0.7,1.4c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1
 c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1
 C47.1,53.4,47.6,52.8,47.8,51.9z M35.2,54.9c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7-0.1l-8.9-2.7c-0.5-0.2-1.1,0.1-1.3,0.7
 c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7c0,0,0,0,0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5
 c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0c-0.1,0-1.3-0.3-1.2-1
 c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8C32.6,51.2,35.8,52.1,35.2,54.9z M44.9,55.4l-8.8,3.2c0-0.4-0.1-0.7-0.3-0.9
 l8.8-3.2C45.1,54.8,45,55,44.9,55.4z"/>
</g>
<g>
  <path d="M47.7,48.9c-8.2,0-14.9-6.7-14.9-14.9s6.7-14.9,14.9-14.9S62.6,25.8,62.6,34S55.9,48.9,47.7,48.9z M47.7,21.1
 c-7.1,0-12.9,5.8-12.9,12.9s5.8,12.9,12.9,12.9c7.1,0,12.9-5.8,12.9-12.9S54.8,21.1,47.7,21.1z"/>
</g>
<g>
  <path d="M40.4,38.6L39.3,37l-1.1,1.6c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.4
 c0-0.2,0.1-0.4,0.3-0.7l1.4-2l-1.2-1.6c-0.2-0.3-0.3-0.5-0.3-0.7c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.2
 c0.2,0,0.3,0,0.4,0.2s0.3,0.3,0.4,0.5l1,1.4l1-1.4c0.2-0.2,0.3-0.4,0.4-0.5s0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.5,0.2s0.2,0.2,0.2,0.4
 c0,0.1,0,0.2-0.1,0.3s-0.1,0.2-0.2,0.4L40.3,36l1.4,2c0.2,0.3,0.3,0.5,0.3,0.7c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2
 c-0.2,0-0.3-0.1-0.4-0.2C40.7,39,40.6,38.8,40.4,38.6z"/>
  <path d="M49,30.5h-3.4l-0.4,2.3c0.7-0.4,1.3-0.5,1.9-0.5c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.5,0.7,0.7,1.1
 c0.2,0.4,0.3,0.9,0.3,1.3c0,0.7-0.2,1.3-0.5,1.9c-0.3,0.6-0.7,1-1.3,1.3c-0.6,0.3-1.2,0.5-2,0.5c-0.8,0-1.5-0.2-2.1-0.5
 s-0.9-0.7-1.1-1.1s-0.3-0.8-0.3-1.1c0-0.2,0.1-0.4,0.2-0.5c0.2-0.2,0.3-0.2,0.6-0.2c0.4,0,0.7,0.2,0.9,0.7c0.2,0.4,0.4,0.7,0.8,1
 c0.3,0.2,0.7,0.4,1.1,0.4c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.6-0.8c0.1-0.3,0.2-0.7,0.2-1.1c0-0.5-0.1-0.8-0.2-1.2
 c-0.2-0.3-0.4-0.6-0.6-0.7c-0.3-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.7,0-0.9,0.1c-0.2,0.1-0.4,0.3-0.8,0.5s-0.6,0.4-0.8,0.4
 c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0,0,0-0.2,0.1-0.5L44,30c0.1-0.4,0.2-0.7,0.4-0.9c0.2-0.2,0.5-0.3,0.9-0.3H49
 c0.7,0,1.1,0.3,1.1,0.8c0,0.2-0.1,0.4-0.3,0.6C49.6,30.4,49.3,30.5,49,30.5z"/>
  <path d="M58.8,34c0,0.8,0,1.4-0.1,2c-0.1,0.6-0.3,1.1-0.5,1.5c-0.3,0.6-0.7,1-1.2,1.3c-0.5,0.3-1.1,0.5-1.7,0.5
 c-0.7,0-1.4-0.2-1.9-0.6c-0.6-0.4-1-1-1.3-1.7c-0.1-0.4-0.3-0.8-0.3-1.3c-0.1-0.5-0.1-1-0.1-1.5c0-0.7,0-1.4,0.1-1.9
 c0.1-0.6,0.2-1.1,0.4-1.5c0.3-0.7,0.7-1.2,1.2-1.6c0.5-0.4,1.2-0.5,1.9-0.5c0.5,0,0.9,0.1,1.3,0.2s0.7,0.4,1,0.7s0.6,0.7,0.8,1.1
 C58.7,31.5,58.8,32.6,58.8,34z M56.9,33.9c0-0.9-0.1-1.6-0.2-2.1s-0.3-1-0.5-1.2c-0.2-0.3-0.6-0.4-1-0.4c-0.6,0-1.1,0.3-1.3,0.9
 s-0.4,1.6-0.4,2.9c0,0.9,0.1,1.6,0.2,2.2s0.3,1,0.5,1.3c0.2,0.3,0.6,0.4,1,0.4c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.4-0.7,0.5-1.3
 C56.8,35.5,56.9,34.8,56.9,33.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "linteaux50"
    }
</script>

<style scoped>

</style>