<template>
    <div>
        <select :value="value" @input="value = $event.target.value;$emit('input', $event.target.value)" name="icon">
            <option :key="icon" :value="icon" v-for="icon in icons">
                {{ icon }}
            </option>
        </select>
        <span class="iconContainer">
            <component v-if="value" :is="value" />
        </span>
    </div>
</template>

<script>
    export default {
        name: "IconSelect",
        props: {
            value: String
        },
        data() {
            const icons = ["chambre", "commande", "couettes", "couette-s", "couette-l", "couette-xl", "drapbain", "draps", "drap-s", "drapl", "drapplage", "drapxl", "essuieverrex10", "essuieverrex20", "essuieverrex50", "essuieverre", "houssel", "housses", "houssexl", "housse", "kitbaindoublepeignoire", "kitbaindouble", "kitbainsimplepeignoire", "kitbainsimple", "kitcuisine", "kitlit", "kitlitl", "kitlits", "kitlitxl", "kittable12", "kittable8", "kittable", "kittorchons", "kit", "linteauverretorchon", "linteaux10", "linteaux20", "linteaux50", "liteau", "nappe", "nappe100", "nappe130", "nappe140", "nappe160", "nappe180", "nappe210", "nappe240", "oreillercarre", "oreillerrectangle", "paiement", "peignoire", "produit", "recuperation", "restaurant", "restitution", "salledebain", "serviettebain", "serviettetable", "serviettex10", "serviettex20", "serviettex50", "tablierchef", "tablier", "tapisbain", "torchonx10", "torchonx20", "torchonx50", "torchon", "couvertures", "couverture-s", "couverture-l", "couverture-xl"];
            return {
                icons
            }
        }
    }
</script>

<style scoped>
    .iconContainer {
        margin-left: 10px;
        vertical-align: middle;
        display: inline-block;
        width: 120px;
        height: 120px;
        background-color: #fff;
        border-radius: 50% 50% 0 0;
        text-align: center;
    }
</style>