<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgHoussel" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M47,30c1.4,0,2.5-1.1,2.5-2.5v-7.3c0-1.4-1.1-2.5-2.5-2.5H11c-1.4,0-2.5,1.1-2.5,2.5v7.3c0,1.4,1.1,2.5,2.5,2.5H47z"/>
  <path d="M11.7,9.8c0-0.8,0.7-1.5,1.5-1.5h1.4v4.6c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h1.1v4.6
 c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h0.9c0.8,0,1.5,0.7,1.5,1.5v6.5h2V9.8c0-1.9-1.6-3.5-3.5-3.5h-1
 c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.2c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.5
 c-1.9,0-3.5,1.6-3.5,3.5v6.5h2V9.8z M31.8,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V6.8z M16.7,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V6.8z"/>
  <path d="M42.1,32.2c1.8,0,3.5,0.3,5.1,0.9v-1.7H10.7V48c0,1.4,1.1,2.5,2.5,2.5h13.9c-0.2-1-0.3-2-0.3-3.1
 C26.9,39,33.7,32.2,42.1,32.2z"/>
  <path d="M42.1,34.1c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C55.5,40.1,49.5,34.1,42.1,34.1z M42.1,58.8
 c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4s11.4,5.1,11.4,11.4S48.4,58.8,42.1,58.8z"/>
  <path d="M45.3,50.7h-4.1v-7.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.5-0.3,0.9v7.7
 c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3h4.9c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.3-0.6
 C45.9,50.8,45.7,50.7,45.3,50.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "houssel"
    }
</script>

<style scoped>

</style>