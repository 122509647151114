import RentalStore from "./store/RentalStore";
import formPost from "./formPost";
import csrfToken from "./csrfToken";
import {focusSearch} from "./front";

const tryGoingToCart = () => {
    const {
        supplier,
        from,
        to,
        address
    } = RentalStore.get();

    if (!supplier) {
        focusSearch();
        return;
    }

    if (supplier) {
        formPost(document.querySelector('[data-cart-url]').dataset.cartUrl, {
            supplier,
            start: from,
            end: to,
            address: address ? `${address.street}, ${address.city}, France` : '',
            delivery_street: address ? address.street : '',
            delivery_zip: address ? address.zip : '',
            delivery_city: address ? address.city : '',
            delivery_lat: address ? address.lat : '',
            delivery_long: address ? address.lng : '',
            _token: csrfToken()
        });
        return;
    }

    window.stayInformedModal.show();
};

export default tryGoingToCart;
