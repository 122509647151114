<template>
    <Modal ref="modal">
        <StayInformedForm />
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import StayInformedForm from "./StayInformedForm";

    export default {
        name: "StayInformedModal",
        components: {StayInformedForm, Modal},
        methods: {
            show() {
                this.$refs.modal.show();
            }
        }
    }
</script>

<style scoped>

</style>