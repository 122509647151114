import { render, staticRenderFns } from "./StayInformedForm.vue?vue&type=template&id=183a8108&scoped=true&"
import script from "./StayInformedForm.vue?vue&type=script&lang=js&"
export * from "./StayInformedForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "183a8108",
  null
  
)

export default component.exports