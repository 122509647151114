<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgNappe160" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M11.6,28c-0.2,0.5,0,1.1,0.5,1.3l11.3,5.4c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6c0.2-0.5,0-1.1-0.5-1.3L13,27.5
 C12.5,27.2,11.9,27.5,11.6,28z"/>
  <path d="M57.1,12.5H28.9c-1.9,0-3.5,1.6-3.5,3.5v10.7c0,1.9,1.6,3.5,3.5,3.5h28.2c1.9,0,3.5-1.6,3.5-3.5V16
 C60.6,14,59,12.5,57.1,12.5z M58.6,26.7c0,0.8-0.7,1.5-1.5,1.5H28.9c-0.8,0-1.5-0.7-1.5-1.5V16c0-0.8,0.7-1.5,1.5-1.5h28.2
 c0.8,0,1.5,0.7,1.5,1.5V26.7z"/>
  <path d="M35.4,16c-0.2,0-0.4,0-0.4,0.1c-0.1,0.1-0.2,0.3-0.5,0.7c-0.3,0.4-0.5,0.7-0.9,1s-0.8,0.6-1.3,0.8
 c-0.4,0.2-0.6,0.3-0.8,0.4c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.4,0,1.2-0.4,2.3-1.3v6.2
 c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,0.9-0.5,0.9-1.4v-7.8c0-0.3-0.1-0.6-0.2-0.8C35.8,16.1,35.6,16,35.4,16z"/>
  <path d="M45.6,20.5c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.2-1.2-0.2c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.2-0.7,0.5-1,0.8
 c0.1-1.2,0.3-2.1,0.6-2.6c0.2-0.2,0.4-0.4,0.6-0.6s0.5-0.2,0.7-0.2c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.4,0.4,0.5,0.7
 c0.1,0.2,0.2,0.4,0.4,0.5s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0-0.3-0.1-0.6-0.3-1s-0.6-0.6-1-0.8
 s-1-0.3-1.5-0.3c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.9,0.6-1.2,1c-0.3,0.4-0.6,1-0.8,1.7s-0.3,1.4-0.3,2.3c0,1.1,0.1,2,0.4,2.7
 s0.7,1.3,1.3,1.7c0.6,0.4,1.2,0.6,2,0.6c0.7,0,1.3-0.1,1.8-0.4s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.8c0-0.4-0.1-0.9-0.2-1.3
 S45.9,20.8,45.6,20.5z M44.2,24.3c-0.3,0.4-0.7,0.6-1.1,0.6c-0.5,0-0.9-0.2-1.2-0.6c-0.3-0.4-0.5-0.9-0.5-1.5c0-0.4,0.1-0.7,0.2-1
 s0.4-0.5,0.6-0.7s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.6,0.7c0.1,0.3,0.2,0.7,0.2,1.1C44.7,23.5,44.5,24,44.2,24.3z"/>
  <path d="M53.4,16.9c-0.3-0.3-0.6-0.5-1-0.7S51.6,16,51.1,16c-0.7,0-1.3,0.2-1.8,0.5s-0.9,0.8-1.2,1.5c-0.2,0.4-0.3,0.9-0.3,1.4
 c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.5,0,1,0.1,1.5s0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.2,1.2,1.6s1.2,0.6,1.9,0.6c0.6,0,1.1-0.1,1.6-0.4
 s0.9-0.7,1.2-1.2c0.2-0.4,0.4-0.9,0.5-1.5c0.1-0.5,0.1-1.2,0.1-1.9c0-1.4-0.2-2.4-0.5-3.2C53.9,17.5,53.7,17.2,53.4,16.9z M52.6,23.2c-0.1,0.5-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.2-0.3-0.4-0.7-0.5-1.2
 c-0.1-0.5-0.2-1.2-0.2-2.1c0-1.3,0.1-2.2,0.4-2.8s0.7-0.9,1.3-0.9c0.4,0,0.7,0.1,1,0.4c0.2,0.3,0.4,0.7,0.5,1.2
 c0.1,0.5,0.2,1.2,0.2,2C52.8,21.9,52.7,22.6,52.6,23.2z"/>
  <path d="M50.5,32.1c0.7,2.5,1.2,4.4,1.5,5.8c-0.7-0.2-1.4-0.2-2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1
 c-0.1,0-0.1,0-0.2,0l-16.2,8.3c-0.5-3.1-1.4-9.2-1.4-9.2l-2,0.3c0,0,1.4,9.2,1.7,10.7c0,0.2-0.6,0.8-1,1.2c-0.5,0.5-1.1,1-1.6,1.7
 c-0.1,0-0.2,0.1-0.2,0.1c-0.5-0.7-1.1-1.3-1.6-1.8c-0.4-0.4-1-1-1-1.2c0.2-1.5,2.1-13.1,2.1-13.1l-2-0.3c0,0-1.3,8.2-1.8,11.7
 l-13.1-7.1c-0.1-0.1-0.3-0.2-0.4-0.2l-3.1-1.7c-0.1,0-0.2-0.1-0.3-0.1C7.5,37,7.3,37,7.2,37c0,0-0.4,0.1-1,0.3
 c0.5-2.3,1.5-5.7,3-10.8L24,21v-2.1L8,24.8c0,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.2
 c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c-3.5,11.6-3.9,15.1-4,15.3c-0.1,0.5,0.3,1,0.8,1.1c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.3,1-0.7
 c0.4-1.4,1.4-1.9,1.8-2l1.9,1c0,0.1,0,0.1,0,0.2v8.1c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-6.7l12.3,6.7c0.2,0.8,0.9,1.5,1.6,2.2
 c0.8,0.8,1.8,1.7,2.1,3.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1v3.7c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-5.4
 c0-0.2,0-0.4-0.1-0.6c0.3-0.4,0.7-0.8,1.1-1.1c0.8-0.7,1.6-1.5,1.6-2.5c0,0,0,0,0,0c0.2,0,0.3,0,0.5-0.1l13.1-6.7v7.3
 c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V40c0-0.1,0-0.2,0-0.3l0.1-0.1c0.5,0,1.9,0,2.3,1.4c0.1,0.4,0.5,0.7,1,0.7
 c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.6,0.8-1.1c0-0.1-0.3-2.2-2-8.6H50.5z"/>
  <path d="M31.9,34.6c0.1,0,0.3,0,0.4-0.1l6-2.4h-5.3l-1.4,0.6c-0.5,0.2-0.8,0.8-0.5,1.3C31.1,34.4,31.5,34.6,31.9,34.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "nappe160"
    }
</script>

<style scoped>

</style>