<template>
    <div class="container">
        <!--        <div class="row">-->
        <!--            <div class="col-md-12">-->
        <!--            </div>-->
        <!--            <div class="col-md-12">-->
        <!--                <br>-->
        <!--                <div class="chart-container">-->
        <!--                    <canvas id="myChart"></canvas>-->
        <!--                </div>-->
        <!--                <hr>-->
        <!--            </div>-->

        <div class="col-md-12">
            <div class="form-group">
                <label>
                    {{ getTranslation('flows.from') }}
                    <DatePicker class="form-control" v-model="from"/>
                </label>
            </div>
            <div class="form-group">
                <label>
                    {{ getTranslation('flows.to') }}
                    <DatePicker class="form-control" v-model="to"/>
                </label>
            </div>
        </div>

        <div class="col-md-12">
            <label>
                {{ getTranslation('flows.search_product') }}
                <FormInput v-model="productSearch" :options="(products || []).map(p => p.name)"/>
            </label>
        </div>

        <template v-if="itemsLoaded">
            <div class="col-xs-12">
                <h3>
                    <QtyInOut :qty-in="qtyIn" :qty-out="qtyOut" :qty-currently-out="qtyCurrentlyOut" :qty-missing="qtyMissing"/>
                </h3>
            </div>
            <div class="col-xs-12">
                <br>
                <CustomerProductFlow :product="product" v-for="product in products" :key="product.id"/>
            </div>
        </template>
        <div class="col-xs-12" v-else>
            {{ getTranslation('flows.loading') }}
        </div>
    </div>
</template>

<script>
    import CustomerProductFlow from "./ProductFlow";
    import {commit, getters} from "../../productsFlow";
    import FormInput from "../FormInput";
    import QtyInOut from "./QtyInOut";
    import DatePicker from "../DatePicker";

    export default {
        name: "FlowPage",
        components: {DatePicker, QtyInOut, FormInput, CustomerProductFlow},
        data() {
            return {productSearch: ''};
        },
        computed: {
            products() {
                const products = getters.products;

                if (products === null) {
                    return null;
                }

                return products.filter(p => p.name.toLocaleLowerCase().includes(this.productSearch.toLocaleLowerCase()) && getters.itemsInProduct(p.id).length > 0);
            },
            qtyIn() {
                return getters.qtyIn;
            },
            qtyOut() {
                return getters.qtyOut;
            },
            qtyCurrentlyOut() {
                return getters.qtyCurrentlyOut;
            },
            qtyMissing() {
                return getters.qtyMissing;
            },
            itemsLoaded() {
                return getters.itemsLoaded;
            },
            from: {
                get() {
                    return getters.from;
                },
                set(from) {
                    commit('setFilters', {filters: {from}});
                }
            },
            to: {
                get() {
                    return getters.to;
                },
                set(to) {
                    commit('setFilters', {filters: {to}});
                }
            },
        },
    };
</script>

