<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitTorchons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M60.6,38.8c0.9-4.8-3.2-7.3-5.5-7.9l-12.2-3.8c-0.1,0-1.2-0.4-2.2,0c0,0,0,0,0,0c0,0,0,0,0,0l-15.1,5.4c0,0,0,0-0.1,0
 c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.3-1.2,0.9-1.4,2.2c-0.2,0.9,0,1.7,0.5,2.4c0.1,0.2,0.3,0.4,0.4,0.5c-0.9,0.6-1.7,1.6-1.9,3.1
 c-0.4,2.7,0.1,4.6,1.5,5.9c-0.8,0.7-1.3,1.7-1.5,3c-0.6,4,0.7,6.2,4.4,7.4L39.5,61c0.1,0,0.9,0.3,1.8,0.3c0.4,0,0.7,0,1.1-0.1
 c0,0,0,0,0.1,0l0.1,0c0,0,0,0,0.1,0l15-5.4c0,0,0.1,0,0.1-0.1c0.7-0.3,1.2-0.9,1.3-1.7c0.3-1.2,0.1-2.2-0.6-3
 c0.9-0.4,1.8-1.3,2.1-3.2c0.4-2.3-0.2-4.3-1.9-5.9C59.5,41.4,60.3,40.5,60.6,38.8z M58.6,38.4c-0.2,1.3-0.8,1.7-1.1,1.8l-0.9,0.3
 c0.1-0.3,0.2-0.6,0.3-1c0.4-2.1-0.3-4.7-2.8-5.5c-2-0.7-5.7-1.9-9.1-3l1.7-0.6c0,0,0,0,0,0l7.8,2.4C54.7,32.8,59.4,34.2,58.6,38.4z M44.2,49.4c0-0.8-0.2-1.5-0.7-2l12.8-4.6c0.9,0.5,0.9,0.9,0.8,1.7c0,0.1-0.1,0.2-0.2,0.3L44.2,49.4z M41.3,28.9
 c0.3-0.1,0.8,0,0.9,0l2.9,0.9l-1.7,0.6c-1.3-0.4-2.4-0.8-3.4-1.1L41.3,28.9z M38.5,29.9c0.9,0.3,2.1,0.7,3.4,1.1l-5.3,2
 c-0.3,0.1-0.4,0.4-0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0l6.5-2.4c3.7,1.2,8,2.7,10.2,3.4c2,0.7,2.5,2.7,2.2,4.4
 c-0.1,0.6-0.4,1.2-0.7,1.7l-9.6,3.5c0-0.1,0.1-0.2,0.1-0.4c0.9-4.8-3.2-7.3-5.5-7.9l-10.3-3.2L38.5,29.9z M24.8,41.2
 c0.3-2.2,2.1-2.2,2.6-2.1l12.2,3.8c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-12.3-3.8l-0.1,0c-0.3,0-1.4-0.4-1.8-1
 c-0.2-0.3-0.2-0.5-0.2-0.9c0.1-0.4,0.2-0.7,0.4-0.8c0.3-0.2,0.9-0.1,1.1,0l12.3,3.8c0.2,0.1,4.9,1.4,4.1,5.6
 c-0.2,0.9-0.5,1.4-1,1.7c-0.7,0.4-1.8,0.2-2.6,0l0,0l0,0c0,0,0,0,0,0l-12.3-3.8c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3
 l12.3,3.8l0,0c3,0.9,2.9,1.5,2.7,2.5c0,0.2-0.1,0.3-0.2,0.3c-0.4,0.2-1.3,0.1-1.9-0.1l-12.3-3.8C25.1,45.5,24.3,44.2,24.8,41.2z M41.5,55c-0.4-0.2-0.9-0.3-1.4-0.5l0,0c0,0,0,0,0,0l-12.3-3.8c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l12.3,3.8l0,0
 c3,0.9,2.8,1.5,2.6,2.5c0,0.2-0.1,0.3-0.2,0.3c-0.4,0.2-1.3,0.1-1.9-0.1l-12.3-3.8c-2.8-0.9-3.5-2.1-3-5.2c0.3-2.1,1.4-2.1,1.7-2.1
 c0.3,0.1,0.5,0.2,0.8,0.3l12.2,3.8c0.1,0,0.9,0.3,1.8,0.3c0.4,0,0.7,0,1.1-0.1c0,0,0,0,0.1,0l0.1,0c0,0,0,0,0.1,0l12.5-4.5
 c0.3,0.3,0.5,0.6,0.5,1c0.1,0.5-0.2,1-0.6,1.4L41.5,55z M57.1,53.4c0,0.1-0.1,0.2-0.2,0.3l-12.7,4.6c0-0.8-0.2-1.5-0.7-2l12.8-4.6
 C57.3,52.2,57.3,52.6,57.1,53.4z M58.6,47.3c-0.2,1.3-0.8,1.7-1.1,1.8l-1,0.4c0.1-0.3,0.2-0.7,0.1-1c-0.1-0.5-0.3-0.9-0.5-1.2
 l1.5-0.6c0,0,0.1,0,0.1-0.1c0.4-0.2,0.7-0.5,0.9-0.8C58.8,46.3,58.7,46.8,58.6,47.3z"/>
  <path d="M19,10.6c-0.1-0.3-0.3-0.4-0.6-0.4c-0.3,0.1-0.5,0.3-0.4,0.6l3.9,20.8c0.1-0.1,0.1-0.2,0.2-0.4c0.2-0.2,0.4-0.5,0.6-0.7
 L19,10.6z"/>
  <path d="M17.6,56.9c-2.2,0.1-4.1-0.6-5-1l0.4-3c0.9,0.4,2.3,0.8,3.9,0.8c1,0,2.1-0.2,3.2-0.6c0-0.3,0-0.7-0.1-1
 c-3.1,1.3-5.7,0.4-7-0.2l4-33.2c0.1-0.5-0.3-1-0.9-1.1c-0.5-0.1-1,0.3-1.1,0.9l-2.5,20.8c-2-0.5-3-1.4-3.4-2.1l6.4-27.3
 c0.2-1,1.2-1.7,2.3-1.6c0.8,0.1,1.6,0.7,1.8,1.5l0,0.1c1.8,6.4,3.7,13.1,5.2,19.1c0.6-0.4,1.2-0.7,1.8-1
 c-1.2-4.8-2.8-10.6-4.9-17.9c0-0.3,0.1-0.5,0.1-0.8c0-3.1-2.5-5.7-5.7-5.7S10.5,5.9,10.5,9c0,1.8,0.8,3.4,2.1,4.4L6.5,39.7
 c-0.1,0.3-0.4,1.5,0.3,2.8c0.8,1.4,2.5,2.5,5,3.2l-1.3,10.7c0,0.4,0.1,0.8,0.5,1c0.1,0.1,2.7,1.6,6.1,1.6c0.2,0,0.4,0,0.5,0
 c1.2-0.1,2.2-0.3,2.9-0.7c-0.1-0.6-0.2-1.4-0.3-2.2C19.9,56.4,19.1,56.8,17.6,56.9z M12.5,9c0-2,1.7-3.7,3.7-3.7
 c0.9,0,1.7,0.3,2.3,0.8c-0.2,0-0.3-0.1-0.5-0.1c-2-0.2-3.9,1.2-4.4,3.1l-0.4,1.9C12.8,10.5,12.5,9.8,12.5,9z M12.1,43.6
 C9.9,43,9,42.2,8.6,41.6c-0.4-0.7-0.2-1.2-0.2-1.3l0.5-2c0.7,0.8,1.8,1.5,3.6,1.9L12.1,43.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kittorchons"
    }
</script>

<style scoped>

</style>