<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgEssuieVerreX20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M17.4,26.2c0-0.1,0-0.2,0-0.4c0.2-3.8,0.9-8.8,1.9-11.9h-2.1c-0.2,0.5-0.3,1.1-0.4,1.6c0-0.5,0.1-1.1,0.1-1.6h-2.1
  c-0.4,4.1,0.1,8.4,0.4,10.6c0.1,0.5,0.1,1,0.1,1.2c0,0,0,0,0,0.1c0,0.5,0.3,1,0.9,1.1C16.7,27.1,17.2,26.7,17.4,26.2z"/>
<path d="M27.3,31.5c0.4-3.7,0.4-7.1,0.3-8c0-0.6-0.6-1-1.1-0.9c-0.6,0-1,0.6-0.9,1.1c0.1,0.8,0.1,4.5-0.4,8.4
  C25.9,31.8,26.6,31.6,27.3,31.5z"/>
<path d="M21.9,6.8c2.2-0.3,4.2,0.6,5.9,2.7c2.5,3,4,8.1,4.3,13c0.6-0.7,1.2-1.3,1.9-1.9c-0.5-4.8-2.1-9.5-4.6-12.5
  c-2.2-2.7-4.9-3.9-7.8-3.5c-2.9,0.4-4.7,2.2-5.7,4.6H15H6.4l-0.2,0.8c-0.2,0.6-4,14.7,1.3,21.7c1.6,2.1,3.9,3.3,6.8,3.7v13h-7
  c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h9.3c-0.1,1.3-0.1,2-0.1,2c0,0.3,0.1,0.5,0.3,0.8c0.7,0.7,1.4,1.1,2.2,1.3
  c-0.1-0.8-0.3-1.6-0.3-2.4c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.5c-0.1-1.4-0.2-2.9-0.2-4.5c0-1.8-0.2-6.7,1.4-10.5
  c0.2-2,0.5-3.9,0.8-5.7c0,0,0-0.1,0-0.1c0.2-1,0.3-2,0.5-3.1c2.2-12.5,2.8-17.5,0.4-18.3c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
  c-0.3-0.1-0.5-0.1-0.8,0h-2.2C19.1,8,20.2,7,21.9,6.8z M17.2,40.3l0-4.9c0.1,0,0.4,0,0.5-0.1C17.6,36.6,17.3,39.1,17.2,40.3z M20.7,11.4L20.7,11.4C20.7,11.4,20.7,11.4,20.7,11.4L20.7,11.4c0.8,1.5-0.6,9.9-1.7,16c-0.3,1.9-0.7,3.9-1,5.8
  c-0.7,0.1-1.4,0.2-2.2,0.2c-3,0-5.2-1-6.7-2.9c-4-5.3-1.8-16.1-1.1-19.1h7h0.2c0,0,0,0,0,0h2.1c0,0,0,0,0,0h0.8c0,0,0,0,0,0H20.7z"
  />
<path d="M48.1,50.8c-0.7,0.1-1.5,0.2-2.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.2l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.3-1.8-0.4-3.1-1.3-4.1
  l1.8-0.6c-2.4-1.1-4.4-2.7-6.1-4.7l-7.5-2.3c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0
  c1.9,0.6,1.9,0.9,1.7,1.5c0,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.3-0.9-0.3l-8.8-2.7c-0.1,0-0.4-0.1-0.9-0.2c-1.1-0.6-1.4-1.4-1.1-3.1
  c0.2-1.2,1.1-1.3,1.5-1.3l6.8,2.1c-0.5-0.8-1-1.6-1.4-2.5l-5-1.6l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4
  c0.1-0.1,0.4,0,0.5,0l5.1,1.6c-0.2-0.7-0.4-1.5-0.6-2.3l-1.7-0.5l1.5-0.6c-0.1-0.7-0.1-1.3-0.1-2c0,0,0,0,0,0l-5.4,2c0,0,0,0-0.1,0
  c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3
  c-0.3,2,0.1,3.4,1.1,4.4c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3
  c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l10.9-3.9
  c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1C47.3,52.5,47.8,51.8,48.1,50.8z M35.4,54
  c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7-0.1l-8.9-2.7c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7
  c0,0,0,0,0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7
  c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0c-0.2,0-0.9-0.3-1.1-0.6c-0.1-0.1-0.1-0.2-0.1-0.4
  c0-0.2,0.1-0.3,0.2-0.4c0.2,0.1,0.4,0.1,0.6,0.2l8.9,2.7c0.1,0,0.5,0.2,1,0.2C34.5,51.2,35.7,52.2,35.4,54z M45.1,54.6l-8.8,3.2
  c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C45.2,53.9,45.2,54.1,45.1,54.6z"/>
<g>
  <path d="M45.1,48.8c-8.2,0-14.9-6.7-14.9-14.9s6.7-14.9,14.9-14.9S60,25.6,60,33.8S53.3,48.8,45.1,48.8z M45.1,20.9
 c-7.1,0-12.9,5.8-12.9,12.9S38,46.8,45.1,46.8C52.2,46.8,58,41,58,33.8S52.2,20.9,45.1,20.9z"/>
</g>
<g>
  <path d="M37.8,38.4l-1.1-1.6l-1.1,1.6c-0.2,0.2-0.3,0.4-0.4,0.5s-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.4
 c0-0.2,0.1-0.4,0.3-0.7l1.4-2l-1.2-1.6c-0.2-0.3-0.3-0.5-0.3-0.7c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.2
 c0.2,0,0.3,0,0.4,0.2s0.3,0.3,0.4,0.5l1,1.4l1-1.4c0.2-0.2,0.3-0.4,0.4-0.5s0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.5,0.2
 c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.4l-1.2,1.6l1.4,2c0.2,0.3,0.3,0.5,0.3,0.7
 c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.4-0.2C38.1,38.8,38,38.7,37.8,38.4z"/>
  <path d="M42.9,37.3h3.7c0.4,0,0.7,0.1,0.8,0.2s0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.7,0.2h-5.2
 c-0.4,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.3-0.5,0.4-0.7c0.6-0.7,1.2-1.2,1.7-1.7
 s0.9-0.8,1.1-0.9c0.4-0.3,0.7-0.5,1-0.8c0.3-0.3,0.5-0.6,0.6-0.8s0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.6
 c-0.2-0.1-0.5-0.2-0.8-0.2c-0.6,0-1.1,0.3-1.5,0.8c0,0.1-0.1,0.3-0.2,0.6s-0.2,0.6-0.4,0.7c-0.1,0.2-0.3,0.3-0.6,0.3
 c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.7,0.2-1c0.1-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.5,1.1-0.7
 c0.4-0.2,1-0.3,1.6-0.3c0.7,0,1.3,0.1,1.8,0.3c0.3,0.2,0.6,0.4,0.9,0.6c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.3,0.2,0.7,0.2,1.1
 c0,0.6-0.1,1.1-0.4,1.6c-0.3,0.5-0.6,0.9-0.9,1.1c-0.3,0.3-0.8,0.7-1.5,1.3c-0.7,0.6-1.2,1-1.5,1.4C43.1,36.9,43,37.1,42.9,37.3z"
 />
  <path d="M56.2,33.8c0,0.8,0,1.4-0.1,2c-0.1,0.6-0.3,1.1-0.5,1.5c-0.3,0.6-0.7,1-1.2,1.3c-0.5,0.3-1.1,0.5-1.7,0.5
 c-0.7,0-1.4-0.2-1.9-0.6c-0.6-0.4-1-1-1.3-1.7c-0.1-0.4-0.3-0.8-0.3-1.3S49,34.5,49,34c0-0.7,0-1.4,0.1-1.9
 c0.1-0.6,0.2-1.1,0.4-1.5c0.3-0.7,0.7-1.2,1.2-1.6c0.5-0.4,1.2-0.5,1.9-0.5c0.5,0,0.9,0.1,1.3,0.2s0.7,0.4,1,0.7s0.6,0.7,0.8,1.1
 C56,31.3,56.2,32.4,56.2,33.8z M54.3,33.7c0-0.9-0.1-1.6-0.2-2.1c-0.1-0.5-0.3-1-0.5-1.2c-0.2-0.3-0.6-0.4-1-0.4
 c-0.6,0-1.1,0.3-1.3,0.9s-0.4,1.6-0.4,2.9c0,0.9,0.1,1.6,0.2,2.2s0.3,1,0.5,1.3c0.2,0.3,0.6,0.4,1,0.4c0.4,0,0.8-0.2,1-0.5
 c0.2-0.3,0.4-0.7,0.5-1.3C54.2,35.3,54.3,34.6,54.3,33.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "essuieverrex20"
    }
</script>

<style scoped>

</style>