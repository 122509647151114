import SessionStorageStore from "./SessionStorageStore";

const store = new SessionStorageStore('rental');

class RentalStore {
    static get() {
        return store.get() || {};
    }

    static set(rental) {
        store.set(rental);
    }

    static merge(rental) {
        store.merge(rental);
    }

    static subscribe(subscriber) {
        store.subscribe(subscriber);
    }

    static unsubscribe(subscriber) {
        store.unsubscribe(subscriber);
    }

    static setAddress(address) {
        store.merge({address: address});
    }

    static removeDates() {
        store.merge({from: null, to: null})
    }
}

export default RentalStore;