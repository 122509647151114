<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgCommande" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M23.7,41.4c1.2,0,2.1-0.9,2.1-2.1c0-0.8-0.4-1.4-1-1.8c0.2-4.1,0.9-14,4.7-17.7c0.9-0.9,1.9-1.3,3-1.3c1.1,0,2.1,0.4,3,1.3
 c3.7,3.7,4.5,13.7,4.7,17.7c-0.6,0.4-1,1-1,1.8c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1c0-0.8-0.5-1.5-1.1-1.8
 c-0.2-4.3-1-14.8-5.2-19.1c-1.3-1.3-2.8-1.9-4.4-1.9c-1.7,0-3.2,0.7-4.4,1.9c-4.3,4.3-5.1,14.8-5.2,19.1c-0.7,0.3-1.1,1-1.1,1.8
 C21.6,40.4,22.5,41.4,23.7,41.4z"/>
  <polygon points="27.3,31.1 37.3,31.1 37.1,29.1 27.6,29.1   "/>
  <path d="M48.6,32.1c-0.1-1.7-1.5-3-3.2-3h-2.3l0.3,2h2c0.7,0,1.2,0.5,1.2,1.2l2.3,23.6c0,0.4-0.1,0.7-0.3,1
 c-0.2,0.3-0.6,0.4-0.9,0.4H16.3c-0.4,0-0.7-0.1-0.9-0.4s-0.4-0.6-0.3-1l3.1-23.6c0.1-0.6,0.6-1.1,1.2-1.1h2l0.3-2c0,0,0,0-0.1,0
 h-2.2c-1.7,0-3,1.2-3.2,2.9l-3.1,23.6c-0.1,0.9,0.2,1.8,0.8,2.5c0.6,0.7,1.5,1.1,2.4,1.1h31.3c0.9,0,1.8-0.4,2.4-1.1
 s0.9-1.6,0.8-2.5L48.6,32.1z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "commande"
    }
</script>

<style scoped>

</style>