<template>

    <div class="wrapDeliveryChoice">
        <div>
            <div v-if="delivery && (!delivery.deliveryRequiresAvailableSupplier || supplier.available)">
                <div class="radio">
                    <div class="custom-control custom-radio">
                        <input type="radio" class="dataPrice custom-control-input" data-select="true"
                               data-type="delivery" data-place="supplier" :data-price="supplier.price"
                               name="deliveryMode" :id="radioName" :value="radioName" :checked="selected"
                               v-on:change="select()">
                        <label class="custom-control-label" :for="radioName">
                            <span class="desc-wrapper">
                                <span class="text-primary">{{ getTranslation('delivery.address_choice') }}</span><br>
                                  {{ getTranslation('delivery.delivery_at') }} {{ address.street }} {{address.zip}}
                                  {{address.city}} le {{ dateToLocaleString(delivery.deliveryDate)}}
                            </span>
                            <span class="price-wrapper">
                                <span v-if="supplier.price == 0">{{ getTranslation('delivery.offered') }}</span>
                                <span v-else></span>
                                <span v-if="supplier.price != 0" class="tax_price">{{ priceFormat(supplier.price / 100 * 1.2) }} TTC</span>
                                <span v-if="supplier.price != 0" class="notax_price">{{ priceFormat(supplier.price / 100) }} HT</span>
                            </span>
                        </label>
                    </div>
                </div>
                <div class="delivery-detail-wrapper" v-if="showAddressFields" :style="selected ? '' : 'display: none;'">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="wrapFloatLabel input-group">
                                <input type="text" name="delivery_name" id="delivery_name"
                                       class="form-control floatLabelInput" placeholder="" value="" required
                                       v-model="address.name">
                                <label for="delivery_name" class="floatLabel">
                                    <span
                                        class="floatLabelContent">{{ getTranslation('delivery.recipient') }}<span
                                        class="right">*</span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="wrapFloatLabel input-group">
                                <input type="text" name="delivery_street" id="delivery_street"
                                       class="form-control floatLabelInput" placeholder="" value="" required
                                       v-model="address.street" data-toggle="tooltip"
                                       data-placement="top">
                                <label for="delivery_street" class="floatLabel">
                                    <span class="floatLabelContent">Rue<span class="right">*</span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="wrapFloatLabel input-group">
                                <input type="text" name="delivery_complement" id="delivery_complement"
                                       class="form-control floatLabelInput" placeholder="Rue"
                                       :value="address.complement" required>
                                <label for="delivery_complement" class="floatLabel">
                                    <span class="floatLabelContent">{{ getTranslation('delivery.additional_address') }}<span
                                        class="right">*</span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="wrapFloatLabel input-group">
                                <input type="text" name="delivery_zip" id="delivery_zip"
                                       class="form-control floatLabelInput" placeholder="Rue"
                                       :value="address.zip" readonly="readonly" data-toggle="tooltip"
                                       data-placement="top"
                                       :title="getTranslation('delivery.cant_change_this_info')">
                                <label for="delivery_zip" class="floatLabel">
                                    <span
                                        class="floatLabelContent">{{ getTranslation('delivery.zip_code') }}<span
                                        class="right">*</span></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="wrapFloatLabel input-group">
                                <input type="text" name="delivery_city" id="delivery_city"
                                       class="form-control floatLabelInput" placeholder="Rue"
                                       :value="address.city" readonly="readonly" data-toggle="tooltip"
                                       data-placement="top"
                                       :title="getTranslation('delivery.cant_change_this_info')">
                                <label for="delivery_city" class="floatLabel">
                                    <span class="floatLabelContent">{{ getTranslation('delivery.city') }}<span
                                        class="right">*</span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="timeFrames().length" class="delivery-detail-wrapper delivery-time-wrapper" :style="selected ? '' : 'display: none;'">
                    <div class="row">
                        <div class="col-md-6">
                            <TimeFrameSelect label="Livraison"
                                 class="delivery_time_frame"
                                 name="delivery_time_frame"
                                 :help="getTranslation('delivery.favorite_schedule')"
                                 :deliveryDate="delivery.deliveryDate"
                                 :time-frames="timeFrames()"
                                 :selectedValue="delivery.deliveryPreference"
                                 :required="selected"
                            />
                        </div>
                        <div class="col-md-6">
                            <TimeFrameSelect label="Récupération"
                                 class="recovery_time_frame"
                                 name="recovery_time_frame"
                                 :help="getTranslation('delivery.favorite_schedule_pick_up')"
                                 :deliveryDate="delivery.recoveryDate"
                                 :time-frames="timeFrames()"
                                 :selectedValue="delivery.recoveryPreference"
                                 :required="selected"
                            />
                        </div>
                        <div class="col-md-12">
                            <p class="text-danger" id="chooseTimeFrameMessage">
                                <i class="fa fa-info-circle"></i>
                                <span id="chooseTimeFrameContent">Veuillez choisir un horaire livraison et de récupération pour passer à l'étape suivante</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-md-12">
                        <p>
                            {{ getTranslation('delivery.contact_patform', { ':supplier_name': supplier.name}) }}
                            <span v-if="supplier.phone">
                                <br>
                                <strong>Tel : <a
                                    :href="`tel:${supplier.phone}`">{{ supplier.phone }}</a></strong>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="radio">
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" disabled>
                        <label class="custom-control-label">
                            <span class="desc-wrapper">
                                <span class="text-primary">{{ getTranslation('delivery.address_choice') }}</span><br>
                                <span v-if="delivery && !supplier.available">
                                    {{ getTranslation('delivery.delivery_impossible_at_dates') }}
                                </span>
                                <span v-else>
                                    <span v-html="noDeliveryReason"></span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
                <input type="hidden" name="delivery_city" id="delivery_city" :value="address.city">
                <input type="hidden" name="delivery_name" id="delivery_name" value="" v-model="address.name">
                <input type="hidden" name="delivery_street" id="delivery_street" value=""
                       v-model="address.street">
                <input type="hidden" name="delivery_complement" id="delivery_complement"
                       :value="address.complement">
                <input type="hidden" name="delivery_zip" id="delivery_zip" :value="address.zip">
            </div>
        </div>
        <div v-if="selected" class="delivery-detail-wrapper">
            <div class="row">
                <div class="col-md-6">
                    <LabelledInput name="delivery_phone" v-model="phoneNumber" init-id="delivery_phone" required>
                        <template v-slot:label>{{ getTranslation('delivery.phone_number') }}</template>
                    </LabelledInput>
                </div>
                <div class="col-md-12">
                    <LabelledInput name="delivery_info" init-id="delivery_info" v-model="addressInfo">
                        <template v-slot:label>
                            {{ getTranslation('delivery.order_infos')}}
                        </template>
                        <template v-slot:input="{id, required, handleFocus, handleBlur, handleInput, className, value}">
                            <textarea :id="id"
                                      type="text"
                                      rows="5"
                                      class="form-control floatLabelInput"
                                      :value="value"
                                      @input="handleInput($event.target.value)"
                                      @focus="handleFocus"
                                      @blur="handleBlur"
                                      :class="className"
                                      :required="required"></textarea>
                        </template>
                    </LabelledInput>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import TimeFrameSelect from "./TimeFrameSelect";
import dateToLocaleString from '../dateToLocaleString';
import LabelledInput from "./LabelledInput";
import locale from "../locale";

export default {
        'name': 'DeliverySelect',
        components: {LabelledInput, TimeFrameSelect},
        props: {
            delivery_service: Object,
            delivery: Object,
            supplier: Object,
            address: Object,
            noDeliveryReason: String,
            radioName: String,
            selected: Boolean,
            showAddressFields: Boolean,
            select: Function
        },
        data() {
            return {
                phoneNumber: this.address.phone,
                addressInfo: this.address.info
            };
        },
        methods: {
            timeFrames: function () {
                if (!['easylinge'].includes(this.delivery_service.name)) {
                    return [];
                }

                const timeFrames = this.delivery.timeFrames.map(tf => ({value: tf, name: tf}));

                if (timeFrames.length && timeFrames.length < 1 && this.delivery_service.name === 'easylinge') {
                    timeFrames.unshift({name: "Choisissez votre créneau", value: null});
                }

                return timeFrames;
            },
            dateToLocaleString,
            priceFormat: new Intl.NumberFormat(locale(), {style: 'currency', currency: 'EUR'}).format,
        },
    }
</script>
