<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitBainDoublePeignoire" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M33,41.3c0.8-1.5,1.9-2.9,3.1-4H21.4V21.1c0-0.9-0.2-1.8-0.4-2.6h1.2c0,0.2,0.1,0.5,0.1,0.7V32c0,0.6,0.5,1,1,1h10.9
 c0.6,0,1-0.5,1-1V19.3c0-0.3,0-0.5-0.1-0.7h3.7h2c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.3,0.4,0.8,0.6,1.4
 c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0,0.5v2.4v10.4c1.5-0.5,3.1-0.8,4.8-0.8c1.1,0,2.1,0.1,3.1,0.3c-0.2-0.5-0.6-1-1.2-1.1l2.1-7.3
 l0-0.2c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3v9.1c0.6,0.2,1.1,0.4,1.6,0.6v-9.7h0c0-1.1-0.9-1.9-1.9-1.9
 c-1,0-1.9,0.8-1.9,1.8l-2.1,7.6h-3l-0.5-1.8v-5.4l0.5-1.6h2.7c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6h-1.9l1.3-4.8
 c8.3,1.4,10,4.8,10,9.3v11.6c0.6,0.4,1.1,0.9,1.6,1.4c0,0,0,0,0,0v-13c0-6-3.2-9.3-10.5-10.8l-4.5-2.2c0-0.3-0.1-0.5-0.1-0.7
 c0.1,0,0.1,0,0.2-0.1c0.7-0.3,2.1-0.8,2.1-2.7c0-2-1.2-3.3-3.1-3.3c-1.8,0-2.9,1.2-2.9,2.2c0,0.4,0.3,0.8,0.8,0.8
 c0.4,0,0.8-0.3,0.8-0.8c0-0.1,0.3-0.6,1.3-0.6c0.6,0,1.4,0.2,1.4,1.7c0,0.7-0.3,0.9-1.1,1.2c-0.2,0.1-0.4,0.2-0.6,0.3
 c-0.6,0.4-0.7,1.3-0.5,2.1l-4.4,2.2c-2.9,0.6-5.2,1.6-6.8,2.9H17.1h-4.6c-0.5,0-1,0.2-1.4,0.6c-0.2,0.2-0.5,0.5-0.7,0.8
 c-0.3,0.4-0.5,0.9-0.6,1.5c0,0.2-0.1,0.3-0.1,0.5h-4c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.9v0h5.9v1.1H9.6v1.3v1v18.6
 c0,0,0,0,0,0v3.8c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-1.8c0.1,0,0.3,0,0.4,0h1.5v1.8c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8
 v-1.8h0.3c0.1,0,0.3,0,0.4,0v4.9c0,0.1,0,0.3,0,0.4v3.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.2c0.1,0,0.3,0,0.4,0H19
 v2.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1H26v2.1
 c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.2
 c-0.2-0.5-0.4-1.1-0.5-1.6H17.5c-0.2,0-0.4-0.2-0.4-0.4V21.6c0-1.1-0.2-2.1-0.5-3h2.7c0.3,0.7,0.6,1.6,0.6,2.6v23.3
 c0,1,0.8,1.8,1.8,1.8h9.8c0.1-0.5,0.2-1.1,0.3-1.6H21.6c-0.1,0-0.2-0.1-0.2-0.2v-3.1H33z M44.4,14.8c0.2,0.1,0.3,0.3,0.3,0.5
 l-1.3,5c-0.1-0.6-0.2-1.1-0.4-1.5l1-3.6C43.9,14.9,44.1,14.8,44.4,14.8z M41.8,13.3l1.6,0.8h-3.2L41.8,13.3z M33.5,31.4h-9.7v-1.7
 h9.7V31.4z M37.8,15.5l0.4,1.5h-5.5C34.1,16.3,35.8,15.8,37.8,15.5z M33.4,18.6c0.1,0.1,0.1,0.3,0.1,0.7v8.1h-9.7v-8.1
 c0-0.2,0-0.4,0-0.7H33.4z"/>
  <path d="M46.6,35.2c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C59.9,41.2,53.9,35.2,46.6,35.2z M46.6,59.9
 c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4s11.4,5.1,11.4,11.4S52.8,59.9,46.6,59.9z"/>
  <path d="M44.2,49.5l1.3-1.7c0.1-0.2,0.2-0.3,0.3-0.4s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0.1-0.5,0.2s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2
 s-0.2,0.3-0.2,0.4c0,0.2,0.1,0.5,0.3,0.7l1.3,1.7l-1.4,2.1c-0.2,0.3-0.3,0.6-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4s0.3,0.2,0.5,0.2
 c0.2,0,0.3-0.1,0.5-0.2s0.3-0.3,0.4-0.5l1.2-1.7l1.2,1.7c0.2,0.2,0.3,0.4,0.4,0.5s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2
 s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L44.2,49.5z"/>
  <path d="M52,51.4H49c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.6-0.6,1.2-1.1s1-0.8,1.3-1.1s0.5-0.5,0.7-0.9s0.4-0.8,0.4-1.3
 c0-0.3-0.1-0.6-0.2-0.9s-0.3-0.5-0.5-0.8s-0.4-0.4-0.7-0.5c-0.4-0.2-0.9-0.3-1.5-0.3c-0.5,0-0.9,0.1-1.3,0.2s-0.7,0.3-0.9,0.6
 s-0.4,0.5-0.5,0.8S47,46.3,47,46.5c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.3-0.6s0.2-0.4,0.2-0.5
 c0.3-0.4,0.7-0.7,1.2-0.7c0.2,0,0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7c0,0.2-0.1,0.5-0.2,0.7s-0.3,0.5-0.5,0.7
 s-0.5,0.5-0.8,0.7c-0.2,0.1-0.5,0.4-0.9,0.8s-0.9,0.8-1.4,1.4c-0.1,0.1-0.2,0.3-0.3,0.5S47,51.8,47,51.9c0,0.2,0.1,0.4,0.2,0.6
 s0.4,0.2,0.7,0.2h4.3c0.3,0,0.5-0.1,0.6-0.2s0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5S52.3,51.4,52,51.4z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kitbaindoublepeignoire"
    }
</script>

<style scoped>

</style>