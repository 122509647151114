<template>
    <div class="alert alert-danger text-center">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "ErrorMessage"
    }
</script>

<style scoped>

</style>