<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgRestaurant" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M45.5,16.6c-1.9,0-3.5,1.6-3.5,3.5v7.2h-8.5c0,0,0,0,0,0c0-0.6-0.4-1-1-1h-1.2v-3c0.8-0.2,1.5-0.5,2-1.1
  c1.3-1.4,1.2-3.4,1.2-3.6l-0.1-0.9h-8.2l-0.1,0.9c0,0.2-0.1,2.2,1.2,3.6c0.5,0.6,1.2,0.9,2,1.1v3h-1.4c-0.6,0-1,0.4-1,1c0,0,0,0,0,0
  H22v-9.4c0-1.9-1.6-3.5-3.5-3.5H4.2v13.9v10v0.8v0.3v1v4.1v8.5c0,0.6,0.4,1,1,1s1-0.4,1-1v-8.5h2.6v12.6c0,0.6,0.4,1,1,1s1-0.4,1-1
  V44.6h4.5c0.2,3.5,0.5,4.3,0.9,4.7c0.2,0.2,0.5,0.4,0.8,0.4H20v3.4c0,0.6,0.4,1,1,1s1-0.4,1-1v-3.4h2.3v7.5c0,0.6,0.4,1,1,1
  s1-0.4,1-1v-7.5h11v7.7c0,0.6,0.4,1,1,1s1-0.4,1-1v-7.7h2.8v11.8c0,0.6,0.4,1,1,1s1-0.4,1-1V46.8h9.2v11.7c0,0.6,0.4,1,1,1
  s1-0.4,1-1V46.8h2.5v14.6c0,0.6,0.4,1,1,1s1-0.4,1-1V46.8v-1.9v-4.4v-2V16.6H45.5z M28.2,19.6h4.2c-0.1,0.4-0.2,0.8-0.6,1.2
  c-0.4,0.4-0.9,0.6-1.6,0.6c-0.7,0-1.2-0.2-1.5-0.6C28.5,20.5,28.3,20,28.2,19.6z M6.2,16.4h12.3c0.8,0,1.5,0.7,1.5,1.5v9.4h-1.3
  c-1.8,0-3.3,1.5-3.3,3.3c0,0.2,0,0.6,0,1.1c0,1.7-0.1,3.2-0.1,4.5H7H6.2v-8V16.4z M6.2,42.6v-2.1v-1v-0.3c0-0.5,0.4-0.8,0.8-0.8h8.2
  c0,1.7,0,3.1,0,4.3H6.2z M57.8,44.8H39.3v-3.4c0-0.5,0.4-0.8,0.8-0.8H42h15.8V44.8z M44,38.5V27.3v-7.2c0-0.8,0.7-1.5,1.5-1.5h12.3
  v19.9H44z"/>
</svg>

</template>

<script>
    export default {
        name: "restaurant"
    }
</script>

<style scoped>

</style>