<template>
    <div>
        <span v-if="duration && quantity" class="priceTotal">
            <Price v-bind:price="totalPrice({basePrice, dailyPrice}, quantity, duration)"
                   v-bind:currency-symbol="currencySymbol"/>
            <br/>
        </span>
        <div class="wrapPrice">
            <span v-if="!priceLocked">À partir de</span>
            <Price v-bind:price="basePrice" v-bind:currency-symbol="currencySymbol"/>
            puis
            <Price v-bind:price="dailyPrice" v-bind:currency-symbol="currencySymbol"/>
            / jour
        </div>
    </div>
</template>

<script>
    import Price from "./Price";
    import {totalPrice} from "../price";

    export default {
        name: "RentalPrice",
        components: {Price},
        props: {
            basePrice: Object,
            dailyPrice: Object,
            currencySymbol: String,
            duration: Number,
            priceLocked: Boolean,
            quantity: {
                type: Number,
                default: 1
            }
        },
        methods: {
            totalPrice
        }
    }
</script>

<style scoped>

</style>
