<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTorchonX50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M16.2,58.6c-2.2,0.1-4.1-0.6-5-1l0.4-3c0.9,0.4,2.3,0.8,3.9,0.8c0.7,0,1.5-0.1,2.3-0.3c-0.1-0.3-0.1-0.6-0.2-1
 c-2.6,0.8-4.9,0-6-0.6l4-33.2c0.1-0.5-0.3-1-0.9-1.1c-0.5-0.1-1,0.3-1.1,0.9l-2.5,20.8c-2-0.5-3-1.4-3.4-2.1l6.4-27.3
 c0.2-1,1.2-1.7,2.3-1.6c0.8,0.1,1.6,0.7,1.8,1.5l0,0.1c1,3.4,1.9,6.8,2.9,10.2c0.5-0.3,1.1-0.5,1.7-0.7c0.1,0,0.1,0,0.2-0.1
 c-0.8-2.8-1.6-5.9-2.6-9.3c0-0.3,0.1-0.5,0.1-0.8c0-3.1-2.5-5.7-5.7-5.7s-5.7,2.5-5.7,5.7c0,1.8,0.8,3.4,2.1,4.4L5.2,41.4
 c-0.1,0.3-0.4,1.5,0.3,2.8c0.8,1.4,2.5,2.5,5,3.2L9.2,58c0,0.4,0.1,0.8,0.5,1c0.1,0.1,2.7,1.6,6.1,1.6c0.2,0,0.4,0,0.5,0
 c1.2-0.1,2-0.3,2.7-0.6c-0.2-0.6-0.4-1.2-0.6-1.9C18,58.3,17.3,58.6,16.2,58.6z M11.2,10.8c0-2,1.7-3.7,3.7-3.7
 c0.9,0,1.7,0.3,2.3,0.8c-0.2,0-0.3-0.1-0.5-0.1c-2-0.2-3.9,1.2-4.4,3.1l-0.4,1.9C11.4,12.2,11.2,11.5,11.2,10.8z M10.7,45.4
 c-2.2-0.6-3.1-1.4-3.4-2.1C6.9,42.6,7,42.1,7,42.1l0.5-2c0.7,0.8,1.8,1.5,3.6,1.9L10.7,45.4z"/>
  <path d="M17,12c-0.3,0.1-0.5,0.3-0.4,0.6l2.1,11.4c0.3-0.4,0.5-0.7,0.8-1l-2-10.6C17.6,12.1,17.3,11.9,17,12z"/>
  <path d="M45.5,51l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.3-2.1-0.6-3.6-1.8-4.6l1-0.4c-0.8-0.4-1.6-0.8-2.3-1.4c0,0,0,0,0,0.1
 c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l5.3,1.6c-0.4-0.8-0.8-1.6-1.1-2.4l-3.7-1.2
 l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l3.9,1.2c-0.2-0.7-0.3-1.5-0.4-2.2l-2.9-0.9
 c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l3.4,1.1c0.1-0.7,0.2-1.3,0.4-2l-3.3-1l-0.1,0c-0.2,0-0.9-0.3-1.1-0.6
 c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l4.3,1.3c0.2-0.6,0.5-1.3,0.8-1.9l-2.3-0.7l3.3-1.2
 c0.7-1.1,1.6-2.1,2.5-3l-9.8,3.6c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2,0.1,3.5,1.2,4.5c-0.2,0.3-0.4,0.7-0.5,1.2c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2.2,0.1,3.7,1.5,4.7c-0.3,0.3-0.6,0.7-0.7,1.4c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1
 c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1
 c0.6-0.4,1.1-1.1,1.4-2.3c-0.7,0.2-1.5,0.3-2.3,0.4C45.7,50.8,45.6,50.9,45.5,51z M35.3,53.8c-0.1,0.5-0.3,0.9-0.6,1.1
 c-0.3,0.2-0.9,0.2-1.7-0.1L24.2,52c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5
 c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7
 c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0c-0.2,0-0.9-0.3-1.1-0.6c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0.1-0.4,0.2-0.4
 c0.1-0.1,0.4,0,0.5,0l8.9,2.8C32.8,50.1,35.8,51,35.3,53.8z M45,54.3l-8.8,3.2c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2
 C45.2,53.6,45.1,53.8,45,54.3z"/>
  <path d="M24.1,31.5c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l3.4,1c0-0.3,0-0.5,0-0.8c0-0.4,0-0.8,0.1-1.3L24.1,31.5z"/>
  <path d="M22.9,42.2c-0.2,0.5,0.1,1.1,0.7,1.3l8.8,2.7c-0.9-0.9-1.8-1.8-2.5-2.9l-5.7-1.8C23.6,41.3,23,41.6,22.9,42.2z"/>
  <path d="M44.1,18.7c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9S59,41.8,59,33.6S52.3,18.7,44.1,18.7z M44.1,46.5
 c-7.1,0-12.9-5.8-12.9-12.9S37,20.7,44.1,20.7c7.1,0,12.9,5.8,12.9,12.9S51.2,46.5,44.1,46.5z"/>
  <path d="M36.7,35.6l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.4,0.1
 s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.4
 c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4s0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1
 s0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.4
 c0-0.2-0.1-0.4-0.3-0.7L36.7,35.6z"/>
  <path d="M45.8,32.8c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.3-1.3-0.3c-0.6,0-1.2,0.2-1.9,0.5L42,30h3.4c0.3,0,0.6-0.1,0.8-0.2
 c0.2-0.2,0.3-0.3,0.3-0.6c0-0.6-0.4-0.8-1.1-0.8h-3.7c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.5-0.4,0.9l-0.6,3.4
 c-0.1,0.3-0.1,0.5-0.1,0.5c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.8-0.4s0.6-0.4,0.8-0.5
 c0.2-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0.1,0.9,0.2s0.5,0.4,0.6,0.7c0.2,0.3,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1
 c-0.1,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.6,0.3-1,0.3c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.5-0.5-0.7-0.9-0.7
 c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.3-0.2,0.5c0,0.3,0.1,0.7,0.3,1.1s0.6,0.8,1.1,1.1c0.5,0.3,1.2,0.5,2.1,0.5
 c0.8,0,1.4-0.2,2-0.5c0.6-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9c0-0.5-0.1-0.9-0.3-1.3C46.3,33.4,46.1,33.1,45.8,32.8z"/>
  <path d="M54.7,30.3c-0.2-0.4-0.5-0.8-0.8-1.1c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5
 c-0.5,0.4-0.9,0.9-1.2,1.6c-0.2,0.4-0.3,0.9-0.4,1.5C48,32.3,48,33,48,33.7c0,0.6,0,1.1,0.1,1.5s0.2,0.9,0.3,1.3
 c0.3,0.7,0.7,1.3,1.3,1.7c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5
 c0.1-0.6,0.1-1.2,0.1-2C55.2,32.2,55,31.1,54.7,30.3z M53.1,35.6c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5
 c-0.4,0-0.8-0.1-1-0.4s-0.4-0.7-0.5-1.3c-0.1-0.6-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9c0.2-0.6,0.7-0.9,1.3-0.9
 c0.4,0,0.8,0.1,1,0.4c0.2,0.3,0.4,0.7,0.5,1.2s0.2,1.3,0.2,2.1C53.3,34.4,53.2,35.1,53.1,35.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "torchonx50"
    }
</script>

<style scoped>

</style>