import $ from "jquery"

// ==> Open Select Language
function openSelect() {
    $('.headerSelect').on('click', function() {
        $(this).closest('.selectLangage').toggleClass('open');
    });
}

export default openSelect;
