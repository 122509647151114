<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTorchonX10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M19.2,33.3c-0.6,0-1,0.5-0.9,1.1l0.6,8.3c0.6-0.4,1.2-0.8,1.9-1.1l-0.5-7.3C20.3,33.7,19.8,33.3,19.2,33.3z"/>
  <path d="M23.4,40.6l-5.3-28.4c-0.1-0.3-0.3-0.4-0.6-0.4c-0.3,0.1-0.5,0.3-0.4,0.6l5.3,28.6C22.8,40.8,23.1,40.7,23.4,40.6z"/>
  <path d="M28.4,46.5c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1l2.1-0.8c-0.5-0.5-1-1-1.5-1.6L29,45.2
 C28.4,45.4,28.2,46,28.4,46.5z"/>
  <path d="M45.1,50.9c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.4,0-0.7,0c-0.1,0.1-0.2,0.2-0.3,0.2l-1,0.4c0,0,0,0,0,0l-7.1,2.6
 c0.5-3.5-2.5-5.4-4.2-5.8l-6.7-2.1l6.2-2.2c-0.4-0.6-0.8-1.1-1.1-1.7l-9.1,3.3c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1
 c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7
 c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4
 c0.2-0.8,0.1-1.5-0.3-2.1C44.4,52.5,44.8,51.9,45.1,50.9z M32.5,54c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7-0.1l-8.9-2.7
 c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7
 c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0
 c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8C29.8,50.3,33,51.2,32.5,54z M42.2,54.5l-8.8,3.2
 c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C42.3,53.9,42.3,54.1,42.2,54.5z"/>
  <path d="M17,9.6c0.8,0.1,1.6,0.7,1.8,1.5l0,0.1c2.9,10.2,5.8,21.2,7.5,28.7c0.3-0.1,0.7-0.1,1-0.2c-0.7-1.8-1.1-3.8-1.1-5.9
 c0-0.7,0.1-1.4,0.1-2.1c-1.3-5.2-3.1-11.8-5.5-20.4c0-0.3,0.1-0.5,0.1-0.8c0-3.1-2.5-5.7-5.7-5.7s-5.7,2.5-5.7,5.7
 c0,1.8,0.8,3.4,2.1,4.4L5.7,41.2C5.6,41.5,5.3,42.7,6,44c0.8,1.4,2.5,2.5,5,3.2L9.7,57.8c0,0.4,0.1,0.8,0.5,1
 c0.1,0.1,2.7,1.6,6.1,1.6c0.1,0,0.2,0,0.3,0c-0.3-0.6-0.6-1.3-0.9-2c-1.8-0.1-3.2-0.7-4-1l0.4-3c0.7,0.3,1.6,0.6,2.7,0.8
 c-0.1-0.3-0.1-0.7-0.1-1c-1-0.2-1.9-0.5-2.4-0.8l4-33.2c0.1-0.5-0.3-1-0.9-1.1c-0.5-0.1-1,0.3-1.1,0.9l-2.5,20.8
 c-2-0.5-3-1.4-3.4-2.1l6.4-27.3C15,10.2,16,9.5,17,9.6z M11.6,41.7l-0.4,3.5c-2.2-0.6-3.1-1.4-3.4-2.1c-0.4-0.7-0.2-1.2-0.2-1.3
 l0.5-2C8.7,40.6,9.8,41.3,11.6,41.7z M12.8,10.7l-0.4,1.9c-0.4-0.6-0.7-1.3-0.7-2.1c0-2,1.7-3.7,3.7-3.7c0.9,0,1.7,0.3,2.3,0.8
 c-0.2,0-0.3-0.1-0.5-0.1C15.1,7.4,13.3,8.8,12.8,10.7z"/>
  <path d="M43.6,18.9c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9S58.5,42,58.5,33.8S51.8,18.9,43.6,18.9z M43.6,46.7
 c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9S50.7,46.7,43.6,46.7z"/>
  <path d="M36.1,35.8l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0-0.4,0.1s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2
 c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4
 C32.8,39,33,39,33.2,39c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5
 C36.8,39,37,39,37.1,39c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L36.1,35.8z"/>
  <path d="M43.3,28.5c-0.2,0-0.4,0-0.5,0.1s-0.3,0.3-0.5,0.7c-0.3,0.4-0.6,0.7-0.9,1c-0.3,0.3-0.8,0.6-1.4,0.8
 c-0.4,0.2-0.6,0.3-0.8,0.4c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.4,0,1.2-0.5,2.4-1.4v6.4
 c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1-0.5,1-1.4v-8.1c0-0.3-0.1-0.6-0.2-0.8C43.8,28.6,43.6,28.5,43.3,28.5z"/>
  <path d="M54.2,30.5c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5s-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C54.7,32.4,54.5,31.3,54.2,30.5z M52.6,35.9c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3c-0.1-0.6-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4
 c0.2,0.3,0.4,0.7,0.5,1.2s0.2,1.3,0.2,2.1C52.8,34.6,52.7,35.3,52.6,35.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "torchonx10"
    }
</script>

<style scoped>

</style>