class SessionStorageStore {
    static get(key) {
        return JSON.parse(window.sessionStorage.getItem(key));
    }

    static set(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }

    static merge(key, value) {
        this.set(key, Object.assign(this.get(key) || {}, value));
    }

    key;
    subscribers = [];

    constructor(key) {
        this.key = key;
    }

    get() {
        return SessionStorageStore.get(this.key);
    }

    set(value) {
        SessionStorageStore.set(this.key, value);
        this.fireSubscriptions();
    }

    merge(value) {
        SessionStorageStore.merge(this.key, value);
        this.fireSubscriptions();
    }

    subscribe(subscriber) {
        this.subscribers.push(subscriber);
    }

    unsubscribe(subscriber) {
        let i;
        while ((i = this.subscribers.indexOf(subscriber)) !== -1) {
            this.subscribers.splice(i, 1);
        }
    }

    fireSubscriptions() {
        const value = this.get();
        this.subscribers.forEach(subscriber => subscriber(value));
    }
}

export default SessionStorageStore;