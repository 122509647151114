<template>
    <div>
        <div class="blockSoonForm">
            <MapMarker/>
            <div class="soonForm">
                <div class="title">{{ getTranslation('newsletter.title') }}</div>
                <div class="subtitle">{{ getTranslation('newsletter.subtitle') }}</div>
                <form @submit="send" class="formSoon">
                    <div class="row">
                        <div class="col-xs-12">
                            <LabelledInput type="email" v-model="emailAddress" :required="true">
                                <template v-slot:label>{{ getTranslation('newsletter.email') }}</template>
                            </LabelledInput>
                        </div>
                        <div class="col-lg-6">
                            <LabelledInput type="text" v-model="zipCode" :required="true">
                                <template v-slot:label>{{ getTranslation('newsletter.cp') }}</template>
                            </LabelledInput>
                        </div>
                        <div class="col-lg-6">
                            <button class="btn btn-primary" type="submit">{{ getTranslation('newsletter.button_value') }}</button>
                        </div>
                        <div v-if="error" class="row">
                            <div class="col-xs-12">
                                <ErrorMessage>
                                    {{ error }}
                                </ErrorMessage>
                            </div>
                        </div>
                        <div v-if="success" class="row">
                            <div class="col-xs-12">
                                <SuccessMessage>
                                    {{ getTranslation('newsletter.confirm') }}
                                </SuccessMessage>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="textLaw">
            <RgpdMention/>
        </div>
    </div>
</template>

<script>
    import LabelledInput from "./LabelledInput";
    import RgpdMention from "./RgpdMention";
    import jsonFetch from "../jsonFetch";
    import RentalStore from "../store/RentalStore";
    import SuccessMessage from "./SuccessMessage";
    import ErrorMessage from "./ErrorMessage";
    import MapMarker from "./MapMarker";

    export default {
        name: "StayInformedForm",
        components: {MapMarker, ErrorMessage, SuccessMessage, RgpdMention, LabelledInput},
        data() {
            return {
                emailAddress: '',
                zipCode: '',
                success: false,
                error: null
            };
        },
        created() {
            const rental = RentalStore.get();

            const update = ({address}) => {
                if (address && address.zip) {
                    this.zipCode = address.zip;
                }
            };

            update(rental);
            RentalStore.subscribe(update);
        },
        methods: {
            send(event) {
                this.error = null;
                this.success = false;
                event.preventDefault();
                jsonFetch('/stay-informed', {
                    emailAddress: this.emailAddress,
                    zipCode: this.zipCode
                }).then(() => {
                    this.success = true;
                }).catch(err => {
                    this.error = err.json ? err.json.error : 'Erreur technique'
                });
            }
        }
    }
</script>

<style scoped>

</style>
