<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgRestitution" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M32.3,32.3h-11c-1.7,0-3.1,1.4-3.1,3.1v1h2v-1c0-0.6,0.5-1.1,1.1-1.1h11c0.6,0,1.1,0.5,1.1,1.1v14c0,0.6-0.5,1.1-1.1,1.1
 h-11c-0.6,0-1.1-0.5-1.1-1.1v-1h-2v1c0,1.7,1.4,3.1,3.1,3.1h11c1.7,0,3.1-1.4,3.1-3.1v-14C35.4,33.7,34,32.3,32.3,32.3z"/>
  <path d="M26,44.6c0.7-0.9,1-2.1,0.7-3.3c-0.4-1.7-2-2.9-3.8-2.9h-7.2c-0.1,0-0.1-0.1-0.1-0.1c-0.3-3.2-0.9-5.3-2-6.7
 c2.2-1.5,3.3-4.2,2.7-6.8c-0.5-2.5-2.5-4.5-5-5.1c-2-0.4-4,0.1-5.6,1.3c-1.6,1.3-2.4,3.1-2.4,5.1c0,1.9,0.8,3.7,2.2,4.9
 c-0.2,0.4-0.5,0.8-0.7,1.1c-1.3,2-2.2,3.5-2.2,9.5c0,4.3,0.2,9.4,1.3,13.8L4,55.8c1.2,2.2,3.2,3.5,5.8,3.5c2.8,0,4.9-1.5,5.6-4.2
 l0-0.2c0.3-2.5,0.3-5.5,0.3-8.6v-0.2H23C24.2,46.1,25.3,45.6,26,44.6z M15.6,44.1c-1,0-1.9,0.8-1.9,1.9v0.4c0,3,0,5.9-0.3,8.3
 c-0.5,1.8-1.8,2.6-3.7,2.6c-1.8,0-3.1-0.8-4-2.3c-1.1-4.2-1.2-9-1.2-13.2c0-5.5,0.7-6.7,1.9-8.5C6.7,32.9,7,32.5,7.3,32
 C8,30.7,7.3,30,7,29.8c-1.1-0.9-1.8-2.2-1.8-3.6c0-1.4,0.6-2.7,1.7-3.6c1.1-0.9,2.5-1.2,3.9-0.9c1.7,0.4,3.1,1.8,3.5,3.5
 c0.4,2-0.5,4-2.2,5c-0.4,0.2-0.7,0.6-0.8,1.1c-0.1,0.5,0.1,0.9,0.4,1.3c1,1,1.6,2.9,1.8,5.9c0.1,1.1,1,2,2.1,2h7.2
 c0.9,0,1.7,0.6,1.9,1.3c0.1,0.6,0,1.1-0.3,1.6c-0.3,0.4-0.9,0.7-1.4,0.7H15.6z"/>
  <path d="M59.2,32.2c-0.2-0.3-0.4-0.7-0.7-1.1c1.4-1.3,2.2-3,2.2-4.9c0-0.9-0.2-1.8-0.5-2.6c0,0,0,0,0,0c-0.4-1-1.1-1.8-1.9-2.5
 c-1.6-1.3-3.6-1.7-5.6-1.3c-1.9,0.4-3.4,1.6-4.3,3.2h-3.1c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.4c-0.5,2.6,0.6,5.2,2.8,6.7
 c-1.1,1.4-1.7,3.5-2,6.7c0,0.1-0.1,0.1-0.1,0.1h-7.2c-1.8,0-3.4,1.2-3.8,2.9c-0.3,1.2,0,2.4,0.7,3.3c0.7,0.9,1.8,1.5,3,1.5h7.3v0.2
 c0,3.1,0,6.1,0.3,8.6l0,0.2c0.8,2.6,2.8,4.2,5.6,4.2c2.6,0,4.6-1.2,5.8-3.5l0.1-0.2c1.2-4.5,1.3-9.5,1.3-13.8
 C61.4,35.7,60.5,34.3,59.2,32.2z M58.1,55c-0.8,1.5-2.2,2.3-4,2.3c-1.9,0-3.1-0.9-3.7-2.6c-0.2-2.4-0.2-5.3-0.2-8.3v-0.4
 c0-1-0.8-1.9-1.9-1.9H41c-0.6,0-1.1-0.3-1.4-0.7c-0.4-0.4-0.5-1-0.3-1.6v0c0.2-0.8,1-1.3,1.9-1.3h7.2c1.1,0,2-0.8,2.1-2
 c0.3-3,0.8-4.9,1.8-5.9c0.3-0.3,0.5-0.8,0.4-1.3c-0.1-0.5-0.3-0.9-0.8-1.1c-1.8-1-2.6-3-2.2-5c0-0.1,0-0.2,0.1-0.2h8.9
 c0.1,0.4,0.2,0.8,0.2,1.2c0,1.4-0.7,2.7-1.8,3.6C56.7,30,56,30.7,56.7,32c0.3,0.5,0.5,0.9,0.8,1.3c1.1,1.8,1.9,3,1.9,8.5
 C59.4,45.9,59.2,50.7,58.1,55z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "restitution"
    }
</script>

<style scoped>

</style>