<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgEssuieVerreX50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M21.5,9.4C21.4,9.4,21.4,9.4,21.5,9.4c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.1-0.5-0.1-0.8,0h-2.2c0.8-1.4,2-2.3,3.7-2.6
 c2.2-0.3,4.2,0.6,5.9,2.7c2,2.4,3.3,6.1,3.9,10.1c0.7-0.1,1.4-0.2,2.1-0.3c-0.7-4.3-2.2-8.4-4.4-11.1c-2.2-2.7-4.9-3.9-7.8-3.5
 c-2.9,0.4-4.7,2.2-5.7,4.6H15H6.4l-0.2,0.8c-0.2,0.6-4,14.7,1.3,21.7c1.6,2.1,3.9,3.3,6.8,3.7v13h-7c-0.6,0-1,0.5-1,1
 c0,0.6,0.5,1,1,1h9.3c-0.1,1.3-0.1,2-0.1,2c0,0.3,0.1,0.5,0.3,0.8c0.7,0.7,1.4,1.1,2.2,1.3c-0.1-0.8-0.3-1.6-0.3-2.4
 c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.5c-0.1-1.4-0.2-2.9-0.2-4.5c0-0.9-0.1-2.7,0.1-4.8c-0.3-2.4-0.7-5.8-0.6-9.1
 c-0.6,0.1-1.3,0.2-2.1,0.2c-3,0-5.2-1-6.7-2.9c-4-5.3-1.8-16.1-1.1-19.1h7h0.2c0,0,0,0,0,0h2.1c0,0,0,0,0,0h0.8c0,0,0,0,0,0h2.5v0
 c0,0,0,0,0,0h0c0.8,1.4-0.5,8.9-1.5,14.9c0.6-1.5,1.5-2.9,2.7-3.8C23.3,13.8,23.4,10.1,21.5,9.4z M17.2,35.4c0.1,0,0.4,0,0.5-0.1
 c-0.1,1.2-0.5,3.7-0.6,4.9L17.2,35.4z"/>
  <path d="M25.8,31.7c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l3.4,1c0-0.3,0-0.5,0-0.8c0-0.4,0-0.8,0.1-1.3L25.8,31.7z"/>
  <path d="M47.1,51.2l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.3-2.1-0.6-3.6-1.8-4.6l1-0.4c-0.8-0.4-1.6-0.8-2.3-1.4c0,0,0,0,0,0.1
 c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l5.3,1.6c-0.4-0.8-0.8-1.6-1.1-2.4l-3.7-1.2
 l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l3.9,1.2c-0.2-0.7-0.3-1.5-0.4-2.2l-2.9-0.9
 c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l3.4,1.1c0.1-0.7,0.2-1.3,0.4-2l-3.3-1l-0.1,0c-0.1,0-1.3-0.3-1.2-1
 c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l4.3,1.3c0.2-0.6,0.5-1.3,0.8-1.9L28,25.7l3.3-1.2c0.7-1.1,1.6-2.1,2.5-3L23.9,25
 c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2
 c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2,0.1,3.5,1.2,4.5c-0.2,0.3-0.4,0.7-0.5,1.2c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2
 c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2.2,0.1,3.7,1.5,4.7c-0.3,0.3-0.6,0.7-0.7,1.4c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2
 c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0
 c0,0,0,0,0,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1c0.6-0.4,1.1-1.1,1.4-2.3
 c-0.7,0.2-1.5,0.3-2.3,0.4C47.3,51.1,47.2,51.2,47.1,51.2z M36.9,54c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7-0.1l-8.9-2.7
 c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7c0,0,0,0,0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1
 l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7
 l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8C34.3,50.3,37.5,51.2,36.9,54z M46.7,54.5
 l-8.8,3.2c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C46.8,53.9,46.7,54.1,46.7,54.5z"/>
  <path d="M24.5,42.4c-0.2,0.5,0.1,1.1,0.7,1.3l8.8,2.7c-0.9-0.9-1.8-1.8-2.5-2.9l-5.7-1.8C25.2,41.6,24.7,41.9,24.5,42.4z"/>
  <path d="M45.7,18.9c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9S53.9,18.9,45.7,18.9z M45.7,46.7
 c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9S52.8,46.7,45.7,46.7z"/>
  <path d="M38.3,35.8l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.4,0.1
 s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
 c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4s0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1
 c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4
 c0-0.2-0.1-0.4-0.3-0.7L38.3,35.8z"/>
  <path d="M47.4,33c-0.3-0.3-0.6-0.5-1-0.7C46,32.1,45.5,32,45.1,32c-0.6,0-1.2,0.2-1.9,0.5l0.4-2.3H47c0.3,0,0.6-0.1,0.8-0.2
 c0.2-0.2,0.3-0.3,0.3-0.6c0-0.6-0.4-0.8-1.1-0.8h-3.7c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.5-0.4,0.9l-0.6,3.4
 c-0.1,0.3-0.1,0.5-0.1,0.5c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.8-0.4s0.6-0.4,0.8-0.5
 c0.2-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0.1,0.9,0.2c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.3,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1
 c-0.1,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.6,0.3-1,0.3c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.5-0.5-0.7-0.9-0.7
 c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.3-0.2,0.5c0,0.3,0.1,0.7,0.3,1.1s0.6,0.8,1.1,1.1s1.2,0.5,2.1,0.5c0.8,0,1.4-0.2,2-0.5
 c0.6-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9c0-0.5-0.1-0.9-0.3-1.3C47.9,33.7,47.7,33.3,47.4,33z"/>
  <path d="M56.3,30.5c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5c-0.5,0.4-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C56.8,32.4,56.7,31.3,56.3,30.5z M54.7,35.9c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3s-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4c0.2,0.3,0.4,0.7,0.5,1.2
 s0.2,1.3,0.2,2.1C54.9,34.6,54.8,35.3,54.7,35.9z"/>
  <path d="M16.2,27c0.5,0.1,1-0.2,1.2-0.8c0-0.1,0-0.2,0-0.4c0.2-3.8,0.9-8.8,1.9-11.9h-2.1c-0.2,0.5-0.3,1.1-0.4,1.6
 c0-0.5,0.1-1.1,0.1-1.6h-2.1c-0.4,4.1,0.1,8.4,0.4,10.6c0.1,0.5,0.1,1,0.1,1.2c0,0,0,0,0,0.1C15.3,26.4,15.7,26.9,16.2,27z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "essuieverrex50"
    }
</script>

<style scoped>

</style>