<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgServietteX50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M22.5,18.4V22c0.6-0.4,1.3-0.7,2-1v-4.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1L17,5.1
 c-0.4-0.6-1.3-0.6-1.7,0L8,16c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1V27c-1.1,0.3-2,1.3-2,2.4
 v3.2c0,1.2,0.8,2.2,2,2.4v11.6c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1l7.3,10.9
 c0.2,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6-0.2,0.8-0.4l1-1.5c-0.4-1.9-0.8-4.1-1-6.6l-4.4-6l2.1-2.8c0.3-0.4,0.2-1.1-0.2-1.4
 c-0.4-0.3-1.1-0.2-1.4,0.2l-3.3,4.5v-9.3h3.4l3.9,5.3c0.1-0.9,0.3-1.8,0.5-2.6l-2-2.7h2.3c0-0.7,0-1.3,0-2H8.4
 c-0.3,0-0.5-0.2-0.5-0.5v-3.2c0-0.3,0.2-0.5,0.5-0.5h10.1c0.1-0.7,0.3-1.4,0.5-2h-3.7L22.5,18.4z M10.6,46.9l0.9-1.2l6,8.3
 l-1.2,1.9l-5.9-8.7C10.4,47,10.5,47,10.6,46.9z M16.2,7.5L17.3,9l-5.8,8l-0.9-1.1c0,0,0,0,0,0L16.2,7.5z M9.9,26.9v-8.7l3.3,4
 c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c0.4-0.4,0.5-1,0.1-1.4l-2-2.4l5.7-7.8l3.5,5.2l-9.2,10.9H9.9z"/>
  <path d="M25.9,31.5c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l3.4,1c0-0.3,0-0.5,0-0.8c0-0.4,0-0.8,0.1-1.3L25.9,31.5z"/>
  <path d="M24.7,42.2c-0.2,0.5,0.1,1.1,0.7,1.3l8.8,2.7c-0.9-0.9-1.8-1.8-2.5-2.9l-5.7-1.8C25.4,41.4,24.8,41.7,24.7,42.2z"/>
  <path d="M47.3,51l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.3-2.1-0.6-3.6-1.8-4.6l1-0.4c-0.8-0.4-1.6-0.8-2.3-1.4c0,0,0,0,0,0.1
 c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l5.3,1.6c-0.4-0.8-0.8-1.6-1.1-2.4l-3.7-1.2
 l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l3.9,1.2c-0.2-0.7-0.3-1.5-0.4-2.2l-2.9-0.9
 c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l3.4,1.1c0.1-0.7,0.2-1.3,0.4-2l-3.3-1l-0.1,0c-0.1,0-1.3-0.3-1.2-1
 c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l4.3,1.3c0.2-0.6,0.5-1.3,0.8-1.9l-2.3-0.7l3.3-1.2c0.7-1.1,1.6-2.1,2.5-3l-9.8,3.6
 c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2
 c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2,0.1,3.5,1.2,4.5c-0.2,0.3-0.4,0.7-0.5,1.2c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2
 c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2.2,0.1,3.7,1.5,4.7c-0.3,0.3-0.6,0.7-0.7,1.4c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2
 c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0
 c0,0,0,0,0,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1c0.6-0.4,1.1-1.1,1.4-2.3
 c-0.7,0.2-1.5,0.3-2.3,0.4C47.5,50.9,47.4,50.9,47.3,51z M37.1,53.8c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7-0.1L26,52
 c-0.5-0.2-1.1,0.1-1.3,0.7s0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1L26,55.4
 c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3L26,48.7l-0.1,0
 c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8C34.6,50.1,37.6,51,37.1,53.8z M46.8,54.3L38,57.5
 c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C47,53.6,46.9,53.9,46.8,54.3z"/>
  <path d="M45.9,18.7c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9S54.1,18.7,45.9,18.7z M45.9,46.5
 c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9S53,46.5,45.9,46.5z"/>
  <path d="M38.5,35.6l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0-0.4,0.1s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2
 C35,32.9,35,33.1,35,33.2c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4
 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5
 c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L38.5,35.6z"/>
  <path d="M47.6,32.8c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-0.8-0.3-1.3-0.3c-0.6,0-1.2,0.2-1.9,0.5l0.4-2.3h3.4c0.3,0,0.6-0.1,0.8-0.2
 s0.3-0.3,0.3-0.6c0-0.6-0.4-0.8-1.1-0.8h-3.7c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.5-0.4,0.9l-0.6,3.4c-0.1,0.3-0.1,0.5-0.1,0.5
 c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.8-0.4s0.6-0.4,0.8-0.5c0.2-0.1,0.5-0.1,0.9-0.1
 c0.3,0,0.6,0.1,0.9,0.2c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.3,0.2,0.7,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.3-0.4,0.6-0.6,0.8
 c-0.3,0.2-0.6,0.3-1,0.3c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.2-0.6-0.6-0.8-1c-0.2-0.5-0.5-0.7-0.9-0.7c-0.2,0-0.4,0.1-0.6,0.2
 c-0.2,0.2-0.2,0.3-0.2,0.5c0,0.3,0.1,0.7,0.3,1.1c0.2,0.4,0.6,0.8,1.1,1.1s1.2,0.5,2.1,0.5c0.8,0,1.4-0.2,2-0.5
 c0.6-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9c0-0.5-0.1-0.9-0.3-1.3C48.1,33.4,47.9,33.1,47.6,32.8z"/>
  <path d="M56.5,30.3c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5s-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C57,32.2,56.8,31.1,56.5,30.3z M54.9,35.7c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3c-0.1-0.6-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4
 c0.2,0.3,0.4,0.7,0.5,1.2s0.2,1.3,0.2,2.1C55.1,34.4,55,35.1,54.9,35.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "serviettex50"
    }
</script>

<style scoped>

</style>