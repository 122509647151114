import $ from "jquery";

function menu() {

    $(".menuMore").on('click', function (e) {
        e.preventDefault();
        $('.subMenu').addClass('open');
    })
    $('#mobile-menu-btn').on('click', function () {
        $('.subMenu').removeClass('open');
        $('.oneSubmenu').removeClass('open');
    })
    $('.menuBack').on('click', function () {
        $('.subMenu').removeClass('open');
        $('.oneSubmenu').removeClass('open');
    })
    $('.titleSubmenu span').on('click', function () {
        $(this).closest('.oneSubmenu').toggleClass('open')
    })
    $('.hasSubmenu').on('mouseover', function(){
        $(this).addClass('open');
    })
    $('.hasSubmenu').on('mouseout', function(){
        $(this).removeClass('open');
        $('.subMenu').css({'display': ''});
    })

    $(window).scroll(function() {
        $('.hasSubmenu.open .subMenu').hide();
        $('.hasSubmenu').removeClass('open');
    })

}

export default menu;