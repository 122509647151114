<template>
    <svg version="1.1" class="svg svgCouvertures" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M50,25.6H14c-1.4,0-2.5,1.1-2.5,2.5v7.3c0,1.4,1.1,2.5,2.5,2.5h24.1H50h0.2v0H50c1.4,0,2.5-1.1,2.5-2.5v-7.3
 C52.5,26.7,51.4,25.6,50,25.6z"/>
    <path d="M14.8,18.7c0-0.8,0.7-1.5,1.5-1.5h1.4v4.6c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5v-4.6h1.1v4.6
 c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5v-4.6h0.9c0.8,0,1.5,0.7,1.5,1.5v5.5h2v-5.5c0-1.9-1.6-3.5-3.5-3.5h-1
 c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.2c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.5
 c-1.9,0-3.5,1.6-3.5,3.5v5.5h2V18.7z M34.8,15.7c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V15.7z M19.7,15.7c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V15.7z"/>
    <path d="M13.8,56.9c0,1.4,1.1,2.5,2.5,2.5h31.5c1.4,0,2.5-1.1,2.5-2.5V39.3H13.8V56.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "couvertures"
    }
</script>

<style scoped>

</style>