<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgServietteBain" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M57.4,22.9h-3.5v4h3.5c1.1,0,2-0.9,2-2S58.5,22.9,57.4,22.9z"/>
  <path d="M52.3,22.9L52.3,22.9c-0.2-0.8-0.4-1.5-0.7-2.1c-0.4-0.7-0.8-1.3-1.4-1.6c-0.4-0.3-0.9-0.5-1.4-0.5H16.2
 c-0.5,0-1,0.2-1.4,0.5c-1,0.7-1.8,2-2.1,3.7H6.6c-1.1,0-2,0.9-2,2s0.9,2,2,2h5.9v0h7.3v1.4h-7.3v1.6V31v11.1c0,1.4,1.1,2.5,2.5,2.5
 h0h4.3c0.2,0,0.3,0,0.5,0v12c0,1.4,1.1,2.5,2.5,2.5h0h27.5c1.4,0,2.5-1.1,2.5-2.5V24.5c0-0.4,0-0.8-0.1-1.2
 C52.4,23.2,52.3,23,52.3,22.9z M16.8,18.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1C17,18.9,16.9,18.9,16.8,18.8z M48.8,20.8c0.6,0,1.6,1.5,1.6,3.7V48H21.8V24.5c0-1.4-0.2-2.6-0.7-3.7H48.8z M49.9,57.2H22.4c-0.3,0-0.5-0.2-0.5-0.5V53h28.6v3.7
 C50.4,57,50.2,57.2,49.9,57.2z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "serviettebain"
    }
</script>

<style scoped>

</style>