<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitBainDouble" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M59.4,13.1c0-1.1-0.9-2-2-2h-2.6v4h2.6C58.5,15.1,59.4,14.2,59.4,13.1z"/>
  <path d="M26.4,43.4h7.1c0.2-0.7,0.5-1.4,0.9-2h-7.9c-0.2,0-0.3-0.1-0.3-0.3v-3.8h11.4c2.7-2.5,6.4-4.1,10.4-4.1
 c1.9,0,3.8,0.4,5.5,1v-22C53.4,9.3,52,7,50.2,7h-2.4H20.8h-5.7c-0.6,0-1.2,0.3-1.7,0.7c-0.3,0.3-0.6,0.6-0.8,1
 c-0.3,0.5-0.6,1.1-0.8,1.8c-0.1,0.2-0.1,0.4-0.1,0.6h-5c-1.1,0-2,0.9-2,2s0.9,2,2,2h4.8v0h7.3v1.4h-7.3v1.6v1.2v23.1c0,0,0,0,0,0
 v4.7c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.3c0.2,0,0.3,0.1,0.5,0.1h1.9v2.2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.2h0.4c0.2,0,0.3,0,0.5,0v6
 c0,0.2,0,0.3,0,0.5V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.7c0.2,0,0.3,0,0.5,0h1.9V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h2.4V56
 c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h2.4V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.2c-0.5-1.1-0.9-2.3-1.1-3.5H21.3c-0.3,0-0.5-0.2-0.5-0.5
 V12.7c0-1.4-0.2-2.6-0.7-3.7h3.3c0.4,0.9,0.7,2,0.7,3.2v28.9C24.1,42.3,25.1,43.4,26.4,43.4z M41,9c0.1,0.2,0.2,0.4,0.2,0.8v10h-12
 v-10c0-0.3,0-0.6-0.1-0.8H41z M41.2,22.8v2.1h-12v-2.1H41.2z M27,9c0.1,0.3,0.1,0.6,0.1,0.9v15.8c0,0.7,0.6,1.3,1.3,1.3h13.5
 c0.7,0,1.3-0.6,1.3-1.3V9.8c0-0.3,0-0.6-0.1-0.9h4.6h2.4c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
 c0.3,0.4,0.5,0.9,0.7,1.7c0,0.1,0,0.3,0.1,0.4c0,0.2,0,0.4,0,0.6v3v17.1H26.1V12.1c0-1.1-0.2-2.2-0.5-3.2H27z"/>
  <path d="M48,35.2c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C61.3,41.2,55.3,35.2,48,35.2z M48,59.9
 c-6.3,0-11.4-5.1-11.4-11.4S41.7,37.2,48,37.2s11.4,5.1,11.4,11.4S54.2,59.9,48,59.9z"/>
  <path d="M45.6,49.5l1.3-1.7c0.1-0.2,0.2-0.3,0.3-0.4s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0.1-0.5,0.2s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2
 s-0.2,0.3-0.2,0.4c0,0.2,0.1,0.5,0.3,0.7l1.3,1.7l-1.4,2.1c-0.2,0.3-0.3,0.6-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4s0.3,0.2,0.5,0.2
 c0.2,0,0.3-0.1,0.5-0.2s0.3-0.3,0.4-0.5l1.2-1.7l1.2,1.7c0.2,0.2,0.3,0.4,0.4,0.5s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2
 s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L45.6,49.5z"/>
  <path d="M53.4,51.4h-3.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.6-0.6,1.2-1.1s1-0.8,1.3-1.1s0.5-0.5,0.7-0.9s0.4-0.8,0.4-1.3
 c0-0.3-0.1-0.6-0.2-0.9s-0.3-0.5-0.5-0.8s-0.4-0.4-0.7-0.5c-0.4-0.2-0.9-0.3-1.5-0.3c-0.5,0-0.9,0.1-1.3,0.2s-0.7,0.3-0.9,0.6
 s-0.4,0.5-0.5,0.8s-0.2,0.6-0.2,0.8c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.3-0.6S50,46,50.1,46
 c0.3-0.4,0.7-0.7,1.2-0.7c0.2,0,0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7c0,0.2-0.1,0.5-0.2,0.7s-0.3,0.5-0.5,0.7
 s-0.5,0.5-0.8,0.7c-0.2,0.1-0.5,0.4-0.9,0.8s-0.9,0.8-1.4,1.4c-0.1,0.1-0.2,0.3-0.3,0.5s-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
 s0.4,0.2,0.7,0.2h4.3c0.3,0,0.5-0.1,0.6-0.2s0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5S53.7,51.4,53.4,51.4z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kitbaindouble"
    }
</script>

<style scoped>

</style>