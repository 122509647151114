// ==> CollapseMobile
function collapseMobile($blockClick, $blockTarget, $class) {
    const blockClick = document.querySelectorAll($blockClick);

    for (var item of blockClick) {
        item.onclick = function(e) {
            e.preventDefault();

            // Fermeture des autres collapses
            if(!this.closest($blockTarget).classList.contains($class)) {
                for (var item2 of blockClick) {
                    item2.closest($blockTarget).classList.remove($class);
                }
            }

            // Ouverture/Fermeture du collapse ciblé
            const thisEl = this;
            setTimeout(function(){
                thisEl.closest($blockTarget).classList.toggle($class);
                thisEl.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
            },500)

        }
    }

}

export default collapseMobile;
