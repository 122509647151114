<template>
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :checked="checked" @input="$emit('input', !checked)" :id="id">
        <label class="custom-control-label" :for="id">
            <slot />
        </label>
    </div>
</template>

<script>
    import uiid from 'uuid/v4';

    export default {
        name: "LabelledCheckbox",
        props: {
            checked: Boolean
        },
        data() {
            return {
                id: uiid()
            }
        }
    }
</script>

<style scoped>

</style>