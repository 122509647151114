<template>
    <div class="alert alert-success text-center">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "SuccessMessage"
    }
</script>

<style scoped>

</style>