<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgDrapBain" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M57.4,10.9h-3.6v4h3.6c1.1,0,2-0.9,2-2S58.5,10.9,57.4,10.9z"/>
  <path d="M52.4,13.5c0-1-0.1-2.5-0.1-2.5c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.3-0.2-0.6-0.4-0.9
 c-0.4-0.7-0.8-1.3-1.4-1.6c-0.4-0.3-0.9-0.5-1.4-0.5H16.1c-1.7,0-3.1,1.8-3.5,4.1h-6c-1.1,0-2,0.9-2,2s0.9,2,2,2h5.8v0h7.3v1.4
 h-7.3v1.6v1.2v23.1c0,1.4,1.1,2.5,2.5,2.5h4.3c0.2,0,0.3,0,0.5,0v12c0,1.4,1.1,2.5,2.5,2.5h27.5c1.4,0,2.5-1.1,2.5-2.5V44.3h0V13.5
 z M16.7,6.9c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0C17,7,17,7,17.1,7C16.9,7,16.8,6.9,16.7,6.9z M48.7,8.8
 c0.6,0,1.6,1.5,1.6,3.7v35.3H21.7V12.5c0-1.4-0.2-2.6-0.7-3.7H48.7z M49.8,57.3H22.3c-0.3,0-0.5-0.2-0.5-0.5v-3.9h28.6v3.9
 C50.4,57,50.1,57.3,49.8,57.3z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "drapbain"
    }
</script>

<style scoped>

</style>