<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTablierCher" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M48.2,26.7h-6.4l-0.2-2.2c0-0.5-0.5-0.9-1-0.9H23.2c-0.5,0-1,0.4-1,0.9L22,26.7h-6.3c-0.6,0-1,0.4-1,1s0.4,1,1,1h6.1
 L20,54.3c0,0.4,0.1,1.1,1,1.7c1,0.7,2.8,0.9,4.6,0.9c1.4,0,2.8-0.2,3.8-0.5l-0.1,0.8c0,0.4,0.2,0.9,0.6,1c0.1,0,2.2,0.8,4.4,0.8
 c0.7,0,1.4-0.1,2.1-0.3c0.4-0.1,0.7-0.5,0.7-1L37.1,56c0.3,0,0.6,0,1,0c1.2,0,3-0.2,5.1-0.8c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1
 c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1L42,28.7
 h6.2c0.6,0,1-0.4,1-1S48.8,26.7,48.2,26.7z M37,53.9l-0.6-15.5c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1L35.1,57c-1.3,0.2-2.9-0.2-3.7-0.4
 l0.6-7.3c0-0.6-0.4-1-0.9-1.1c-0.5,0-1,0.4-1.1,0.9l-0.4,5.2c0,0-0.1,0-0.1,0c-2.3,0.9-6.8,0.6-7.5-0.1l2-26.5c0,0,0,0,0,0
 c0,0,0,0,0,0l0.2-2.1h15.7l2.1,28C39.5,54.1,37.8,54,37,53.9z"/>
  <path d="M38,26.7H26c-0.6,0-1,0.4-1,1s0.4,1,1,1h12c0.6,0,1-0.4,1-1S38.6,26.7,38,26.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "tablierchef"
    }
</script>

<style scoped>

</style>