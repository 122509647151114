<template>
    <span class="price">
        <span itemprop="price" :title="(price.ht / 100).toFixed(2) + currencySymbol + ' HT'">
            {{((price.ht + price.tax) / 100).toFixed(2) }}
        </span>
        <span class="smallTTC">{{currencySymbol}} TTC</span>
    </span>
</template>

<script>
    export default {
        name: "Price",
        props: {
            price: Object,
            currencySymbol: String
        }
    }
</script>

<style scoped>

</style>