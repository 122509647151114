import jsonFetch from "../jsonFetch";
import {commit} from "../productsFlow";

const loadCustomerProductFlow = customerId => {
    jsonFetch('/administration/produits').then(products => commit('setProducts', {products}));
    jsonFetch(`/administration/clients/${customerId}/commandes`).then(orders => {
        commit('setOrders', {orders});
        return jsonFetch('/administration/commandes/items', {orderIds: orders.map(o => o.id)}).then(items => {
            commit('setItems', {items});
        });
    });
};

export default loadCustomerProductFlow;