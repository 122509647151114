import jsonFetch from "../jsonFetch";
import {commit} from "../productsFlow";

const loadLaundryProductFlow = () => {
    jsonFetch('/api/products').then(products => commit('setProducts', {products}));
    jsonFetch(`/gestion/commandes`).then(orders => {
        commit('setOrders', {orders});
        return jsonFetch('/gestion/commandes/items', {orderIds: orders.map(o => o.id)}).then(items => {
            commit('setItems', {items});
        });
    });
};

export default loadLaundryProductFlow;