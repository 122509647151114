<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitTable12" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M10.4,27.4c-0.5-0.2-1,0-1.2,0.4c-0.2,0.5,0,1,0.4,1.2l10.5,5c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.7-0.2,0.8-0.5
 c0.2-0.5,0-1-0.4-1.2L10.4,27.4z"/>
  <path d="M28.1,36.1l-1.8,0.3c0,0,1.3,8.5,1.6,9.9c0,0.2-0.6,0.8-0.9,1.1c-0.4,0.4-1,0.9-1.4,1.6c-0.1,0-0.2,0.1-0.2,0.1
 c-0.5-0.7-1-1.2-1.5-1.7c-0.4-0.3-0.9-0.9-0.9-1.1c0.2-1.4,1.9-12.1,1.9-12.1l-1.8-0.3c0,0-1.2,7.6-1.7,10.8L9,38.1
 c-0.1-0.1-0.2-0.2-0.4-0.2l-2.9-1.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c0,0-0.4,0.1-0.9,0.3c0.5-2.1,1.4-5.3,2.8-10
 l17-6.3v9.3c0,0.8,0.5,1.5,1.2,1.9c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.3-0.5l1.9-1.5c0,0,0-0.1,0-0.1c0,0.7,0.4,1.3,1,1.7
 l-2.6,1.1c-0.5,0.2-0.7,0.7-0.5,1.2c0.1,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.3-0.1l7-2.9v0c0.1,0.1,0.2,0.2,0.4,0.2
 c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.3-0.5l1.9-1.5c0,0,0-0.1,0-0.1c0,0.8,0.5,1.5,1.2,1.9c0.3,0.1,0.6,0.2,0.9,0.2
 c0.5,0,0.9-0.2,1.3-0.5l1.5-1.2c0.2,0.6,0.3,1.2,0.5,1.7c0.4,0,0.8-0.1,1.2-0.1c0.3,0,0.5,0,0.8,0c-0.2-0.9-0.5-1.9-0.8-3l9.1-7.4
 c0.5-0.4,0.8-1,0.8-1.6s-0.3-1.2-0.8-1.6l0,0L43.1,8c-0.6-0.5-1.5-0.6-2.2-0.3c-0.7,0.3-1.2,1.1-1.2,1.9c0,0,0-0.1,0-0.1L37.8,8
 c-0.6-0.5-1.5-0.6-2.2-0.3c-0.7,0.3-1.2,1.1-1.2,1.9c0,0,0-0.1,0-0.1L32.6,8c-0.6-0.5-1.5-0.6-2.2-0.3c-0.7,0.3-1.2,1.1-1.2,1.9
 c0,0,0-0.1,0-0.1L27.3,8c-0.6-0.5-1.5-0.6-2.2-0.3c-0.7,0.3-1.2,1.1-1.2,1.9v8.5L5.8,24.9c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1
 c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1C2,36.2,1.6,39.4,1.6,39.6c-0.1,0.5,0.3,0.9,0.7,1
 c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.3,0.9-0.7c0.4-1.3,1.3-1.7,1.7-1.9L6.8,39c0,0.1,0,0.1,0,0.2v7.5c0,0.8,0.6,1.4,1.4,1.4
 s1.4-0.6,1.4-1.4v-6.2l11.4,6.2c0.2,0.8,0.8,1.4,1.5,2c0.8,0.8,1.7,1.6,2,2.8c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1v3.4
 c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4v-5c0-0.2,0-0.4-0.1-0.5c0.3-0.4,0.7-0.7,1-1c0.7-0.7,1.4-1.4,1.5-2.3c0,0,0,0,0,0
 c0.1,0,0.3,0,0.4-0.1l0.9-0.5c0-0.8,0.1-1.5,0.3-2.2l-1.9,1C28.9,41.7,28.1,36.1,28.1,36.1z M41.1,9.6c0-0.4,0.3-0.6,0.4-0.6
 c0,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.2l9.9,8.1l-10,8.4c-0.3,0.2-0.3,0.7-0.1,1c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2
 l10.2-8.6l1.1,0.9c0,0,0,0,0,0c0.2,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5l-12.1,9.9c-0.3,0.2-0.6,0.1-0.7,0.1
 c-0.1,0-0.4-0.2-0.4-0.6V9.6z M35.8,9.6c0-0.4,0.3-0.6,0.4-0.6c0,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.2l2.8,2.2v11.9l-2.8,2.3
 c-0.3,0.2-0.3,0.7-0.1,1c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l1.9-1.6v2.6l-2.8,2.2c-0.3,0.2-0.6,0.1-0.7,0.1
 c-0.1,0-0.4-0.2-0.4-0.6V9.6z M30.6,9.6c0-0.4,0.3-0.6,0.4-0.6C31,9,31.1,9,31.3,9c0.1,0,0.3,0,0.4,0.2l2.8,2.2v11.9l-2.8,2.3
 c-0.3,0.2-0.3,0.7-0.1,1c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l1.9-1.6v2.6l-2.8,2.2c-0.3,0.2-0.6,0.1-0.7,0.1
 c-0.1,0-0.4-0.2-0.4-0.6V9.6z M25.3,9.6c0-0.4,0.3-0.6,0.4-0.6C25.8,9,25.9,9,26,9c0.1,0,0.3,0,0.4,0.2l2.7,2.2v11.9l-2.8,2.3
 c-0.3,0.2-0.3,0.7-0.1,1c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l1.9-1.6v2.6l-2.7,2.2c-0.3,0.2-0.6,0.1-0.7,0.1
 c-0.1,0-0.4-0.2-0.4-0.6V9.6z"/>
  <path d="M46.2,33.5c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C59.6,39.5,53.6,33.5,46.2,33.5z M46.2,58.2
 c-6.3,0-11.4-5.1-11.4-11.4S40,35.5,46.2,35.5s11.4,5.1,11.4,11.4S52.5,58.2,46.2,58.2z"/>
  <path d="M41.2,48.5l1-1.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.3s-0.2-0.1-0.4-0.1
 c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4l-0.8,1.1l-0.8-1.1c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.4-0.1
 c-0.2,0-0.3,0-0.4,0.1s-0.2,0.2-0.2,0.3c0,0.2,0.1,0.4,0.3,0.6l1,1.3l-1.1,1.6c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.3
 c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1s0.2-0.2,0.3-0.4l0.9-1.3l0.9,1.3c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.1,0.4,0.1
 c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.3-0.3-0.6L41.2,48.5z"/>
  <path d="M46.2,42.3c-0.2,0-0.3,0-0.4,0.1s-0.2,0.3-0.4,0.6s-0.5,0.6-0.8,0.8s-0.7,0.5-1.1,0.7c-0.3,0.1-0.5,0.3-0.7,0.4
 s-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.5s0.3,0.2,0.4,0.2c0.3,0,1-0.4,2-1.1v5.3c0,0.3,0.1,0.5,0.2,0.7s0.3,0.2,0.6,0.2
 c0.5,0,0.8-0.4,0.8-1.2v-6.7c0-0.3-0.1-0.5-0.2-0.7S46.4,42.3,46.2,42.3z"/>
  <path d="M52.7,49.6h-3.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.6-0.6,1.2-1.1s1-0.8,1.3-1.1s0.5-0.5,0.7-0.9s0.4-0.8,0.4-1.3
 c0-0.3-0.1-0.6-0.2-0.9s-0.3-0.5-0.5-0.8s-0.4-0.4-0.7-0.5c-0.4-0.2-0.9-0.3-1.5-0.3c-0.5,0-0.9,0.1-1.3,0.2s-0.7,0.3-0.9,0.6
 s-0.4,0.5-0.5,0.8s-0.2,0.6-0.2,0.8c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.3-0.6s0.2-0.4,0.2-0.5
 c0.3-0.4,0.7-0.7,1.2-0.7c0.2,0,0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7c0,0.2-0.1,0.5-0.2,0.7s-0.3,0.5-0.5,0.7
 s-0.5,0.5-0.8,0.7c-0.2,0.1-0.5,0.4-0.9,0.8s-0.9,0.8-1.4,1.4c-0.1,0.1-0.2,0.3-0.3,0.5s-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
 s0.4,0.2,0.7,0.2h4.3c0.3,0,0.5-0.1,0.6-0.2s0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5S53,49.6,52.7,49.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kittable12"
    }
</script>

<style scoped>

</style>