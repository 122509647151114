export function randomPassword(length) {
    const chars = "abcdefghijklmnopqrstuvwxyz@#%&*()-+ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < length; x++) {
        const i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
}

export function generate() {
    const userPasswordCreate = window.userPasswordCreate;
    userPasswordCreate.password.value = randomPassword(userPasswordCreate.length.value);
}