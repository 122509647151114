<template>
    <div>
        <div role="button"
             @click="collapsed = !collapsed"
             :href="`#${id}`"
             :aria-expanded="collapsed ? 'false' : 'true'"
             :aria-controls="id"
             :class="{collapsed}">
            <slot name="toggle"/>
        </div>

        <div :id="id" :class="bodyClass" v-if="!collapsed">
            <slot name="body"/>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid/v4';

    export default {
        name: "Collapse",
        props: {
            initiallyCollapsed: {type: Boolean, default: true}
        },
        data() {
            return {
                collapsed: this.initiallyCollapsed,
            };
        },
        computed: {
            id() {
                return uuid();
            },
            bodyClass() {
                return {collapse: true, in: !this.collapsed};
            }
        }
    };
</script>

<style scoped>

</style>