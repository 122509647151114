<template>
    <div class="input-group qty-input-group">
        <span class="input-group-btn">
            <button class="btn btn-primary qty-btn btnLess" v-on:click="trySetQuantity(value - 1)">
                <span>-</span>
            </button>
        </span>
        <input class="form-control text-center qty-input" type="number" min="1"
               v-bind="attributes"
               :value="value"
               @input="trySetQuantity($event.target.value)">
        <span class="input-group-btn">
          <button class="btn btn-primary qty-btn btnMore" v-on:click="trySetQuantity(value + 1)">
              <span>+</span>
          </button>
        </span>
    </div>
</template>
<script>
    export default {
        name: 'QuantityInput',
        props: {
            value: Number,
            min: {
                type: Number,
                default: 1
            },
            max: {
                type: Number,
                default: Infinity
            },
            attributes: Object
        },
        methods: {
            trySetQuantity(newQuantity) {
                const inRangeNewQuantity = newQuantity !== ''
                    ? Math.min(Math.max(this.min, newQuantity), this.max)
                    : null
                ;

                this.$emit('input', inRangeNewQuantity);
                this.$forceUpdate(); // needed to make sure value is in range
            },
        }
    }
</script>
