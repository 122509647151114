<template>
    <div>
        <slot v-for="option in options" :name="option" v-bind:radioName="option" v-bind:selected="selectedData === option" v-bind:select="() => {selectedData = option;hook(option)}"></slot>
    </div>
</template>

<script>
    export default {
        name: "RadioWrapper",
        props: {
            options: Array,
            selected: {
                type: String,
                default: null
            },
            hook: {
                type: Function,
                default: () => null
            }
        },
        data() {
            return {
                selectedData: this.selected
            }
        }
    }
</script>