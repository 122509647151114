<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitCuisine" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M44.1,43.7c0.7-3.5-2.3-5.3-4-5.7l-8.8-2.7c-0.1,0-0.8-0.3-1.6,0c0,0,0,0,0,0c0,0,0,0,0,0l-10.8,3.9c0,0,0,0-0.1,0
 c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.8,0.7-1,1.6c-0.1,0.6,0,1.2,0.3,1.7c0.1,0.1,0.2,0.3,0.3,0.4c-0.7,0.4-1.2,1.1-1.4,2.2
 c-0.3,1.9,0.1,3.3,1.1,4.2c-0.6,0.5-1,1.2-1.1,2.2c-0.4,2.9,0.5,4.5,3.2,5.3l8.8,2.7c0.1,0,0.7,0.2,1.3,0.2c0.3,0,0.5,0,0.8-0.1
 c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0L42,55.8c0,0,0.1,0,0.1-0.1c0.5-0.2,0.8-0.7,0.9-1.2c0.2-0.9,0.1-1.6-0.4-2.1c0.6-0.3,1.3-1,1.5-2.3
 c0.3-1.6-0.2-3.1-1.4-4.2C43.3,45.6,43.9,45,44.1,43.7z M42.7,43.5c-0.2,0.9-0.6,1.2-0.8,1.3L41.2,45c0.1-0.2,0.2-0.5,0.2-0.7
 c0.3-1.5-0.2-3.3-2-4c-1.4-0.5-4.1-1.4-6.5-2.2l1.2-0.4c0,0,0,0,0,0l5.6,1.7C39.9,39.4,43.3,40.4,42.7,43.5z M32.3,51.4
 c0-0.6-0.2-1.1-0.5-1.4l9.2-3.3c0.7,0.4,0.7,0.7,0.6,1.3c0,0.1-0.1,0.2-0.1,0.2L32.3,51.4z M30.2,36.6c0.2-0.1,0.5,0,0.7,0l2.1,0.7
 l-1.2,0.4c-0.9-0.3-1.8-0.6-2.5-0.8L30.2,36.6z M28.2,37.3c0.6,0.2,1.5,0.5,2.5,0.8l-3.8,1.4c-0.2,0.1-0.3,0.3-0.2,0.5
 c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0l4.7-1.7c2.6,0.9,5.8,1.9,7.4,2.5c1.4,0.5,1.8,1.9,1.6,3.2c-0.1,0.4-0.3,0.9-0.5,1.2
 l-6.9,2.5c0-0.1,0.1-0.2,0.1-0.3c0.7-3.5-2.3-5.3-4-5.7l-7.4-2.3L28.2,37.3z M18.4,45.4c0.2-1.6,1.5-1.5,1.8-1.5l8.7,2.7
 c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9l-8.8-2.7l-0.1,0c-0.2,0-1-0.3-1.3-0.7C19,41.6,19,41.4,19,41.1
 c0.1-0.3,0.2-0.5,0.3-0.6c0.2-0.1,0.6-0.1,0.8,0l8.8,2.7c0.1,0,3.6,1,3,4.1c-0.1,0.6-0.4,1-0.7,1.2c-0.5,0.3-1.3,0.2-1.9,0
 c0,0,0,0,0,0l0,0c0,0,0,0,0,0l-8.8-2.7c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9l8.8,2.7l0,0c2.1,0.7,2.1,1.1,1.9,1.8
 c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.2-1,0.1-1.3-0.1l-8.8-2.7C18.6,48.5,18,47.6,18.4,45.4z M30.4,55.4c-0.3-0.1-0.6-0.3-1-0.4l0,0l0,0
 l-8.8-2.7c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9l8.8,2.7l0,0c2.1,0.7,2,1.1,1.9,1.8c0,0.1-0.1,0.2-0.1,0.2
 c-0.3,0.2-1,0.1-1.3-0.1l-8.8-2.7c-2-0.6-2.5-1.5-2.2-3.7c0.2-1.5,1-1.5,1.2-1.5c0.2,0.1,0.4,0.1,0.6,0.2l8.8,2.7
 c0.1,0,0.7,0.2,1.3,0.2c0.3,0,0.5,0,0.8-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l9-3.3c0.2,0.2,0.4,0.4,0.4,0.7c0,0.4-0.1,0.7-0.4,1
 L30.4,55.4z M41.6,54.3c0,0.1-0.1,0.2-0.1,0.2l-9.1,3.3c0-0.6-0.2-1.1-0.5-1.4L41,53C41.7,53.4,41.7,53.7,41.6,54.3z M42.7,49.9
 c-0.2,0.9-0.6,1.2-0.8,1.3l-0.7,0.3c0.1-0.3,0.1-0.5,0.1-0.7c0-0.3-0.2-0.6-0.4-0.9l1.1-0.4c0,0,0.1,0,0.1-0.1
 c0.3-0.1,0.5-0.3,0.7-0.6C42.8,49.1,42.8,49.5,42.7,49.9z"/>
  <path d="M14.2,21.3c0-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.3,0.2-0.3,0.4L16.6,38c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.3,0.4-0.5
 L14.2,21.3z"/>
  <path d="M13.2,54.6c-1.6,0.1-2.9-0.4-3.6-0.7l0.3-2.2c0.7,0.3,1.6,0.6,2.8,0.6c0.7,0,1.5-0.1,2.3-0.4c0-0.2,0-0.5,0-0.8
 c-2.2,1-4.1,0.3-5-0.1l2.9-23.9c0-0.4-0.2-0.8-0.6-0.8c-0.4,0-0.8,0.2-0.8,0.6l-1.8,15c-1.5-0.3-2.1-1-2.5-1.5l4.6-19.7
 c0.2-0.7,0.9-1.2,1.6-1.2c0.6,0.1,1.1,0.5,1.3,1.1l0,0.1c1.3,4.6,3.2,11.5,4.3,15.8c0.5-0.2,0.8-0.3,1.3-0.4
 c-0.9-3.4-2.6-10-4.1-15.2c0-0.2,0-0.4,0-0.6c0-2.3-1.8-4.1-4.1-4.1s-4.1,1.8-4.1,4.1c0,1.3,0.6,2.4,1.5,3.2L5.2,42.2
 c-0.1,0.2-0.3,1.1,0.2,2C6,45.3,7.2,46,9,46.5l-0.9,7.7c0,0.3,0.1,0.6,0.3,0.7c0.1,0,2,1.2,4.4,1.2c0.1,0,0.3,0,0.4,0
 c0.9,0,1.6-0.2,2.1-0.5c-0.1-0.4-0.1-1-0.2-1.6C14.8,54.2,14.2,54.5,13.2,54.6z M9.5,20.2c0-1.5,1.2-2.6,2.6-2.6
 c0.6,0,1.2,0.2,1.7,0.6c-0.1,0-0.2,0-0.4-0.1c-1.5-0.1-2.8,0.8-3.1,2.3L10,21.7C9.7,21.2,9.5,20.7,9.5,20.2z M9.2,45.1
 c-1.6-0.4-2.2-1-2.5-1.5c-0.3-0.5-0.1-0.9-0.1-0.9l0.3-1.5c0.5,0.5,1.3,1.1,2.6,1.3L9.2,45.1z"/>
</g>
<path d="M46.8,28.7H34.2c-0.5,0-0.9,0.4-0.9,0.9v4.3c0.5,0.1,1.1,0.3,1.7,0.5v-3.9h10.8v4.4c0,0.8-0.8,1.7-1.6,1.7h-1.6
  c0.8,0.5,1.5,1,2.1,1.7c1.5-0.2,2.9-1.7,2.9-3.3v-5.2C47.6,29.1,47.2,28.7,46.8,28.7z"/>
<path d="M30.1,33.5L30.1,33.5v-9.1c4.3-0.4,7.5-3.9,7.8-8.7h5.2c0.3,4.8,3.5,8.3,7.8,8.7v23.2c0,0.5-0.4,0.9-0.9,0.9h-3.5
  c0,0.6-0.1,1.2-0.1,1.7H50c1.4,0,2.6-1.2,2.6-2.6V36.5h8.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-8.7V23.6
  c0-0.5-0.4-0.9-0.9-0.9c-4.1,0-7-3.2-7-7.9l0-6.9c-0.1-1.1-0.9-3.1-3.4-3.1h-1.8c-2.5,0-3.3,2.1-3.4,3.2v6.8c0,4.6-2.9,7.9-7,7.9
  c-0.5,0-0.9,0.4-0.9,0.9v10.3l0,0 M43.1,14h-5.2l0-5.9c0-0.2,0.1-1.6,1.7-1.6h1.8c1.5,0,1.6,1.3,1.7,1.5V14z"/>
</svg>

</template>

<script>
    export default {
        name: "kitcuisine"
    }
</script>

<style scoped>

</style>