import Cookie from 'js-cookie';

export default {
    on(cookieName, handler) {
        const previous = this;
        return {
            ...previous,
            listeners() {
                return {...previous.listeners(), [cookieName]: handler};
            },
        };
    },
    handle() {
        const listeners = this.listeners();
        Object.keys(listeners).forEach(cookieName => {
            const event = Cookie.get(cookieName);

            if (event) {
                try {
                    listeners[cookieName](JSON.parse(event));
                } finally {
                    Cookie.remove(cookieName);
                }
            }
        });
    },
    listeners() {
        return {};
    },
};