<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgEssuieVerreX10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M17.9,26.3c0-0.1,0-0.2,0-0.4c0.2-3.8,0.9-8.8,1.9-11.9h-2.1c-0.2,0.5-0.3,1.1-0.4,1.6c0-0.5,0.1-1.1,0.1-1.6h-2.1
 c-0.4,4.1,0.1,8.4,0.4,10.6c0.1,0.5,0.1,1,0.1,1.2c0,0,0,0,0,0.1c0,0.5,0.3,1,0.9,1.1C17.2,27.2,17.7,26.8,17.9,26.3z"/>
  <path d="M24.3,38.8c-0.1,0.4-0.2,0.8-0.3,1.3c0.7-0.1,1.5-0.2,2.2-0.3c0-0.1,0.1-0.3,0.1-0.4c0.2-0.6,0.3-1.2,0.5-1.8
 c1.4-5.3,1.4-12.6,1.3-14c0-0.6-0.6-1-1.1-0.9c-0.6,0-1,0.6-0.9,1.1c0.1,1.1,0.1,8.2-1.2,13.3C24.6,37.6,24.4,38.2,24.3,38.8z"/>
  <path d="M6.7,49.5c0,0.6,0.5,1,1,1h4.6c0.1-0.7,0.2-1.4,0.4-2.1h-5C7.1,48.5,6.7,49,6.7,49.5z"/>
  <path d="M40.3,51.4l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.5-3.5-2.5-5.4-4.2-5.8l-6.7-2.1l7.5-2.7c0.1,0,0.4,0,0.5,0l0.9,0.3l-3.6,1.3
 c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1l4.6-1.7c-1-1.1-1.9-2.4-2.6-3.8c-0.3,0-0.7,0-1.1,0.1
 c0,0,0,0,0,0c0,0,0,0,0,0l-10.9,3.9c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1
 c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1
 c0.5-0.3,1-0.9,1.3-1.9c-0.7-0.1-1.3-0.2-2-0.3C40.6,51.2,40.4,51.3,40.3,51.4z M30.1,54.2c-0.1,0.5-0.3,0.9-0.6,1.1
 c-0.3,0.2-0.9,0.2-1.7-0.1L19,52.5c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5
 c-0.1,0.1-0.7,0.1-1.1-0.1L19,55.8c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7
 c0.2-0.5-0.1-1.1-0.7-1.3L19,49.1l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8
 C27.6,50.5,30.6,51.5,30.1,54.2z M39.8,54.7L31,57.9c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C40,54.1,39.9,54.3,39.8,54.7z"/>
  <path d="M19.7,41.2C20,37.8,20.4,34.1,21,31c0,0,0-0.1,0-0.1c0.2-1,0.3-2,0.5-3c2.2-12.5,2.8-17.5,0.4-18.3c0,0,0,0-0.1,0
 c0,0-0.1,0-0.1,0c-0.3-0.1-0.5-0.1-0.8,0h-2.2c0.8-1.4,2-2.3,3.7-2.6c2.2-0.3,4.2,0.6,5.9,2.7c2.3,2.8,3.8,7.5,4.2,12.1
 c0.6-0.6,1.2-1.1,1.9-1.6c-0.6-4.6-2.2-9-4.5-11.8c-2.2-2.7-4.9-3.9-7.8-3.5c-2.9,0.4-4.7,2.2-5.7,4.6h-0.9H6.9l-0.2,0.8
 c-0.2,0.6-4,14.7,1.3,21.6c1.6,2.1,3.9,3.3,6.8,3.7v8.9C16,43,17.7,42,19.7,41.2z M17.7,40.4l0-4.9c0.1,0,0.4,0,0.5-0.1
 C18.1,36.6,17.8,39.2,17.7,40.4z M9.6,30.6c-4-5.3-1.8-16.1-1.1-19.1h7h0.2c0,0,0,0,0,0h2.1c0,0,0,0,0,0h0.8c0,0,0,0,0,0h2.5v0
 c0,0,0,0,0,0h0c0.8,1.5-0.6,9.9-1.7,16c-0.3,1.9-0.7,3.9-1,5.8c-0.7,0.1-1.4,0.2-2.2,0.2C13.3,33.5,11.1,32.6,9.6,30.6z"/>
  <path d="M44.6,19.1c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9S59.5,42.2,59.5,34S52.8,19.1,44.6,19.1z M44.6,46.9
 c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9S51.7,46.9,44.6,46.9z"/>
  <path d="M37.2,36l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4C38.4,33.1,38.2,33,38,33
 c-0.2,0-0.3,0-0.4,0.1s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5S34.5,33,34.4,33c-0.2,0-0.4,0.1-0.5,0.2
 s-0.2,0.3-0.2,0.4c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4s0.3,0.2,0.5,0.2
 c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.2
 c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L37.2,36z"/>
  <path d="M44.4,28.7c-0.2,0-0.4,0-0.5,0.1s-0.3,0.3-0.5,0.7s-0.6,0.7-0.9,1c-0.3,0.3-0.8,0.6-1.4,0.8c-0.4,0.2-0.6,0.3-0.8,0.4
 s-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.2,0.2,0.3,0.2,0.5,0.2c0.4,0,1.2-0.5,2.4-1.4v6.4c0,0.4,0.1,0.7,0.3,0.9
 s0.4,0.3,0.7,0.3c0.6,0,1-0.5,1-1.4v-8.1c0-0.3-0.1-0.6-0.2-0.8S44.6,28.7,44.4,28.7z"/>
  <path d="M55.2,30.7c-0.2-0.4-0.5-0.8-0.8-1.1c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5s-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C55.7,32.6,55.5,31.5,55.2,30.7z M53.6,36.1c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3s-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9c0.2-0.6,0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4
 c0.2,0.3,0.4,0.7,0.5,1.2c0.1,0.5,0.2,1.3,0.2,2.1C53.8,34.8,53.7,35.5,53.6,36.1z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "essuieverrex10"
    }
</script>

<style scoped>

</style>