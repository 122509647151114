<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgLiteau" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M44.3,30.3c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S45,30.3,44.3,30.3z M44.1,31.5
 c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2C44.6,31.8,44.1,31.8,44.1,31.5z"/>
  <path d="M25.6,30.4c-0.6,0-1,0.4-1,1v22.3c0,0.6,0.4,1,1,1s1-0.4,1-1V31.4C26.6,30.9,26.1,30.4,25.6,30.4z"/>
  <path d="M12.4,31v10.8H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h7.4v1H6.5v1c0,1.4,1.1,2.5,2.5,2.5h2.6h0c0.3,0,0.6-0.1,0.9-0.2v9.1
 c0,1.1,0.9,2.1,2.1,2.1H37c1.1,0,2.1-0.9,2.1-2.1V43.8h2.7c0.6,0.5,1.3,0.7,2.1,0.7h0.9c1.3,0,2.4-0.7,3-1.7H56
 c1.3,0,2.4-1.1,2.4-2.4c0-0.4-0.1-0.7-0.2-1c0.5-0.4,0.8-1.1,0.8-1.8c0-0.4-0.1-0.7-0.2-1c0.5-0.4,0.8-1.1,0.8-1.8
 c0-0.3-0.1-0.7-0.2-1c0.4-0.4,0.7-1,0.7-1.7c0-1.3-1.1-2.4-2.4-2.4H48c-0.5-1.3-1.8-2.1-3.2-2.1h-4.4v1.4h-1.7l0,0
 c-0.5-1.6-1.5-2.6-2.7-2.6H9.5c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.2-0.7,0.6-0.9,1c-0.1,0.2-0.3,0.4-0.4,0.7
 c-0.1,0.2-0.1,0.4-0.2,0.6H5c-0.6,0-1,0.4-1,1s0.4,1,1,1L12.4,31L12.4,31z M10.1,26.4C10.1,26.4,10.1,26.4,10.1,26.4
 C10.1,26.4,10.1,26.4,10.1,26.4C10.1,26.4,10,26.4,10.1,26.4C10,26.4,10,26.4,10.1,26.4z M37,57.3C37,57.3,37,57.3,37,57.3
 l-22.5,0.1c0,0-0.1,0-0.1-0.1V31c0-1-0.1-1.9-0.4-2.7h22c0.2,0.1,1,1,1,2.7V57.3z M38.9,29.9C39,29.9,39,29.9,38.9,29.9
 C39,29.9,39,29.9,38.9,29.9z M40.4,41.8H39V31c0,0,0,0,0,0h1.4V41V41.8z M46.3,41c0,0.8-0.7,1.5-1.5,1.5h-0.9
 c-0.8,0-1.5-0.7-1.5-1.5V29.5h2.4c0.8,0,1.5,0.7,1.5,1.5V41z M56,40.8h-7.5v-9.2h9c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.2,0.4
 l0,0h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.4-0.4,0.4h-0.1c0,0,0,0,0,0h-3.1c-0.6,0-1,0.4-1,1
 s0.4,1,1,1h3c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.2,0.4-0.5,0.4h-2.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.4c0.1,0.1,0.2,0.2,0.2,0.4
 C56.4,40.6,56.2,40.8,56,40.8z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "liteau"
    }
</script>

<style scoped>

</style>