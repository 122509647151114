<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTorchonX20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M18.8,33.6c-0.6,0-1,0.5-0.9,1.1l0.3,3.5c0.4-1.3,1-2.5,1.8-3.5l0-0.1C19.8,33.9,19.3,33.5,18.8,33.6z"/>
  <path d="M21.6,33.1l-3.9-20.8c-0.1-0.3-0.3-0.4-0.6-0.4c-0.3,0.1-0.5,0.3-0.4,0.6l4,21.2C20.9,33.5,21.2,33.3,21.6,33.1z"/>
  <path d="M16.3,58.6c-2.2,0.1-4.1-0.6-5-1l0.4-3c0.9,0.4,2.3,0.8,3.9,0.8c0.7,0,1.5-0.1,2.3-0.3c-0.1-0.3-0.1-0.6-0.2-1
 c-2.6,0.8-4.9,0-6-0.6l4-33.2c0.1-0.5-0.3-1-0.9-1.1c-0.5-0.1-1,0.3-1.1,0.9l-2.5,20.8c-2-0.5-3-1.4-3.4-2.1l6.4-27.3
 c0.2-1,1.2-1.7,2.3-1.6c0.8,0.1,1.6,0.7,1.8,1.5l0,0.1c2,7,4,14.4,5.6,20.7c0.7-0.2,1.3-0.4,2-0.5c-1.3-5.1-3.1-11.7-5.5-20.1
 c0-0.3,0.1-0.5,0.1-0.8c0-3.1-2.5-5.7-5.7-5.7s-5.7,2.5-5.7,5.7c0,1.8,0.8,3.4,2.1,4.4L5.2,41.4c-0.1,0.3-0.4,1.5,0.3,2.8
 c0.8,1.4,2.5,2.5,5,3.2L9.2,58c0,0.4,0.1,0.8,0.5,1c0.1,0.1,2.7,1.6,6.1,1.6c0.2,0,0.4,0,0.5,0c1.2-0.1,2-0.3,2.7-0.6
 c-0.2-0.6-0.4-1.2-0.6-1.9C18.1,58.3,17.4,58.6,16.3,58.6z M11.2,10.8c0-2,1.7-3.7,3.7-3.7c0.9,0,1.7,0.3,2.3,0.8
 c-0.2,0-0.3-0.1-0.5-0.1c-2-0.2-3.9,1.2-4.4,3.1l-0.4,1.9C11.5,12.2,11.2,11.5,11.2,10.8z M10.8,45.4c-2.2-0.6-3.1-1.4-3.4-2.1
 c-0.4-0.7-0.2-1.2-0.2-1.3l0.5-2c0.7,0.8,1.8,1.5,3.6,1.9L10.8,45.4z"/>
  <path d="M44.5,51.4l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.3-1.8-0.4-3.1-1.3-4.1l1.8-0.6c-2.4-1.1-4.4-2.7-6.1-4.7l-7.5-2.3
 c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7c0,0,0,0,0,0c1.9,0.6,1.9,0.9,1.7,1.5c0,0-0.1,0-0.2,0
 c-0.3-0.1-0.6-0.3-0.9-0.3l-8.8-2.7c-0.1,0-0.4-0.1-0.9-0.2c-1.1-0.6-1.4-1.4-1.1-3.1c0.2-1.2,1.1-1.3,1.5-1.3l6.8,2.1
 c-0.5-0.8-1-1.6-1.4-2.5l-5-1.6l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l5.1,1.6
 c-0.2-0.7-0.4-1.5-0.6-2.3l-1.7-0.5l1.5-0.6c-0.1-0.7-0.1-1.3-0.1-2c0,0,0,0,0,0l-5.4,2c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1
 c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.3,2,0.1,3.4,1.1,4.4
 c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7
 c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4
 c0.2-0.8,0.1-1.5-0.3-2.1c0.5-0.4,1.1-1,1.3-2c-0.7,0.1-1.5,0.2-2.3,0.2C44.6,51.3,44.6,51.3,44.5,51.4z M34.3,54.2
 c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7,0l0,0h0c0,0,0,0,0,0l-8.9-2.7c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3
 l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7
 c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.3,0.2-0.4
 c0.2,0.1,0.4,0.1,0.6,0.2l8.9,2.7c0.1,0,0.5,0.2,1,0.2C33.4,51.3,34.6,52.3,34.3,54.2z M44,54.7l-8.8,3.2c0-0.4-0.1-0.7-0.3-0.9
 l8.8-3.2C44.1,54,44.1,54.3,44,54.7z"/>
  <path d="M44,19.1c-8.2,0-14.9,6.7-14.9,14.9S35.8,48.9,44,48.9S58.9,42.2,58.9,34S52.2,19.1,44,19.1z M44,46.9
 c-7.1,0-12.9-5.8-12.9-12.9S36.9,21.1,44,21.1c7.1,0,12.9,5.8,12.9,12.9S51.1,46.9,44,46.9z"/>
  <path d="M36.6,36l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0-0.4,0.1s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5S34,33,33.8,33c-0.2,0-0.4,0.1-0.5,0.2
 c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4
 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5
 c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L36.6,36z"/>
  <path d="M45.5,37.4h-3.7c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.3,0.8-0.8,1.5-1.4c0.7-0.6,1.2-1,1.5-1.3c0.3-0.3,0.6-0.6,0.9-1.1
 c0.3-0.5,0.4-1,0.4-1.6c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.3-0.3-0.7-0.6-0.9s-0.5-0.5-0.9-0.6c-0.5-0.2-1.1-0.3-1.8-0.3
 c-0.6,0-1.1,0.1-1.6,0.3c-0.4,0.2-0.8,0.4-1.1,0.7s-0.5,0.6-0.7,1s-0.2,0.7-0.2,1c0,0.3,0.1,0.5,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2
 c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.3-0.4,0.4-0.7s0.2-0.5,0.2-0.6c0.4-0.5,0.8-0.8,1.5-0.8c0.3,0,0.6,0.1,0.8,0.2
 c0.2,0.1,0.4,0.3,0.6,0.6c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.6-0.6,0.8c-0.3,0.3-0.6,0.6-1,0.8
 c-0.2,0.1-0.6,0.5-1.1,0.9c-0.5,0.5-1.1,1-1.7,1.7c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.7c0,0.3,0.1,0.5,0.3,0.7
 c0.2,0.2,0.5,0.3,0.8,0.3h5.2c0.3,0,0.5-0.1,0.7-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.5-0.3-0.6C46.2,37.5,45.9,37.4,45.5,37.4
 z"/>
  <path d="M54.6,30.7c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5s-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C55.1,32.6,55,31.5,54.6,30.7z M53,36.1c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3c-0.1-0.6-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4
 c0.2,0.3,0.4,0.7,0.5,1.2s0.2,1.3,0.2,2.1C53.2,34.8,53.1,35.5,53,36.1z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "torchonx20"
    }
</script>

<style scoped>

</style>