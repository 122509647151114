<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgHousses" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M33.9,30c1.4,0,2.5-1.1,2.5-2.5v-7.3c0-1.4-1.1-2.5-2.5-2.5h-21c-1.4,0-2.5,1.1-2.5,2.5v7.3c0,1.4,1.1,2.5,2.5,2.5H33.9z"
 />
  <path d="M13.6,9.8c0-0.8,0.7-1.5,1.5-1.5h1.4v4.6c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h1.4c0.8,0,1.5,0.7,1.5,1.5
 v6.5h2V9.8c0-1.9-1.6-3.5-3.5-3.5h-1.4c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.5c-1.9,0-3.5,1.6-3.5,3.5v6.5h2V9.8
 z M18.5,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9c-0.3,0-0.5-0.2-0.5-0.5V6.8z"/>
  <path d="M34.5,33.3v-1.9H12.6V48c0,1.4,1.1,2.5,2.5,2.5h10.2c-0.2-1-0.3-2-0.3-3.1C24.9,41.1,28.9,35.6,34.5,33.3z"/>
  <path d="M40.2,34.1c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C53.6,40.1,47.6,34.1,40.2,34.1z M40.2,58.8
 c-6.3,0-11.4-5.1-11.4-11.4S34,36.1,40.2,36.1s11.4,5.1,11.4,11.4S46.5,58.8,40.2,58.8z"/>
  <path d="M43.5,47.2c-0.3-0.2-0.7-0.4-1.2-0.6s-1-0.3-1.5-0.4c-0.5-0.1-0.8-0.2-1-0.3c-0.2-0.1-0.4-0.1-0.6-0.2s-0.3-0.2-0.5-0.4
 s-0.2-0.3-0.2-0.5c0-0.3,0.2-0.6,0.5-0.8s0.7-0.3,1.3-0.3c0.6,0,1,0.1,1.2,0.3c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.3,0.3,0.5,0.4,0.6
 c0.1,0.1,0.3,0.2,0.5,0.2c0.3,0,0.5-0.1,0.6-0.3s0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.2-0.8s-0.4-0.5-0.6-0.8c-0.3-0.3-0.7-0.5-1.1-0.6
 c-0.4-0.2-1-0.2-1.6-0.2c-0.8,0-1.4,0.1-2,0.3c-0.6,0.2-1,0.6-1.3,1s-0.5,0.9-0.5,1.5c0,0.6,0.1,1.1,0.4,1.5
 c0.3,0.4,0.7,0.7,1.2,0.9s1.1,0.4,1.8,0.6c0.5,0.1,1,0.2,1.3,0.3s0.6,0.3,0.8,0.5c0.2,0.2,0.3,0.5,0.3,0.8c0,0.4-0.2,0.8-0.6,1
 s-0.9,0.4-1.5,0.4c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.5-0.4-0.6
 c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.6c0,0.4,0.1,0.9,0.4,1.3c0.3,0.4,0.7,0.8,1.1,1.1
 c0.6,0.4,1.5,0.6,2.4,0.6c0.8,0,1.5-0.1,2.1-0.4s1.1-0.7,1.4-1.1c0.3-0.5,0.5-1,0.5-1.6c0-0.5-0.1-0.9-0.3-1.3
 C44,47.7,43.8,47.5,43.5,47.2z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "housses"
    }
</script>

<style scoped>

</style>