<template>

    <!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1"  class="svg svgHousse" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g id="g4152" transform="translate(-21.338983,4.6881332)">
</g>
<g>
  <path d="M23,11.4c0-0.8,0.7-1.5,1.5-1.5l1-0.1v4.6c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V9.9h0.9c0.8,0,1.5,0.7,1.5,1.5
 L42,25.9l1.9,1.3l0-15.8c0-1.9-1.6-3.5-3.5-3.5h-1c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1l-1.2,0.1
 c-1.9,0-3.5,1.6-3.5,3.5v5.5h2.3L23,11.4z M27.4,8.4c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V8.4z"/>
  <path d="M43.9,27.8l-13,1.9c-4.6,0.7-6.3-0.6-6.4-3.2l-0.1-7.8l-5.7,0.1v11.2c0,0,0,0,0,0l0,18c0,2.3,1.2,4.2,2.8,4.2l3.7,0v0h2.1
 l3,0c0,0,0,0,0,0h3.1c0,0,0,0,0,0l8.8,0c1.6,0,2.8-1.8,2.8-4.2l0-19.5L43.9,27.8z"/>
  <path d="M27,26.2c0,0.9,1.3,1.9,2.6,1.7l11.3-1.5l-14-6.6L27,26.2z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "housse"
    }
</script>

<style scoped>

</style>