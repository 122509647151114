<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitBainSimplePeignoire" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M47.6,14l-4.5-2.2c0-0.3-0.1-0.5-0.1-0.7c0.1,0,0.1,0,0.2-0.1c0.7-0.3,2.1-0.8,2.1-2.7c0-2-1.2-3.3-3.1-3.3
  c-1.8,0-2.9,1.2-2.9,2.2c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8c0-0.1,0.3-0.6,1.3-0.6c0.6,0,1.4,0.2,1.4,1.7
  c0,0.7-0.3,0.9-1.1,1.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.4-0.7,1.3-0.5,2.1L37,14.1c-2.9,0.6-5.2,1.6-6.8,2.9H17.7h-4.6
  c-0.5,0-1,0.2-1.4,0.6c-0.2,0.2-0.5,0.5-0.7,0.8c-0.3,0.4-0.5,0.9-0.6,1.5c0,0.2-0.1,0.3-0.1,0.5h-4c-0.9,0-1.6,0.7-1.6,1.6
  s0.7,1.6,1.6,1.6h3.9v0H16v1.1h-5.9v1.3v1v18.6c0,0,0,0,0,0v3.8c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-1.8c0.1,0,0.3,0,0.4,0h1.5
  v1.8c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-1.8h0.3c0.1,0,0.3,0,0.4,0v4.9c0,0.1,0,0.3,0,0.4v3.8c0,0.4,0.4,0.8,0.8,0.8
  c0.4,0,0.8-0.4,0.8-0.8v-2.2c0.1,0,0.3,0,0.4,0h1.5v2.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1
  c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1
  c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.9v2.1
  c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.1h1.5c0.1,0,0.3,0,0.4,0v2.2c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-3.8
  c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3v-2.6H49c1.1,0,2-0.9,2-2l-1.8-11.3c0.6-0.3,1.1-0.9,1.1-1.7v-0.6c0-0.8-0.5-1.5-1.3-1.7l2.1-7.3
  l0-0.2c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3v12.9c0,1.1,0.9,2,2,2h2.2c1.1,0,2-0.9,2-2v-13C58.1,18.8,54.9,15.5,47.6,14z M44.9,14.8c0.2,0.1,0.3,0.3,0.3,0.5l-1.3,5c-0.1-0.6-0.2-1.1-0.4-1.5l1-3.6C44.5,14.9,44.7,14.8,44.9,14.8z M43.9,37.2l0.5,2.2
  l-0.5,0.1V37.2z M42.4,13.3L44,14h-3.2L42.4,13.3z M38.4,15.5l0.4,1.5h-5.5C34.7,16.3,36.4,15.8,38.4,15.5z M33.9,18.6
  c0.1,0.1,0.1,0.3,0.1,0.7v8.1h-9.7v-8.1c0-0.2,0-0.4,0-0.7H33.9z M34.1,29.8v1.7h-9.7v-1.7H34.1z M22.7,18.6c0,0.2,0.1,0.5,0.1,0.7
  V32c0,0.6,0.5,1,1,1h10.9c0.6,0,1-0.5,1-1V19.3c0-0.3,0-0.5-0.1-0.7h3.7h2c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2
  c0.2,0.3,0.4,0.8,0.6,1.4c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0,0.5v2.4v13.8H21.9V21.1c0-0.9-0.2-1.8-0.4-2.6H22.7z M40.7,52.3
  c0,0.2-0.2,0.4-0.4,0.4H18c-0.2,0-0.4-0.2-0.4-0.4V21.6c0-1.1-0.2-2.1-0.5-3h2.7c0.3,0.7,0.6,1.6,0.6,2.6v23.3c0,1,0.8,1.8,1.8,1.8
  h18.5V52.3z M40.7,44.7H22.2c-0.1,0-0.2-0.1-0.2-0.2v-3.1h20.4v3.1c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1H40.7z M49.4,47.7
  c0,0.2-0.2,0.4-0.4,0.4h-6.7v-1.8c0.9-0.1,1.7-0.9,1.7-1.8v-3.3c0.1,0,0.2,0,0.3,0l0.6-0.1c0.9-0.2,1.4-1.1,1.2-2l-0.6-2.4h2.2
  L49.4,47.7z M44.8,33.9h3.7c0.1,0,0.2,0.1,0.2,0.2v0.6c0,0.1-0.1,0.2-0.2,0.2h-3.5L44.8,33.9z M44.4,23.5h2.7c0.9,0,1.6-0.7,1.6-1.6
  s-0.7-1.6-1.6-1.6h-1.9l1.3-4.8c8.3,1.4,10,4.8,10,9.3v13c0,0.2-0.2,0.4-0.4,0.4h-2.2c-0.2,0-0.4-0.2-0.4-0.4v-13h0
  c0-1.1-0.9-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.8l-2.1,7.6h-3l-0.5-1.8v-5.4"/>
</svg>

</template>

<script>
    export default {
        name: "kitbainsimplepeignoire"
    }
</script>

<style scoped>

</style>