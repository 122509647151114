import moment from "moment";

const computeDuration = (from, to) => {
    if (!from || !to) {
        return null;
    }

    const fromDate = moment(from, 'DD/MM/YYYY');
    const toDate = moment(to, 'DD/MM/YYYY');

    return (toDate - fromDate) / (1000 * 60 * 60 * 24);
};

export default computeDuration;