<template>

    <!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" class="svg svgKitTable" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M12,27.4c-0.5-0.2-1,0-1.2,0.4c-0.2,0.5,0,1,0.4,1.2l10.5,5c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.7-0.2,0.8-0.5
 c0.2-0.5,0-1-0.4-1.2L12,27.4z"/>
  <path d="M59.1,19.4L46.4,9.7c-0.6-0.5-1.5-0.6-2.2-0.3S43,10.5,43,11.3v-0.1l-1.9-1.5c-0.6-0.5-1.5-0.6-2.2-0.3s-1.2,1.1-1.2,1.9
 v-0.1l-1.8-1.5c-0.6-0.5-1.5-0.6-2.2-0.3s-1.2,1.1-1.2,1.9v-0.1l-1.9-1.5c-0.6-0.5-1.5-0.6-2.2-0.3s-1.2,1.1-1.2,1.9v8.5l-18,6.8
 c-0.1,0-0.1,0.1-0.2,0.1l-0.1,0.1l-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1C5.5,37.9,5.1,41.2,5,41.3c-0.1,0.5,0.3,0.9,0.7,1
 c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.3,0.9-0.7c0.4-1.3,1.3-1.7,1.7-1.9l1.6,1c0,0.1,0,0.1,0,0.2v7.5c0,0.8,0.6,1.4,1.4,1.4
 s1.4-0.6,1.4-1.4v-6.2l11.4,6.2c0.2,0.8,0.8,1.4,1.5,2c0.8,0.8,1.7,1.6,2,2.8c0,0.1,0,0.1,0.1,0.2v0.1v3.4c0,0.8,0.6,1.4,1.4,1.4
 c0.8,0,1.4-0.6,1.4-1.4v-4.6c0.1-1,0.4-1.4,0.9-1.9c0.7-0.6,1.3-1.2,1.5-2l11.4-6.2v6.2c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
 v-7.5c0-0.1,0-0.1,0-0.2l1.6-1c0.4,0.2,1.7,0.6,2.1,1.9c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.1,0,0.2,0c0.4-0.1,0.8-0.5,0.7-1
 c-0.1-0.1-0.4-2.3-2.2-8.8c-0.2-0.8-0.4-1.6-0.7-2.5l9.1-7.4c0.5-0.4,0.8-1,0.8-1.6C59.9,20.4,59.6,19.8,59.1,19.4z M39.1,11.3
 L39.1,11.3c0.1-0.4,0.4-0.6,0.5-0.6c0,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.2l2.8,2.2V25l-2.8,2.3c-0.3,0.2-0.3,0.7-0.1,1
 c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l1.9-1.6v2.6l-2.8,2.2c-0.3,0.2-0.6,0.1-0.7,0.1c-0.1,0-0.4-0.2-0.4-0.6V11.3z
  M33.9,11.3c0-0.4,0.3-0.6,0.4-0.6c0,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.2l2.8,2.2v11.9L35,27.2c-0.3,0.2-0.3,0.7-0.1,1
 c0.1,0.2,0.3,0.2,0.5,0.2s0.3-0.1,0.5-0.2l1.9-1.6v2.6L35,31.4c-0.3,0.2-0.6,0.1-0.7,0.1c-0.1,0-0.4-0.2-0.4-0.6V11.3z M28.6,11.3
 c0-0.4,0.3-0.6,0.4-0.6c0,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.2l2.7,2.2v11.9l-2.7,2.4c-0.3,0.2-0.3,0.7-0.1,1
 c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l1.9-1.6v2.6l-2.8,2.3c-0.3,0.2-0.6,0.1-0.7,0.1c-0.1,0-0.4-0.2-0.4-0.6V11.3z
  M48.9,37.8c-0.2,0-0.4,0-0.5,0.1c-0.1,0-0.1,0.1-0.2,0.1l-2.9,1.6c-0.2,0-0.3,0.1-0.4,0.2l-10.1,5.5c0,0,0,0,0,0l-1.9,1
 c-0.5-2.9-1.4-8.5-1.4-8.5l0,0c-0.2-1.3-0.3-2.2-0.3-2.2l-1.8,0.3c0,0,1.7,10.7,1.9,12.1c0,0.2-0.5,0.8-0.9,1.1
 c-0.3,0.3-0.5,0.5-0.8,0.8c0,0,0,0,0,0.1c-0.2,0.2-0.4,0.5-0.5,0.7c-0.1,0-0.2,0.1-0.2,0.1c-0.5-0.7-1-1.2-1.5-1.7
 c-0.4-0.3-0.9-0.9-0.9-1.1c0.2-1.4,1.9-12.1,1.9-12.1l-1.8-0.3c0,0-1.2,7.6-1.7,10.8l-12.1-6.6c-0.1-0.1-0.2-0.2-0.4-0.2L9.3,38
 c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c0,0-0.4,0.1-0.9,0.3c0.5-2.1,1.4-5.3,2.8-10l17-6.3v9.3c0,0.8,0.5,1.5,1.2,1.9
 c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.3-0.5l1.9-1.5v-0.1c0,0.7,0.4,1.3,1,1.7l-2.6,1.1c-0.5,0.2-0.7,0.7-0.5,1.2
 c0.1,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.3-0.1l7-2.9c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.3-0.5
 l1.9-1.5V31c0,0.8,0.5,1.5,1.2,1.9c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,0.9-0.2,1.3-0.5l1.5-1.2c0.2,0.6,0.3,1.2,0.5,1.7c0,0,0,0,0,0
 c0.6,2.1,1,3.8,1.3,5C49.7,37.9,48.9,37.8,48.9,37.8z M57.6,21.6l-12.1,9.9c-0.3,0.2-0.6,0.1-0.7,0.1c-0.1,0-0.4-0.2-0.4-0.6V11.3
 c0-0.4,0.3-0.6,0.4-0.6c0,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.2l9.9,8.1l-10,8.4c-0.3,0.2-0.3,0.7-0.1,1c0.1,0.2,0.3,0.2,0.5,0.2
 c0.2,0,0.3-0.1,0.5-0.2l10.2-8.6l1.1,0.9c0.2,0.1,0.2,0.3,0.2,0.5C57.8,21.3,57.7,21.5,57.6,21.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kittable"
    }
</script>

<style scoped>

</style>