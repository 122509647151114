<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgPaiement" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M52.1,38.6c-0.5,0-1.1,0.1-1.6,0.4c-0.5-0.3-1-0.4-1.6-0.4c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5
 c0.5,0,1.1-0.1,1.6-0.4c0.5,0.3,1,0.4,1.6,0.4c1.9,0,3.5-1.6,3.5-3.5C55.5,40.1,54,38.6,52.1,38.6z M52.1,43.5
 c-0.4,0-0.7-0.2-0.9-0.4l-0.6-0.5l-0.6,0.5c-0.2,0.2-0.5,0.4-0.9,0.4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
 c0.4,0,0.7,0.2,0.9,0.4l0.6,0.5l0.6-0.5c0.2-0.2,0.5-0.4,0.9-0.4c0.8,0,1.5,0.7,1.5,1.5S52.9,43.5,52.1,43.5z"/>
  <path d="M56.3,27H26.4c-1.8,0-3.2,1.3-3.4,3l-9.3,1.7c-2.8,0.7-5.3,3-7.1,6.5C4,43.3,3.4,50,5.2,54.2c1.4,3.2,4.3,5,8.4,5.1
 c0.1,0,0.2,0,0.3,0c3.7,0,7.7-1.3,10.3-2.4c0.4-0.1,7.2-2.4,12-7.5h20.2c1.9,0,3.5-1.6,3.5-3.5V30.5C59.8,28.5,58.2,27,56.3,27z M24.9,30.5c0-0.8,0.7-1.5,1.5-1.5h29.9c0.8,0,1.5,0.7,1.5,1.5v2.7H24.9v-2.4V30.5z M23.4,54.9c-3.6,1.5-7.1,2.4-9.8,2.3
 c-3.3-0.1-5.5-1.3-6.5-3.9c-1.5-3.6-1-9.8,1.3-14.3c1.5-3,3.5-4.9,5.7-5.5l8.8-1.6v13.8c0,0.3,0.1,0.6,0.4,0.8s0.6,0.3,0.8,0.2
 c9-2,10.4-4.8,10.6-5.6c0.4-0.9,1.3-1.3,2.2-1c0.9,0.3,1.4,1.4,1,2.3C34.9,51.1,23.6,54.9,23.4,54.9z M56.3,47.3H37.7
 c0.9-1.2,1.6-2.6,2.2-4.1c0.7-1.9-0.3-4.1-2.2-4.8c-0.9-0.3-2-0.3-2.9,0.1c-0.9,0.4-1.6,1.2-2,2.1c0,0.1,0,0.1,0,0.2
 c0,0-0.9,2-7.9,3.8v-9.3h32.9v10.6C57.8,46.6,57.1,47.3,56.3,47.3z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "paiement"
    }
</script>

<style scoped>

</style>