<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitLitXL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M13.7,50.5c-0.8,0-1.5-0.7-1.5-1.5V33h34.5v0.7c0.7,0.2,1.4,0.4,2,0.7v-1.7C50,32.3,51,31,51,29.5v-7.3
 c0-1.9-1.6-3.5-3.5-3.5h-36c-1.9,0-3.5,1.6-3.5,3.5v7.3c0,1.5,0.9,2.8,2.3,3.3V49c0,1.9,1.6,3.5,3.5,3.5h14.2
 c-0.2-0.7-0.3-1.3-0.4-2H13.7z M10,22.2c0-0.8,0.7-1.5,1.5-1.5h36c0.8,0,1.5,0.7,1.5,1.5v7.3c0,0.8-0.7,1.5-1.5,1.5h-36
 c-0.8,0-1.5-0.7-1.5-1.5V22.2z"/>
  <path d="M12.2,9.8c0-0.8,0.7-1.5,1.5-1.5h1.4v4.6c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h1.1v4.6
 c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h0.9c0.8,0,1.5,0.7,1.5,1.5v7.5h2V9.8c0-1.9-1.6-3.5-3.5-3.5h-1
 c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.2c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.5
 c-1.9,0-3.5,1.6-3.5,3.5v7.5h2V9.8z M32.3,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V6.8z M17.2,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V6.8z"/>
  <path d="M42.6,35.1c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4c7.4,0,13.4-6,13.4-13.4C56,41.1,50,35.1,42.6,35.1z M42.6,59.8
 c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4c6.3,0,11.4,5.1,11.4,11.4S48.9,59.8,42.6,59.8z"/>
  <path d="M42,51.4l-2.1-3.3l2-3c0.4-0.5,0.5-0.9,0.5-1.2c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.4-0.2-0.6-0.2c-0.1,0-0.3,0-0.4,0.1
 c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.5l-1.6,2.4l-1.5-2.4c-0.3-0.5-0.5-0.8-0.7-0.9
 s-0.4-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.6,0.2S35,43.7,35,43.9c0,0.2,0,0.4,0.1,0.6s0.2,0.4,0.4,0.7l1.9,2.9l-2.2,3.3
 c-0.3,0.5-0.5,0.9-0.5,1.2c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2
 c0.1-0.1,0.2-0.2,0.2-0.3s0.2-0.3,0.3-0.5l1.9-2.9l1.8,2.8c0.2,0.3,0.4,0.6,0.5,0.7s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1
 c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5C42.4,52,42.2,51.7,42,51.4z"/>
  <path d="M50.8,52c-0.2-0.2-0.4-0.2-0.8-0.2h-4.1v-7.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3
 s-0.3,0.5-0.3,0.9v7.7c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3h4.9c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.3-0.6
 C51.1,52.3,51,52.1,50.8,52z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kitlitxl"
    }
</script>

<style scoped>

</style>