import moment from "moment";

/**
 * @param {moment.Moment} focusedDateA
 * @param {moment.Moment} focusedDateB
 */
export const compareDates = (focusedDateA, focusedDateB) =>
    (focusedDateB.isValid() - focusedDateA.isValid())
    || (focusedDateA.isBefore(focusedDateB, 'day') ? -1 : 0)
    || (focusedDateB.isBefore(focusedDateA, 'day') ? 1 : 0)
;

export const itemDateToMoment = date => moment(date, 'DD/MM/YYYY');