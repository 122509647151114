<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgLinteauVerreTorchon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M17.7,10.6c-0.6,0-1,0.4-1,1v21.7c0,0.6,0.4,1,1,1s1-0.4,1-1V11.6C18.7,11.1,18.3,10.6,17.7,10.6z"/>
<path d="M35.9,12.9c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S36.6,12.9,35.9,12.9z M35.9,11.5
  c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0.4,0.2,0.4,0C36.1,11.6,36,11.5,35.9,11.5z"/>
<g>
  <path d="M55.1,10.5c0-0.2,0-0.4,0-0.6c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5c0,1.4,0.7,2.7,1.7,3.5l-2,8.7c-1.3-0.9-2.8-1.2-4.3-1
 c-2.4,0.3-3.8,1.8-4.6,3.7H36h-7l-0.2,0.6c-0.1,0.5-3.3,11.9,1,17.6c1.3,1.7,3.2,2.7,5.5,3v10.6h-5.7c-0.5,0-0.8,0.4-0.8,0.8
 s0.4,0.8,0.8,0.8h7.6c0,1-0.1,1.6-0.1,1.7c0,0.2,0.1,0.4,0.2,0.6c0.8,0.8,1.8,1.1,2.7,1.1c1.3,0,2.5-0.5,3.2-1
 c0.3-0.2,0.4-0.6,0.3-1c-0.1-0.1-0.1-0.3-0.1-0.4c0.3,0,0.6,0,0.9,0c2,0,3.6-0.3,3.7-0.3c0.3,0,0.5-0.2,0.6-0.5
 c0.1-0.2,0.1-0.5,0-0.8c-1.5-2.9-1.2-5-0.1-8.5c0.8,0.2,1.8,0.4,2.9,0.4c0.1,0,0.3,0,0.4,0c3.2-0.2,4-2.2,4-2.3
 c0-0.1,0.1-0.2,0-0.3l-0.4-5.7c0.7-0.5,2.7-1.4,5.2-0.1c0.4,0.2,0.8,0.2,1.1-0.1C62.2,40.9,63.5,39.8,55.1,10.5z M47.7,9.9
 c0-1.6,1.3-2.9,2.9-2.9c0.7,0,1.3,0.3,1.8,0.7c-0.1,0-0.3-0.1-0.4-0.1C50.5,7.4,49,8.5,48.6,10l-0.3,1.5
 C47.9,11,47.7,10.5,47.7,9.9z M37.8,50l0-4c0.1,0,0.3,0,0.4-0.1C38.1,47,37.9,49,37.8,50z M36.7,44.4c-2.4,0-4.2-0.8-5.4-2.4
 C28,37.8,29.8,29,30.4,26.6H36h0.2c0,0,0,0,0,0H38c0,0,0,0,0,0h0.6c0,0,0,0,0,0h2v0c0,0,0,0,0,0h0c0.6,1.2-0.5,8-1.4,13
 c-0.3,1.6-0.5,3.2-0.8,4.7C37.9,44.4,37.3,44.4,36.7,44.4z M38.9,59.6c0-0.8,0.1-2.6,0.3-4.8c0,0,0,0,0,0c0.3,0.1,0.7,0.2,1.1,0.2
 c0.5,0,1-0.1,1.6-0.3c-0.3,1.8-0.4,3.5,0,4.9C41.1,59.9,39.8,60.3,38.9,59.6z M43.2,57.5c0-1.4,0.3-3.1,0.6-4.8c0.7,0,1.5,0,2.1,0
 c-0.2,1.6,0,3,0.7,4.6C45.8,57.5,44.5,57.6,43.2,57.5z M46.2,51.1c-0.6-0.1-1.4-0.1-2-0.1c0.2-0.6,0.3-1.3,0.5-1.9
 c0.1-0.5,0.3-1,0.4-1.5c1.1-4.3,1.1-10.2,1-11.4c0-0.5-0.5-0.8-0.9-0.8c-0.5,0-0.8,0.4-0.8,0.9c0.1,0.9,0.1,6.7-1,10.8
 c-0.1,0.5-0.3,1-0.4,1.5c-0.3,1.3-0.7,2.6-1,3.9c0,0-0.1,0-0.1,0c-0.5,0.3-1,0.5-1.4,0.5l-1.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
 c0.3-3.3,0.6-7.2,1.2-10.4c0,0,0-0.1,0-0.1c0.1-0.8,0.3-1.7,0.4-2.5c1.7-10.2,2.2-14.2,0.3-14.9c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
 c-0.2-0.1-0.4-0.1-0.7,0h-1.8c0.6-1.1,1.6-1.9,3-2.1c1.8-0.3,3.4,0.5,4.8,2.2c3.2,3.9,4.5,12.3,2.6,17.6c-0.4,1.1-0.8,2.1-1.1,3
 C47.2,47.8,46.6,49.5,46.2,51.1z M51.8,48.1c-1,0.1-2-0.1-2.7-0.3c0.2-0.5,0.4-1,0.5-1.5c0.1-0.3,0.2-0.6,0.3-0.9
 c0.4,0.1,0.8,0.1,1.3,0.1c0.9,0,1.9-0.2,3-0.6l0.2,2.1C54.1,47.3,53.5,48,51.8,48.1z M55.4,39.7l-0.2-2.6c0.4-0.2,0.9-0.4,1.6-0.5
 l0.5,2.5C56.5,39.2,55.9,39.4,55.4,39.7z M52.9,11.1c0-0.2-0.3-0.4-0.5-0.3c-0.2,0-0.4,0.2-0.3,0.5l4.6,24.5
 c-0.6,0.1-1.1,0.3-1.5,0.4l-0.5-7.4c0-0.4-0.4-0.8-0.9-0.7c-0.4,0-0.8,0.4-0.7,0.9L54.1,44c-1.5,0.7-2.8,0.8-3.9,0.6
 c0.2-0.5,0.3-0.9,0.5-1.5c1.5-4.3,1.1-10.4-0.6-15l1.3-10.6c0.1-0.4-0.3-0.8-0.7-0.9c-0.4-0.1-0.8,0.3-0.9,0.7l-1,8.1
 c-0.3-0.5-0.7-1-1-1.5c-0.2-0.2-0.4-0.5-0.6-0.7l3-12.9c0.2-0.8,1-1.4,1.8-1.3c0.7,0.1,1.3,0.5,1.5,1.2l0,0.1
 c2.7,9.6,5.5,20.1,6.6,25.7c-0.9-0.4-1.8-0.5-2.6-0.4L52.9,11.1z M58.1,39l-0.5-2.6c0.8,0,1.8,0.1,2.7,0.6c0.2,1,0.3,1.9,0.3,2.4
 C59.7,39.2,58.8,39,58.1,39z"/>
  <path d="M37.8,28.7c-0.1,0.4-0.2,0.9-0.3,1.3c0-0.4,0.1-0.9,0.1-1.3h-1.7c-0.4,3.3,0.1,6.8,0.3,8.6c0.1,0.4,0.1,0.8,0.1,1
 c0,0,0,0,0,0.1c0,0.4,0.3,0.8,0.7,0.9c0.4,0.1,0.8-0.2,0.9-0.6c0-0.1,0-0.2,0-0.3c0.2-3.1,0.7-7.1,1.5-9.6H37.8z"/>
  <path d="M6.9,23.5v-1.8V11.2c0,0,0-0.1,0-0.1c0-0.3,0-0.7-0.1-1c0-0.3-0.1-0.6-0.2-0.9c0,0,0,0,0,0c0-0.2-0.1-0.4-0.1-0.5h21.3
 c0.1,0,0.3,0.2,0.4,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0.1c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4
 c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0l0,10.5l0,0.7v0.2c2.2-0.6,3.9-1,5.3-1.4V9.8h2.6c0.6,0,1.1,0.5,1.1,1.1v9
 c0.7-0.4,1.4-0.8,2.2-1.4v-6.6h2.7c0.2-0.7,0.5-1.4,0.7-2h-3.9c-0.4-1.2-1.6-2.1-2.9-2.1h-4.6v1.4h-1.6C30,7.7,29,6.7,27.9,6.7H2.1
 c-0.2,0-0.4,0-0.6,0.1C0.9,6.9,0.4,7.4,0,8v3.1h4.9c0,0,0,0,0,0v10.5H0v1.8h4.9v1.1H0v2.8C0.4,27.7,1,28,1.7,28H4
 c0.3,0,0.6-0.1,0.9-0.2v8.9c0,1.1,0.9,2,2,2h18.6c0-0.1,0-0.3,0.1-0.4c0.1-0.6,0.2-1.1,0.2-1.6l-18.9,0V23.5z M32.1,11.2L32.1,11.2
 v10.1v0.4v0h-1.3V11.2c0,0,0,0,0,0H32.1z M2.6,6.7C2.6,6.7,2.6,6.7,2.6,6.7C2.6,6.7,2.7,6.8,2.6,6.7c0.1,0,0.1,0,0.1,0
 C2.7,6.8,2.6,6.8,2.6,6.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "linteauverretorchon"
    }
</script>

<style scoped>

</style>