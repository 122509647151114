import Vue from 'vue';
import $ from "jquery";

export default Vue.mixin({
    data () {
        return {
            translates:  []
        }
    },
    created: function () {
        this.loadTranslation()
    },
    methods: {
        loadTranslation: function () {
            let elt = document.querySelectorAll('[data-trans]');

            let translations = {};
            if (elt.length) {
                elt.forEach(function(element) {
                    translations = {...translations, ...JSON.parse(element.dataset.trans)};
                });
            }
            this.translates = translations;
        },
        getTranslation: function (key, params = {}, resolveScopeOnSubObject = false) {
            resolveScopeOnSubObject ? this.loadTranslation() : null;

            let valueToTranslate = this.translates[key];

            for (let param in params) {
                valueToTranslate = valueToTranslate.replace(
                    param,
                    params[param]
                );
            }

            return valueToTranslate;
        }
    }
});
