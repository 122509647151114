<template>
    <svg version="1.1" class="svg svgInfo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 65 65" style="enable-background:new 0 0 65 65;" xml:space="preserve">
        <g>
            <g>
                <path d="M32.5,0C14.6,0,0,14.6,0,32.5S14.6,65,32.5,65S65,50.4,65,32.5S50.4,0,32.5,0z M32.5,61C16.8,61,4,48.2,4,32.5
                    S16.8,4,32.5,4S61,16.8,61,32.5S48.2,61,32.5,61z"></path>
                <circle cx="32.3" cy="21.1" r="2.7"></circle>
                <path d="M32.1,27.4c-1.1,0-2,0.9-2,2v17c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-17C34.1,28.3,33.2,27.4,32.1,27.4z"></path>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "InfoIcon"
    }
</script>

<style scoped>
    svg {
        vertical-align: middle;
        margin-left: 10px;
    }
</style>