<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgNappe" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M11.6,28c-0.2,0.5,0,1.1,0.5,1.3l11.3,5.4c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6c0.2-0.5,0-1.1-0.5-1.3L13,27.5
 C12.5,27.2,11.9,27.5,11.6,28z"/>
  <path d="M48.9,26.6c0.7,2.5,2.8,9.9,3.1,11.3c-0.7-0.2-1.4-0.2-2-0.2l0,0c0,0,0,0-0.1,0s-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0
 l-16.2,8.3c-0.5-3.1-1.4-9.2-1.4-9.2l-2,0.3c0,0,1.4,9.2,1.7,10.7c0,0.2-0.6,0.8-1,1.2c-0.5,0.5-1.1,1-1.6,1.7
 c-0.1,0-0.2,0.1-0.2,0.1c-0.5-0.7-1.1-1.3-1.6-1.8c-0.4-0.4-1-1-1-1.2c0.2-1.5,2.1-13.1,2.1-13.1l-2-0.3c0,0-1.3,8.2-1.8,11.7
 l-13.1-7.1c-0.1-0.1-0.3-0.2-0.4-0.2l-3.1-1.7c-0.1,0-0.2-0.1-0.3-0.1C7.5,37,7.3,37,7.2,37c0,0-0.4,0.1-1,0.3
 c0.5-2.3,1.5-5.7,3-10.8l19.7-7.3v-2.1L8,24.8c0,0,0,0-0.1,0s-0.1,0.1-0.2,0.1L7.6,25c0,0-0.1,0.1-0.1,0.2s-0.1,0.1-0.1,0.2
 c0,0,0,0,0,0.1c-3.5,11.6-3.9,15.1-4,15.3c-0.1,0.5,0.3,1,0.8,1.1c0.1,0,0.1,0,0.2,0c0.4,0,0.8-0.3,1-0.7c0.4-1.4,1.4-1.9,1.8-2
 l1.9,1c0,0.1,0,0.1,0,0.2v8.1c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-6.7l12.3,6.7c0.2,0.8,0.9,1.5,1.6,2.2
 c0.8,0.8,1.8,1.7,2.1,3.1c0,0.1,0.1,0.1,0.1,0.2v0.1v3.7c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-5.4c0-0.2,0-0.4-0.1-0.6
 c0.3-0.4,0.7-0.8,1.1-1.1c0.8-0.7,1.6-1.5,1.6-2.5l0,0c0.2,0,0.3,0,0.5-0.1l13.1-6.7v7.3c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V40
 c0-0.1,0-0.2,0-0.3l0.1-0.1c0.5,0,1.9,0,2.3,1.4c0.1,0.4,0.5,0.7,1,0.7c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.6,0.8-1.1
 c0-0.1-2.2-9.3-3.9-15.7L48.9,26.6L48.9,26.6z"/>
  <path d="M12.8,39.7"/>
  <polygon points="48.9,26.6 28.9,19.2 28.9,17.1 50.9,24.9   "/>
  <path d="M33,34.2c0.2,0.5,0.8,0.8,1.3,0.6l11.8-4.2c0.1,0,0.3-0.1,0.4-0.2c0.3-0.3,0.4-0.6,0.2-1.1c-0.2-0.5-0.8-0.8-1.3-0.6
 l-11.7,4.1C33.1,33,32.9,33.6,33,34.2z"/>
</g>
</svg>

</template>

<script>

    export default {
        name: "nappe"
    }
</script>

<style scoped>

</style>