import pushEcommerceTag from "./pushEcommerceTag";

const pushCartTag = function (dataLayer, cartProducts, taxRate) {
    const products = cartProducts.map(
        ({title: name, id, dataPrice: price, qty: quantity, categoryName = ''}) => ({name, id, price, quantity, categoryName, variant: ''})
    );
    const total_ht = products.reduce((total_ht, product) => total_ht + product.price, 0);
    const total_ttc = total_ht * (1 + taxRate);
    let tag = {
        checkout: {
            actionField: {
                step: 1,
                action: "checkout"
            },
            products,
            total_ht,
            total_ttc
        }
    };
    pushEcommerceTag(dataLayer, tag);
};

export default pushCartTag;