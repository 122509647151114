<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgLinteaux10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M20.7,43.1c0.6,0,1.1-0.5,1.1-1.1V17c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v25C19.6,42.6,20.1,43.1,20.7,43.1z"/>
  <path d="M8.2,45.9V16.5c0-1.1-0.2-2.1-0.5-3h24.6c0.3,0.1,1.1,1.1,1.1,3v7.6c0.7-0.9,1.4-1.8,2.2-2.5v-5.1c0,0,0,0,0-0.1h1.5v3.9
 c0.7-0.5,1.5-1,2.2-1.4v-4.1h2.6c0.9,0,1.7,0.8,1.7,1.7v0.8c1.2-0.3,2.4-0.4,3.6-0.4c1.1,0,2.1,0.1,3.1,0.3h5.8
 c0.3,0,0.5,0.2,0.5,0.5c0,0.2-0.1,0.3-0.2,0.4l0,0h-2.9c1.8,0.7,3.4,1.7,4.8,2.9c0-0.1,0-0.2,0-0.3c0-0.4-0.1-0.8-0.2-1.1
 c0.5-0.5,0.8-1.2,0.8-1.9c0-1.5-1.2-2.7-2.7-2.7H45.8c-0.6-1.4-2-2.4-3.6-2.4h-4.9v1.6h-1.9c-0.5-1.7-1.7-2.9-3-2.9H2.7
 c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.3-0.8,0.6-1.1,1.1C0.2,13,0.1,13.2,0,13.4v3l6,0v0.1v12.1H0v2.2h6v1.1H0
 v2.9c0.5,0.6,1.3,1,2.2,1H5c0.3,0,0.7-0.1,1-0.2v10.2c0,1.3,1,2.3,2.3,2.3h9.4c0.1-0.3,0.1-0.5,0.2-0.8c0.2-0.5,0.4-1,0.7-1.5
 L8.2,45.9C8.3,46,8.2,45.9,8.2,45.9z M3.3,11.4C3.3,11.4,3.3,11.4,3.3,11.4C3.4,11.4,3.4,11.4,3.3,11.4c0.1,0,0.1,0,0.2,0.1
 C3.4,11.4,3.3,11.4,3.3,11.4z"/>
  <path d="M40.3,17.1c0,0.5,0.3,0.9,0.6,1.2c0.6-0.3,1.3-0.5,2-0.7c0.1-0.2,0.1-0.3,0.1-0.5c0-0.8-0.6-1.4-1.4-1.4
 C40.9,15.7,40.3,16.4,40.3,17.1z M41.9,17.1c0,0.3-0.5,0.3-0.5,0c0-0.1,0.1-0.2,0.2-0.2C41.8,16.9,41.9,17,41.9,17.1z"/>
  <path d="M47.5,19.3c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9S55.7,19.3,47.5,19.3z M47.5,47.1
 c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9S54.6,47.1,47.5,47.1z"/>
  <path d="M40.1,36.1l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
 c-0.2,0-0.3,0-0.4,0.1s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2
 c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4
 c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5
 c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.7L40.1,36.1z"/>
  <path d="M47.3,28.8c-0.2,0-0.4,0-0.5,0.1s-0.3,0.3-0.5,0.7c-0.3,0.4-0.6,0.7-0.9,1c-0.3,0.3-0.8,0.6-1.4,0.8
 c-0.4,0.2-0.6,0.3-0.8,0.4C43.1,32,43,32.2,43,32.4c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.4,0,1.2-0.5,2.4-1.4v6.4
 c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1-0.5,1-1.4v-8.1c0-0.3-0.1-0.6-0.2-0.8C47.7,28.9,47.5,28.8,47.3,28.8z"/>
  <path d="M58.1,30.9c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5s-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C58.6,32.7,58.4,31.6,58.1,30.9z M56.5,36.2c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3c-0.1-0.6-0.2-1.3-0.2-2.2c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4
 c0.2,0.3,0.4,0.7,0.5,1.2s0.2,1.3,0.2,2.1C56.7,34.9,56.6,35.7,56.5,36.2z"/>
  <path d="M30.9,46.8c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1l3.1-1.2c-0.5-0.5-1-1-1.4-1.6l-2.4,0.9
 C31,45.7,30.7,46.3,30.9,46.8z"/>
  <path d="M47.6,51.4c0,0-0.1,0-0.1,0c-0.7,0-1.3,0-2-0.1c-0.1,0.2-0.2,0.2-0.3,0.3l-1,0.4c0,0,0,0,0,0L37,54.5
 c0.5-3.5-2.5-5.4-4.2-5.8l-6.7-2.1l7.3-2.6c-0.4-0.6-0.7-1.1-1.1-1.7l-10.2,3.7c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1
 c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7
 c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4
 c0.2-0.8,0.1-1.5-0.3-2.1C46.9,52.8,47.4,52.3,47.6,51.4z M35,54.4c-0.1,0.5-0.3,0.9-0.6,1.1c-0.3,0.2-0.9,0.2-1.7-0.1l-8.9-2.7
 c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5c-0.1,0.1-0.7,0.1-1.1-0.1L23.9,56
 c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0
 c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8C32.3,50.6,35.5,51.5,35,54.4z M44.7,54.9l-8.8,3.2
 c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C44.9,54.2,44.8,54.4,44.7,54.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "linteaux10"
    }
</script>

<style scoped>

</style>