import csrfToken from "./csrfToken";

const jsonFetch = function (url, data) {
    const body = data && JSON.stringify(Object.assign({_token: csrfToken()}, data));
    return fetch(url, {
        method: body ? 'POST' : 'GET',
        body,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            return response.json().then(json => {
                const error = new Error(response.statusText);
                error.json = json;
                throw error;
            })
        }
    });
};

export default jsonFetch;