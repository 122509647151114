<!-- components/ProductVariants.vue -->
<!-- Ce component correspond à la partie dynamique du bloc produit sur le listing -->
<!-- Le prix du produit est mis à jour au changement de quantité, de variante, et quand l'utilisateur change ses dates de location -->

<template>
    <div>
        <div class="blockPrice">
            <div class="row">
                <div class="col-xs-12">
                    <RentalPrice :currency-symbol="currencySymbol"
                                 :base-price="price.basePrice"
                                 :daily-price="price.dailyPrice"
                                 :duration="duration"
                                 :quantity="quantity"
                                 :price-locked="supplier && supplierProductExists"
                    />
                </div>
            </div>
        </div>
        <div class="blockSelect">
            <div class="row">
                <div class="col-xs-12">
                    <LabelledSelect :options="variants" v-model="selectedVariant" @input="updatePrice"
                                    :class="{'hidden': hasVariantsSelectHidden}">
                        {{ getTranslation('product_variant.variant')}}
                    </LabelledSelect>
                    <span aria-hidden="true" v-on:click="openInfoPopup"
                          :class="hasVariantsSelectHidden ? 'wrapInfo noTop' : 'wrapInfo'">
                    <InfoIcon/>
                </span>
                    <Modal ref="popupInfo">
                        <template v-slot:header>
                            <div class="h2 titleLine">{{ selectedVariant.name }}</div>
                        </template>
                        <slot>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card">
                                        <div class="card-img"
                                             :style="`background-image:url('${selectedVariant.image}');`">
                                            <div class="wrapPicto">
                                                <span class="spanContainer">
                                                    <component :is="selectedVariant.iconComponent"/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text">
                                        <ul>
                                            <li :key="characteristic.type"
                                                v-for="characteristic in selectedVariant.characteristics">
                                                <strong>{{ characteristic.type }}</strong>
                                                {{ characteristic.value }}
                                            </li>
                                        </ul>
                                        <ul v-if="selectedVariant.bundle">
                                            <li :key="bundled.name" v-for="bundled in selectedVariant.bundle">
                                                <strong>{{ bundled.quantity }} x</strong>
                                                {{ bundled.name }}
                                            </li>
                                        </ul>
                                        <span itemprop="description" v-html="selectedVariant.excerpt"></span>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </Modal>
                </div>
            </div>
        </div>
        <div v-if="supplier">
            <div class="blockInfosQty" v-if="supplierProductExists">
                <div class="row">
                    <div class="col-md-6">
                        <QuantityInput v-model="quantity"/>
                    </div>
                    <div class="col-md-6">
                        <button @click="addToCart()" :disabled="quantity < 1 || !supplierProductExists"
                                data-product-add-to-cart
                                class="btn btn-primary btnLight">
                            {{ getTranslation('product_variant.add_to_cart')}}
                        </button>
                    </div>
                </div>
            </div>
            <ErrorMessage v-if="supplierProductExists === false">
                {{ getTranslation('product_variant.not_allow_in_your_sector') }}
            </ErrorMessage>
        </div>
        <div class="blockButtons" v-else>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-secondary btnAddress" @click="focusDeliveryAddress()">
                        {{ getTranslation('product_variant.order_address') }}
                    </button>
                </div>
            </div>
        </div>
        <SuccessMessage v-if="successMessage">
            {{ getTranslation('product_variant.product_well_added_to_cart')}}
        </SuccessMessage>
    </div>
</template>

<script>
    import RentalPrice from "./RentalPrice";
    import RentalStore from "../store/RentalStore";
    import Modal from "./Modal";
    import CartStore from "../store/CartStore";
    import {supplierPrice, totalPrice} from "../price";
    import InfoIcon from "./icons/InfoIcon";
    import ErrorMessage from "./ErrorMessage";
    import QuantityInput from "./QuantityInput";
    import {focusSearch} from "../front";
    import LabelledSelect from "./LabelledSelect";
    import SuccessMessage from "./SuccessMessage";
    import computeDuration from "../ordering/computeDuration";

    let successMessageTimeout;

    export default {
        name: "ProductVariants",
        components: {
            SuccessMessage,
            ErrorMessage,
            InfoIcon,
            Modal,
            RentalPrice,
            QuantityInput,
            LabelledSelect
        },
        props: {
            variants: Array,
            category: Object,
            currencySymbol: String
        },
        data() {
            const selectedVariant = this.variants[0];
            return {
                selectedVariant,
                duration: null,
                supplier: null,
                quantity: 1,
                price: {basePrice: selectedVariant.basePrice, dailyPrice: selectedVariant.dailyPrice},
                supplierProductExists: false,
                successMessage: false
            }
        },
        created() {
            RentalStore.subscribe(this.update);
            this.update(RentalStore.get() || {});
        },
        beforeDestroy() {
            RentalStore.unsubscribe(this.update);
        },
        computed: {
            hasVariantsSelectHidden() {
                return (this.variants.length <= 1);
            }
        },
        methods: {
            update({supplier, from, to}) {
                this.supplier = supplier;

                this.updatePrice(this.selectedVariant);

                this.duration = computeDuration(from, to);
            },
            openInfoPopup() {
                this.$refs.popupInfo.show();
            },
            updatePrice(selectedVariant) {
                this.selectedVariant = selectedVariant;
                const {id, basePrice, dailyPrice} = selectedVariant;
                this.price = {basePrice, dailyPrice};
                this.supplierProductExists = null;

                if (!this.supplier) {
                    return;
                }

                supplierPrice({supplierId: this.supplier, productId: id}).then(json => {
                    this.price = json;
                    this.supplierProductExists = true;
                }).catch(() => {
                    this.supplierProductExists = false;
                })
            },
            addToCart() {
                CartStore.addProduct(this.selectedVariant.bundle, {
                    id: this.selectedVariant.id,
                    qty: this.quantity,
                    title: this.selectedVariant.name,
                    totalPrice: totalPrice(this.price, this.quantity, this.duration),
                    categoryName: this.category.name
                });
                this.successMessage = true;
                clearTimeout(successMessageTimeout);
                successMessageTimeout = setTimeout(() => {
                    this.successMessage = false
                }, 2000);
            },
            focusDeliveryAddress() {
                focusSearch();
            }
        }
    }
</script>

