<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgPeignoire" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M39.8,14.9l-5.6-2.8c-0.1-0.3-0.1-0.6-0.1-0.8c0.1,0,0.1-0.1,0.2-0.1c0.8-0.3,2.6-1,2.6-3.3c0-2.5-1.5-4.1-3.8-4.1
  c-2.2,0-3.6,1.4-3.6,2.8c0,0.5,0.4,0.9,1,1c0.5,0,1-0.4,1-1c0-0.2,0.4-0.8,1.6-0.8c0.8,0,1.8,0.2,1.8,2.1c0,0.8-0.4,1.1-1.3,1.5
  c-0.3,0.1-0.5,0.2-0.7,0.4c-0.8,0.5-0.8,1.6-0.7,2.6L26.6,15c-8.8,1.9-12.8,6-12.8,13.3v16.1c0,1.4,1.1,2.5,2.5,2.5h2.7
  c1.4,0,2.5-1.1,2.5-2.5V28.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4l2.1,9.5c-0.8,0.4-1.3,1.1-1.3,2v0.8c0,1,0.7,1.9,1.6,2.1
  l-2.4,13.8l0,0.2c0,1.4,1.1,2.5,2.5,2.5h16.5c1.4,0,2.5-1.1,2.5-2.5l-2.2-14c0.8-0.3,1.4-1.1,1.4-2.1v-0.8c0-1-0.7-1.9-1.6-2.1
  l2.6-9.1l0-0.3c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v16.1c0,1.4,1.1,2.5,2.5,2.5h2.7c1.4,0,2.5-1.1,2.5-2.5V28.3
  C52.9,20.8,49,16.7,39.8,14.9z M36.5,15.9c0.3,0.1,0.4,0.3,0.4,0.6l-3.2,12.2c-0.1,0.2-0.3,0.4-0.5,0.4c0,0-0.1,0-0.1,0
  c-0.3-0.1-0.4-0.3-0.4-0.6l3.2-12.2C35.9,16,36.2,15.8,36.5,15.9z M33.1,39.6l0.9,0c0.2,0,0.3,0,0.3,0l1.6,6.8l-0.7,0.2c0,0,0,0,0,0
  L33.1,39.6z M33.2,50.5c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1l-0.8,0h0c-0.1,0-0.2-0.1-0.2-0.2l0-7.6h0l1.3,4.2c0,0,0,0,0,0
  L33.2,50.5z M33.3,13.9l2,1h-4L33.3,13.9z M24.5,28.2c-0.1-1.3-1.1-2.3-2.4-2.3c-1.3,0-2.4,1.1-2.4,2.4l0,16.1
  c0,0.3-0.2,0.5-0.5,0.5h-2.7c-0.3,0-0.5-0.2-0.5-0.5V28.3c0-5.8,2.9-9.2,9.5-10.9c0,0.1,0,0.1,0,0.2l5,18.2c0.1,0.4,0.5,0.7,1,0.7
  c0.1,0,0.2,0,0.3,0c0.5-0.1,0.8-0.7,0.7-1.2l-5-18.2c0,0,0-0.1,0-0.1c0.4-0.1,0.7-0.1,1.1-0.2l5.4,20.9h-7.1L24.5,28.2z M29.9,40.9
  h-4.3c-0.1,0-0.2-0.1-0.2-0.2v-0.8c0-0.1,0.1-0.2,0.2-0.2h4.3c0,0.1,0,0.2,0,0.3L29.9,40.9z M42.1,56.8c0,0.3-0.3,0.5-0.5,0.5H25
  c-0.3,0-0.5-0.2-0.5-0.5L27,42.9h2.9l0,7.6c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.6,1.6,0.6h0l0.8,0c0.6,0,1.2-0.2,1.6-0.7
  c0.4-0.4,0.7-1,0.6-1.6l0-1.9c0.1,0,0.3,0,0.4,0l0.7-0.2c1.1-0.3,1.8-1.4,1.5-2.4l-0.7-3h2.7L42.1,56.8z M36.4,39.6H41
  c0.1,0,0.2,0.1,0.2,0.2v0.8c0,0.1-0.1,0.2-0.2,0.2h-4.3L36.4,39.6z M50.9,44.4c0,0.3-0.2,0.5-0.5,0.5h-2.7c-0.3,0-0.5-0.2-0.5-0.5
  V28.3h0c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.3,1-2.4,2.3l-2.7,9.4h-3.8L34.4,32c0-0.1,0.1-0.2,0.1-0.2l3.9-15.1
  c10.3,1.8,12.5,5.9,12.5,11.6V44.4z"/>
</svg>

</template>

<script>
    export default {
        name: "peignoire"
    }
</script>

<style scoped>

</style>