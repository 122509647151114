<template>
    <div v-if="options">
        <input :type="type"
               :id="id"
               :value="value"
               @focus="$emit('focus', $event)"
               @blur="$emit('blur', $event)"
               @input="$emit('input', $event.target.value)"
               :list="datalistId"
               ref="input"
               :class="className"/>
        <datalist v-if="options" :id="datalistId">
            <option v-for="option in options" :key="option" :value="option"/>
        </datalist>
    </div>
    <input v-else
           :type="type"
           :id="id"
           :value="value"
           @focus="$emit('focus', $event)"
           @blur="$emit('blur', $event)"
           @input="$emit('input', $event.target.value)"
           :list="datalistId"
           ref="input"
           :class="className"/>
</template>

<script>
    import uuid from "uuid/v4";

    export default {
        name: 'FormInput',
        props: {
            className: {},
            id: String,
            options: Array,
            type: {type: String, default: 'text'},
            value: String,
        },
        computed: {
            datalistId() {
                return uuid();
            }
        },
    };
</script>
