<template>

    <!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" class="svg svgDrapsS" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M15.6,49.9c-0.8,0-1.5-0.7-1.5-1.5v-17H34v2.7c0.6-0.3,1.3-0.6,2-0.8V31c1.1-0.6,1.9-1.8,1.9-3.1v-7.3
 c0-1.9-1.6-3.5-3.5-3.5h-21c-1.9,0-3.5,1.6-3.5,3.5v7.3c0,1.4,0.9,2.7,2.1,3.2v17.3c0,1.9,1.6,3.5,3.5,3.5H26
 c-0.2-0.7-0.3-1.3-0.4-2H15.6z M11.9,20.5c0-0.8,0.7-1.5,1.5-1.5h21c0.8,0,1.5,0.7,1.5,1.5v7.3c0,0.8-0.7,1.5-1.5,1.5h-21
 c-0.8,0-1.5-0.7-1.5-1.5V20.5z"/>
    <path d="M40.7,34.4c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C54.1,40.4,48.1,34.4,40.7,34.4z M40.7,59.2
 c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4s11.4,5.1,11.4,11.4S47,59.2,40.7,59.2z"/>
    <path d="M44,47.6c-0.3-0.2-0.7-0.4-1.2-0.6s-1-0.3-1.5-0.4c-0.5-0.1-0.8-0.2-1-0.3c-0.2-0.1-0.4-0.1-0.6-0.2s-0.3-0.2-0.5-0.4
 s-0.2-0.3-0.2-0.5c0-0.3,0.2-0.6,0.5-0.8s0.7-0.3,1.3-0.3c0.6,0,1,0.1,1.2,0.3c0.3,0.2,0.5,0.5,0.7,0.9c0.1,0.3,0.3,0.5,0.4,0.6
 c0.1,0.1,0.3,0.2,0.5,0.2c0.3,0,0.5-0.1,0.6-0.3s0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.2-0.8s-0.4-0.5-0.6-0.8c-0.3-0.3-0.7-0.5-1.1-0.6
 c-0.4-0.2-1-0.2-1.6-0.2c-0.8,0-1.4,0.1-2,0.3c-0.6,0.2-1,0.6-1.3,1s-0.5,0.9-0.5,1.5c0,0.6,0.1,1.1,0.4,1.5
 c0.3,0.4,0.7,0.7,1.2,0.9s1.1,0.4,1.8,0.6c0.5,0.1,1,0.2,1.3,0.3s0.6,0.3,0.8,0.5c0.2,0.2,0.3,0.5,0.3,0.8c0,0.4-0.2,0.8-0.6,1
 s-0.9,0.4-1.5,0.4c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.5-0.3-0.6-0.5c-0.1-0.2-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.5-0.4-0.6
 c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.7,0.3S37,49.7,37,49.9c0,0.4,0.1,0.9,0.4,1.3c0.3,0.4,0.7,0.8,1.1,1.1
 c0.6,0.4,1.5,0.6,2.4,0.6c0.8,0,1.5-0.1,2.1-0.4s1.1-0.7,1.4-1.1c0.3-0.5,0.5-1,0.5-1.6c0-0.5-0.1-0.9-0.3-1.3
 C44.5,48.1,44.3,47.8,44,47.6z"/>
    <path d="M34.9,9.1c0-1.4-1.1-2.5-2.5-2.5H31V6.1c0-1.4-1.1-2.5-2.5-2.5h-9c-1.4,0-2.5,1.1-2.5,2.5v0.6h-1.4c-1.4,0-2.5,1.1-2.5,2.5
 v6.5h21.8V9.1z M29,12.2c0,0.3-0.2,0.5-0.5,0.5h-9c-0.3,0-0.5-0.2-0.5-0.5V6.1c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5V12.2
 z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "drap-s"
    }
</script>

<style scoped>

</style>