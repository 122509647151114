<template>
    <div class="form-group wrapFloatLabel wrapSelect">
        <select ref="selectpicker" data-size="5" :value="value && value.id" @change="update">
            <option :key="option.id" :value="option.id" v-for="option in options">
                {{ option.name }}
            </option>
        </select>
        <label class="floatLabel">
            <span class="floatLabelContent">
                <slot></slot>
            </span>
        </label>
    </div>
</template>

<script>
    export default {
        name: "LabelledSelect",
        props: {
            options: {type: Array, required: true},
            value: Object
        },
        mounted() {
            const selectpicker = this.$refs.selectpicker;
            selectpicker.classList.add('selectpicker');
            window.$(selectpicker).selectpicker({
              dropupAuto: false
            });
        },
        updated() {
            window.$(this.$refs.selectpicker).selectpicker('refresh');
        },
        methods: {
            update($event) {
                const selectedId = $event.target.value;
                const value = selectedId ? this.options.find(option => option.id == selectedId) : null;
                this.$emit('input', value);
            }
        }
    }
</script>

<style scoped>

</style>