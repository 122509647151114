<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgProduit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M55.6,15.5c0.1-0.5-0.3-1.1-0.8-1.1c-0.5-0.1-1.1,0.3-1.1,0.8c-0.5,3.4-3,5.9-5.7,7.7l0.2-2.7c0-0.3-0.1-0.6-0.3-0.8
 s-0.5-0.3-0.8-0.3l-16.3,1.7c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.5,0.4
 L9.4,41.6c-0.7,0.7-1,1.5-1,2.5c0,0.9,0.4,1.8,1,2.5l11.6,11.6c0.7,0.7,1.6,1,2.5,1c0.9,0,1.8-0.3,2.5-1L46.3,38
 c0.2-0.2,0.4-0.4,0.5-0.7c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0l0.8-11.6
 C51.2,23.4,54.8,20.2,55.6,15.5z M45,36.4c0,0-0.1,0.1-0.1,0.1L24.6,56.8c-0.6,0.6-1.5,0.6-2.1,0L10.9,45.2
 c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.4,0.2-0.8,0.4-1.1l20.2-20.2c0,0,0.1,0,0.1-0.1L46,21.2l-0.2,2.8c-1.4,0.7-2.7,1.2-3.6,1.6
 c-0.1-0.1-0.2-0.2-0.3-0.3c-1-1-2.5-1-3.5,0c-1,1-1,2.5,0,3.5c0.5,0.5,1.1,0.7,1.7,0.7s1.3-0.2,1.7-0.7c0.3-0.3,0.5-0.7,0.6-1.1
 c0.8-0.3,1.9-0.7,3.1-1.2L45,36.4z M40.2,27.2c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1
 c-0.1-0.1-0.1-0.3-0.1-0.3s0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0C40.2,26.8,40.1,27,40.2,27.2z"/>
  <path d="M45.6,10.5c0.5-0.3,0.7-0.9,0.4-1.3c-0.3-0.5-0.9-0.7-1.3-0.4c-4.3,2.2-5.8,8.5-5.8,8.8c0,0.1,0,0.1,0,0.2l2.1-0.2
 C41.3,16.1,42.7,12,45.6,10.5z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "produit"
    }
</script>

<style scoped>

</style>