<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgServietteX10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M12.2,45.6c0.4-0.7,0.9-1.4,1.4-2l2-2.7c0.3-0.4,0.2-1.1-0.2-1.4c-0.4-0.3-1.1-0.2-1.4,0.2l-3.3,4.4v-9.2h3.4l4.2,5.7
 c0.6-0.2,1.3-0.5,2-0.6l-3.7-5h6.6v4.4c0.6-0.1,1.3-0.2,2-0.3V35c0.3-0.1,0.6-0.2,0.8-0.4c0-0.4-0.1-0.7-0.1-1.1
 c0-1.9,0.3-3.7,0.9-5.3c-0.3-0.6-0.9-1.1-1.6-1.2V16.7c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1
 L17.8,5.4c-0.4-0.5-1.3-0.5-1.6,0L8.9,16.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1V27
 c-1.1,0.3-1.9,1.2-1.9,2.4v3.2c0,1.2,0.8,2.2,1.9,2.4v11.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1
 l2,3c0.1-0.9,0.3-1.8,0.6-2.7l-0.3-0.5c0.1-0.1,0.2-0.1,0.2-0.2L12.2,45.6z M17,7.8l1,1.5l-5.7,7.9l-0.9-1.1c0,0,0,0,0,0L17,7.8z M10.7,18.4l3.3,3.9c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.5-1,0.1-1.4l-2-2.3l5.6-7.7l3.4,5.1l-9,10.8h-2.9
 V18.4z M23.2,18.6V27h-7L23.2,18.6z M8.8,32.6v-3.2c0-0.3,0.2-0.5,0.5-0.5h15.3c0.3,0,0.5,0.2,0.5,0.5v3.2c0,0.3-0.2,0.5-0.5,0.5
 H9.3C9,33.1,8.8,32.9,8.8,32.6z"/>
  <path d="M38.9,50.9l-1,0.4c0,0,0,0,0,0l-7.1,2.6c0.5-3.5-2.5-5.4-4.2-5.8L19.8,46l7.5-2.7c0.1,0,0.4,0,0.5,0l0.9,0.3L25.2,45
 c-0.5,0.2-0.8,0.8-0.6,1.3c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1l4.6-1.7c-1-1.1-1.9-2.4-2.6-3.8c-0.3,0-0.7,0-1.1,0.1
 c0,0,0,0,0,0c0,0,0,0,0,0l-10.9,3.9c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-1,0.7-1.2,1.8c-0.1,0.7,0,1.4,0.4,1.9
 c0,0.1,0.1,0.1,0.1,0.2c-0.6,0.5-1.1,1.2-1.2,2.3c-0.5,3,0.6,4.8,3.4,5.7l8.9,2.7c0.2,0.1,0.7,0.2,1.4,0.2c0.3,0,0.6,0,0.9-0.1
 c0,0,0,0,0,0l0.1,0c0,0,0,0,0.1,0l10.9-3.9c0,0,0.1,0,0.1-0.1c0.6-0.3,1-0.8,1.1-1.4c0.2-0.8,0.1-1.5-0.3-2.1
 c0.5-0.3,1-0.9,1.3-1.9c-0.7-0.1-1.3-0.2-2-0.3C39.2,50.7,39,50.9,38.9,50.9z M28.7,53.8c-0.1,0.5-0.3,0.9-0.6,1.1
 c-0.3,0.2-0.9,0.2-1.7-0.1L17.6,52c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l8.9,2.7l0,0c1.9,0.6,1.9,0.9,1.7,1.5
 c-0.1,0.1-0.7,0.1-1.1-0.1l-8.9-2.7c-1.9-0.6-2.3-1.4-2-3.5c0.2-1.2,1.1-1.3,1.5-1.3l8.8,2.7c0.5,0.2,1.1-0.1,1.3-0.7
 c0.2-0.5-0.1-1.1-0.7-1.3l-8.9-2.7l-0.1,0c-0.1,0-1.3-0.3-1.2-1c0-0.2,0.1-0.4,0.2-0.4c0.1-0.1,0.4,0,0.5,0l8.9,2.8
 C26.2,50.1,29.2,51,28.7,53.8z M38.4,54.3l-8.8,3.2c0-0.4-0.1-0.7-0.3-0.9l8.8-3.2C38.5,53.6,38.5,53.8,38.4,54.3z"/>
  <path d="M43.2,18.7c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9S51.4,18.7,43.2,18.7z M43.2,46.5
 c-7.1,0-12.9-5.8-12.9-12.9S36,20.7,43.2,20.7c7.1,0,12.9,5.8,12.9,12.9S50.3,46.5,43.2,46.5z"/>
  <path d="M35.7,35.5l1.2-1.6c0.1-0.1,0.2-0.3,0.2-0.4s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.4,0.1
 s-0.3,0.3-0.4,0.5l-1,1.4l-1-1.4c-0.2-0.2-0.3-0.4-0.4-0.5s-0.3-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
 c0,0.2,0.1,0.4,0.3,0.7l1.2,1.6l-1.4,2c-0.2,0.3-0.3,0.5-0.3,0.7c0,0.2,0.1,0.3,0.2,0.4s0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1
 c0.1-0.1,0.3-0.3,0.4-0.5l1.1-1.6l1.1,1.6c0.2,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.4
 c0-0.2-0.1-0.4-0.3-0.7L35.7,35.5z"/>
  <path d="M42.9,28.2c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.3,0.3-0.5,0.7c-0.3,0.4-0.6,0.7-0.9,1c-0.3,0.3-0.8,0.6-1.4,0.8
 c-0.4,0.2-0.6,0.3-0.8,0.4s-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.4,0,1.2-0.5,2.4-1.4v6.4
 c0,0.4,0.1,0.7,0.3,0.9s0.4,0.3,0.7,0.3c0.6,0,1-0.5,1-1.4v-8.1c0-0.3-0.1-0.6-0.2-0.8S43.2,28.2,42.9,28.2z"/>
  <path d="M53.8,30.3c-0.2-0.4-0.5-0.8-0.8-1.1s-0.6-0.5-1-0.7s-0.8-0.2-1.3-0.2c-0.7,0-1.4,0.2-1.9,0.5c-0.5,0.4-0.9,0.9-1.2,1.6
 c-0.2,0.4-0.3,0.9-0.4,1.5C47.1,32.3,47,33,47,33.7c0,0.6,0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.3,1.3c0.3,0.7,0.7,1.3,1.3,1.7
 c0.6,0.4,1.2,0.6,1.9,0.6c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.3c0.3-0.5,0.4-1,0.5-1.5c0.1-0.6,0.1-1.2,0.1-2
 C54.3,32.1,54.1,31,53.8,30.3z M52.2,35.6c-0.1,0.6-0.3,1-0.5,1.3c-0.2,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.1-1-0.4
 c-0.2-0.3-0.4-0.7-0.5-1.3S49,34.4,49,33.5c0-1.3,0.1-2.3,0.4-2.9s0.7-0.9,1.3-0.9c0.4,0,0.8,0.1,1,0.4c0.2,0.3,0.4,0.7,0.5,1.2
 s0.2,1.3,0.2,2.1C52.4,34.3,52.3,35.1,52.2,35.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "serviettex10"
    }
</script>

<style scoped>

</style>