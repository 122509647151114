<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgEssuieVerre" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M30,35.1c0-0.1,0-0.2,0-0.3c0.2-3.6,0.9-8.5,1.8-11.5h-2.1c-0.1,0.5-0.3,1-0.4,1.6c0-0.5,0.1-1.1,0.1-1.6h-2
 c-0.4,3.9,0.1,8.1,0.4,10.3c0.1,0.5,0.1,1,0.1,1.1c0,0,0,0,0,0.1c0,0.5,0.3,1,0.8,1C29.4,35.9,29.8,35.6,30,35.1z"/>
  <path d="M41.7,17.7c-2.1-2.6-4.7-3.7-7.6-3.3c-2.8,0.4-4.5,2.1-5.5,4.4h-0.9h-8.3l-0.2,0.7c-0.2,0.6-3.9,14.2,1.2,20.9
 c1.6,2.1,3.8,3.2,6.5,3.5v12.6h-6.8c-0.6,0-1,0.4-1,1s0.4,1,1,1h9c-0.1,1.2-0.1,1.9-0.1,2c0,0.3,0.1,0.5,0.3,0.7
 c1,1,2.1,1.4,3.2,1.4c1.5,0,2.9-0.7,3.8-1.2c0.4-0.3,0.5-0.8,0.4-1.2c-0.1-0.2-0.1-0.3-0.2-0.5c0.4,0,0.7,0,1.1,0
 c2.3,0,4.3-0.3,4.4-0.4c0.3-0.1,0.6-0.3,0.7-0.5c0.1-0.3,0.1-0.6,0-0.9c-2.3-4.3-1.2-7.2,1.2-13.7c0.4-1.1,0.8-2.3,1.3-3.6
 C47.6,33.6,45.9,22.9,41.7,17.7z M22,39.2c-3.9-5.1-1.8-15.6-1.1-18.4h6.8h0.2c0,0,0,0,0,0H30c0,0,0,0,0,0h0.7c0,0,0,0,0,0h2.4v0
 c0,0,0,0,0,0h0c0.8,1.5-0.6,9.5-1.6,15.4c-0.3,1.9-0.6,3.8-0.9,5.6C29.9,42,29.2,42,28.4,42C25.6,42,23.4,41.1,22,39.2z M30.3,43.9
 c-0.1,1.2-0.4,3.6-0.6,4.8l0-4.7C30,43.9,30.2,43.9,30.3,43.9z M31.1,60.1c0-0.9,0.1-3,0.3-5.8c0,0,0,0,0,0
 c0.4,0.1,0.8,0.2,1.3,0.2c0.6,0,1.2-0.1,1.8-0.4c-0.3,2.2-0.4,4.2,0,5.9C33.7,60.5,32.2,61,31.1,60.1z M36.2,57.6
 c0-1.7,0.3-3.7,0.8-5.7c0.8,0,1.8,0,2.5,0.1c-0.2,1.8,0,3.6,0.8,5.5C39.3,57.6,37.8,57.7,36.2,57.6z M43.3,39.9
 c-0.5,1.3-0.9,2.5-1.3,3.6C41,46,40.3,48.1,39.8,50c-0.8-0.1-1.6-0.1-2.3-0.1c0.2-0.8,0.4-1.5,0.6-2.3c0.2-0.6,0.3-1.2,0.5-1.8
 c1.3-5.1,1.4-12.2,1.2-13.5c0-0.6-0.6-1-1.1-0.9c-0.5,0-1,0.5-0.9,1.1c0.1,1.1,0.1,8-1.2,12.8c-0.1,0.6-0.3,1.2-0.5,1.8
 c-0.4,1.5-0.8,3.1-1.2,4.7c0,0-0.1,0-0.1,0c-0.6,0.3-1.1,0.5-1.6,0.6l-1.3-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c0.3-4,0.8-8.6,1.4-12.4
 c0,0,0-0.1,0-0.1c0.2-1,0.3-2,0.5-2.9c2.1-12.1,2.7-16.9,0.4-17.7c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.3-0.1-0.5-0.1-0.8,0h-2.1
 c0.8-1.3,1.9-2.2,3.6-2.5c2.1-0.3,4.1,0.6,5.7,2.6C44,23.6,45.4,33.6,43.3,39.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "essuieverre"
    }
</script>

<style scoped>

</style>