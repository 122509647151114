<template>
    <div :id="id" ref="map"></div>
</template>

<script>
    import GoogleMapsLoader from "../../GoogleMapsLoader";
    import MarkerClusterer from "@google/markerclusterer";
    import styles from "../../gmapStyle";

    export default {
        name: "Map",
        props: {
            id: String,
            zoom: Number,
            center: Object,
            locations: Array,
            autocenter: Boolean
        },
        data() {
            return {
                map: null,
                markers: [],
                google: null,
                infoWin: null,
                markerCluster: null
            }
        },
        created() {
            GoogleMapsLoader.onLoad(google => {
                this.google = google;
                this.map = new google.maps.Map(this.$refs.map, {
                    zoom: this.zoom,
                    center: this.center,
                    styles
                });
                this.infoWin = new google.maps.InfoWindow();

                this.map.addListener('bounds_changed', this.checkLocationsInBound.bind(this));

                this.updateMarkers();
            });
            GoogleMapsLoader.load();
        },
        watch: {
            locations() {
                this.updateMarkers();
                this.checkLocationsInBound();
            }
        },
        methods: {
            updateMarkers() {
                if (this.markerCluster) {
                    this.markerCluster.clearMarkers();
                }

                this.markers = this.locations.map(location => {
                    const marker = new this.google.maps.Marker({
                        position: location,
                        icon: {
                            url: location.image,
                            scaledSize: new this.google.maps.Size(30, 40)
                        },
                        data: location
                    });

                    marker.addListener('click', () => {
                        this.infoWin.setContent(
                            `<a href=${location.url}>${location.name}</a>`
                            + location.infos.map(info => `<p>${info}</p>`).join('')
                        );
                        this.infoWin.open(this.map, marker);
                    });

                    return marker;
                });

                this.markerCluster = new MarkerClusterer(this.map, this.markers, {
                    styles: [
                        {
                            textColor: 'white',
                            url: '/img/brand/cluster.png',
                            height: 50,
                            width: 50,
                            textSize: 12
                        }
                    ],
                    maxZoom: 9
                });
            },
            checkLocationsInBound() {
                const inBoundLocations = this.markers
                    .filter(
                        marker => this.map.getBounds().contains(marker.getPosition())
                    )
                    .map(
                        marker => marker.data
                    )
                ;
                this.$emit('in-bounds-locations-changed', inBoundLocations)
            }
        }
    }
</script>

<style scoped>

</style>