<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTorchon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
    <path d="M35.4,10.2c0-0.3,0.1-0.5,0.1-0.8c0-3.1-2.5-5.7-5.7-5.7s-5.7,2.5-5.7,5.7c0,1.8,0.8,3.4,2.1,4.4L20,40.1
  c-0.1,0.3-0.4,1.5,0.3,2.8c0.8,1.4,2.5,2.5,5,3.2l-1.3,10.7c0,0.4,0.1,0.8,0.5,1c0.1,0.1,2.7,1.6,6.1,1.6c0.2,0,0.4,0,0.5,0
  c4-0.2,5-2.7,5-2.8c0-0.1,0.1-0.3,0.1-0.4l-0.5-7.1c0.9-0.6,3.4-1.7,6.5-0.1c0.5,0.2,1,0.2,1.4-0.1C44.2,48.3,45.8,46.9,35.4,10.2z M37.3,41.9c-0.8,0.1-1.4,0.4-1.9,0.6l-0.7-9.3c0-0.6-0.5-1-1.1-0.9c-0.6,0-1,0.5-0.9,1.1L34,52.2c-3.2,1.6-6.1,0.6-7.4,0l4-33.2
  c0.1-0.5-0.3-1-0.9-1.1c-0.5-0.1-1,0.3-1.1,0.9l-2.5,20.8c-2-0.5-3-1.4-3.4-2.1l6.4-27.3c0.2-1,1.2-1.7,2.3-1.6
  c0.8,0.1,1.6,0.7,1.8,1.5l0,0.1c3.4,12,6.9,25.1,8.3,32.2c-1.1-0.5-2.2-0.6-3.3-0.5L32.5,11c-0.1-0.3-0.3-0.4-0.6-0.4
  c-0.3,0.1-0.5,0.3-0.4,0.6L37.3,41.9z M26.1,9.5c0-2,1.7-3.7,3.7-3.7c0.9,0,1.7,0.3,2.3,0.8c-0.2,0-0.3-0.1-0.5-0.1
  c-2-0.2-3.9,1.2-4.4,3.1l-0.4,1.9C26.3,10.9,26.1,10.2,26.1,9.5z M22.1,42c-0.4-0.7-0.2-1.2-0.2-1.3l0.5-2c0.7,0.8,1.8,1.5,3.6,1.9
  l-0.4,3.5C23.4,43.5,22.5,42.6,22.1,42z M31.1,57.3c-2.2,0.1-4.1-0.6-5-1l0.4-3c0.9,0.4,2.3,0.8,3.9,0.8c1.1,0,2.4-0.2,3.7-0.8
  l0.2,2.6C34.1,56.3,33.3,57.2,31.1,57.3z M35.4,43.5c0.5-0.2,1.2-0.5,2-0.6l0.6,3.2c-0.9,0.1-1.8,0.4-2.4,0.7L35.4,43.5z M39,46
  l-0.6-3.2c1.1-0.1,2.2,0.1,3.3,0.8c0.2,1.3,0.4,2.3,0.4,3C41.1,46.2,40,46,39,46z"/>
</svg>

</template>

<script>
    export default {
        name: "torchon"
    }
</script>

<style scoped>

</style>