<template>
    <div>
        <Label>
            {{ translates.partner_type }}
            <select v-model="partnersType">
                <option :value="null">---</option>
                <option :key="option.id" :value="option.id" v-for="option in typeOptions">
                    {{ option.name }}
                </option>
            </select>
        </Label>
        <br/>
        <label>
            Recherche
            <input v-model="searchTerm"/>
        </label>
        <div v-for="partner in filteredPartners" :key="`${partner.type}-${partner.id}`" class="checkbox">
            <label>
                <input type="checkbox"
                       :name="`partner[${partner.type}][${partner.id}][add]`"
                       value="1"
                       :checked="partnerChecked(partner.id, partner.type)"
                       @change="togglePartner(partner.id, partner.type, $event.target.checked)">
                {{ partner.name }}
            </label>
            <label v-if="partnerChecked(partner.id, partner.type)">
                {{ translates.commission }}
                <input :name="`partner[${partner.type}][${partner.id}][commission]`"
                       type="number"
                       min="0"
                       max="100"
                       step="any"
                       required
                       v-model="partner.commission"/>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserSpecialCommissions",
        components: {},
        props: {
            partners: Array,
        },
        data() {
            return {
                partnersType: null,
                searchTerm: '',
                checkedPartnerIds: {depot: new Set(), laundry: new Set(), supplier: new Set()},
            };
        },
        methods: {
            togglePartner(partnerId, type, checked) {
                const previousState = this.checkedPartnerIds[type];
                const newState = new Set(previousState);
                if (checked) {
                    newState.add(partnerId);
                } else {
                    newState.delete(partnerId);
                }

                this.checkedPartnerIds = {...this.checkedPartnerIds, [type]: newState};
            },
            partnerChecked(partnerId, type) {
                return this.checkedPartnerIds[type].has(partnerId);
            },
        },
        computed: {
            checkedPartners() {
                return this.partners.filter(p => this.checkedPartnerIds[p.type].has(p.id));
            },
            uncheckedPartners() {
                return this.partners.filter(p => !this.checkedPartnerIds[p.type].has(p.id));
            },
            filteredPartners() {
                const checkedPartners = this.checkedPartners;

                if (!this.partnersType && !this.searchTerm) {
                    return checkedPartners;
                }

                const searchedPartners = this.uncheckedPartners.filter(
                    partner => partner.name.toLowerCase().includes(this.searchTerm.toLowerCase()) && (!this.partnersType || partner.type === this.partnersType),
                );

                const partners = [
                    ...searchedPartners,
                    ...checkedPartners,
                ];

                partners.sort((partnerA, partnerB) => partnerA.name.localeCompare(partnerB.name));

                return partners;
            },
            typeOptions() {
                return [
                    {id: 'depot', name: 'Point de retrait'},
                    {id: 'laundry', name: 'Blanchisserie'},
                    {id: 'supplier', name: 'Plateforme'},
                ];
            },
        },
    };
</script>

<style scoped>

</style>
