<template>
    <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            version="1.1"

            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            style="enable-background:new 0 0 64 64;"
            xml:space="preserve"
            inkscape:version="0.91 r13725"
            sodipodi:docname="el_couette xl.svg"><metadata
     ><rdf:RDF><cc:Work
         rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title></dc:title></cc:Work></rdf:RDF></metadata>
        <defs
        />
        <sodipodi:namedview
                pagecolor="#ffffff"
                bordercolor="#666666"
                borderopacity="1"
                objecttolerance="10"
                gridtolerance="10"
                guidetolerance="10"
                inkscape:pageopacity="0"
                inkscape:pageshadow="2"
                inkscape:window-width="2560"
                inkscape:window-height="1417"

                showgrid="false"
                inkscape:zoom="7.375"
                inkscape:cx="83.555708"
                inkscape:cy="-0.7101292"
                inkscape:window-x="-8"
                inkscape:window-y="-8"
                inkscape:window-maximized="1"
                inkscape:current-layer="Calque_1"/>
        <g

                transform="translate(2.0450775,-7.3440665)"><path
       sodipodi:nodetypes="cscssssccsssscssccsscssccsscsscccssssssssssssssssss"
       d="m 14.8,18.7 c 0,-0.8 0.7,-1.5 1.5,-1.5 l 1.4,0 0,4.6 c 0,1.4 1.1,2.5 2.5,2.5 l 9,0 c 1.4,0 2.5,-1.1 2.5,-2.5 l 0,-4.6 1.1,0 0,4.6 c 0,1.4 1.1,2.5 2.5,2.5 l 9,0 c 1.4,0 2.5,-1.1 2.5,-2.5 l 0,-4.6 0.9,0 c 0.8,0 1.5,0.7 1.5,1.5 l 0.112829,14.542294 1.881356,1.254238 L 51.2,18.7 c 6.99e-4,-1.9 -1.6,-3.5 -3.5,-3.5 l -1,0 C 46.5,14 45.5,13.1 44.2,13.1 l -9,0 C 34,13.1 33,14 32.7,15.2 l -1.2,0 C 31.3,14 30.3,13.1 29,13.1 l -9,0 c -1.2,0 -2.2,0.9 -2.5,2.1 l -1.5,0 c -1.9,0 -3.5,1.6 -3.5,3.5 l 0,5.5 2.271186,0 z m 20,-3 c 0,-0.3 0.2,-0.5 0.5,-0.5 l 9,0 c 0.3,0 0.5,0.2 0.5,0.5 l 0,6.1 c 0,0.3 -0.2,0.5 -0.5,0.5 l -9,0 c -0.3,0 -0.5,-0.2 -0.5,-0.5 z m -15.1,0 c 0,-0.3 0.2,-0.5 0.5,-0.5 l 9,0 c 0.3,0 0.5,0.2 0.5,0.5 l 0,6.1 c 0,0.3 -0.2,0.5 -0.5,0.5 l -9,0 c -0.3,0 -0.5,-0.2 -0.5,-0.5 z"
       
       inkscape:connector-curvature="0" />
            <path

                    d="m 11.5,25.974609 0,14.417969 a 15,15 0 0 1 9.261719,-3.205078 15,15 0 0 1 15,15 15,15 0 0 1 -1.851563,7.212891 l 15.78125,0 c 1.572603,0 2.808594,-1.830058 2.808594,-4.158203 l 0.04883,-19.523438 -1.308594,-0.619141 -12.974609,1.875 c -4.568214,0.660563 -6.336576,-0.635579 -6.353516,-3.228515 l -0.05078,-7.771485 -20.361328,0 z m 22.699219,1.064453 0.15039,6.492188 c 0.01549,0.929434 1.312179,1.85673 2.632813,1.677734 l 11.263672,-1.52539 -14.046875,-6.644532 z"
                    inkscape:connector-curvature="0"/>
            <g

                    transform="translate(-21.338983,4.6881332)"/>
            <g
                    transform="translate(45.593648,33.15533)"
            ><path
         inkscape:connector-curvature="0"
         d="m -24.832631,5.6328026 c -7.4,0 -13.4,6.0000004 -13.4,13.4000004 0,7.4 6,13.4 13.4,13.4 7.4,0 13.4,-6 13.4,-13.4 0,-7.4 -6,-13.4000004 -13.4,-13.4000004 z m 0,24.7000004 c -6.3,0 -11.4,-5.1 -11.4,-11.4 0,-6.3 5.1,-11.4000004 11.4,-11.4000004 6.3,0 11.4,5.1000004 11.4,11.4000004 0,6.3 -5.1,11.4 -11.4,11.4 z"
          />
                <path
                        inkscape:connector-curvature="0"
                        d="m -25.432631,21.932803 -2.1,-3.3 2,-3 c 0.4,-0.5 0.5,-0.9 0.5,-1.2 0,-0.2 -0.1,-0.4 -0.2,-0.5 -0.2,-0.2 -0.4,-0.2 -0.6,-0.2 -0.1,0 -0.3,0 -0.4,0.1 -0.1,0 -0.2,0.1 -0.3,0.2 -0.1,0.1 -0.2,0.2 -0.3,0.4 -0.1,0.1 -0.2,0.3 -0.3,0.5 l -1.6,2.4 -1.5,-2.4 c -0.3,-0.5 -0.5,-0.8 -0.7,-0.9 -0.2,-0.1 -0.4,-0.2 -0.7,-0.2 -0.2,0 -0.5,0.1 -0.6,0.2 -0.1,0.1 -0.3,0.3 -0.3,0.5 0,0.2 0,0.4 0.1,0.6 0.1,0.2 0.2,0.4 0.4,0.7 l 1.9,2.9 -2.2,3.3 c -0.3,0.5 -0.5,0.9 -0.5,1.2 0,0.3 0.1,0.5 0.2,0.7 0.2,0.2 0.4,0.2 0.6,0.2 0.2,0 0.3,0 0.4,-0.1 0.1,-0.1 0.2,-0.1 0.3,-0.2 0.1,-0.1 0.2,-0.2 0.2,-0.3 0,-0.1 0.2,-0.3 0.3,-0.5 l 1.9,-2.9 1.8,2.8 c 0.2,0.3 0.4,0.6 0.5,0.7 0.1,0.1 0.2,0.2 0.3,0.3 0.1,0.1 0.3,0.1 0.5,0.1 0.2,0 0.3,0 0.5,-0.1 0.1,-0.1 0.2,-0.2 0.3,-0.3 0.1,-0.1 0.1,-0.3 0.1,-0.4 0,-0.2 0,-0.3 -0.1,-0.5 0,-0.2 -0.2,-0.5 -0.4,-0.8 z"
                />
                <path
                        inkscape:connector-curvature="0"
                        d="m -16.632631,22.532803 c -0.2,-0.2 -0.4,-0.2 -0.8,-0.2 l -4.1,0 0,-7.3 c 0,-0.4 -0.1,-0.7 -0.3,-0.9 -0.2,-0.2 -0.4,-0.3 -0.7,-0.3 -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.3,0.5 -0.3,0.9 l 0,7.7 c 0,0.4 0.1,0.8 0.3,1 0.2,0.2 0.5,0.3 1,0.3 l 4.9,0 c 0.3,0 0.6,-0.1 0.8,-0.2 0.2,-0.2 0.3,-0.4 0.3,-0.6 -0.1,-0.4 -0.2,-0.6 -0.4,-0.7 z"
                /></g></g></svg>
</template>

<script>
    export default {
        name: "couette-xl"
    }
</script>

<style scoped>

</style>