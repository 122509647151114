<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgChambre" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<path d="M11.5,18.6c0-0.8,0.6-1.4,1.4-1.4h1.4v4.3c0,1.4,1.1,2.5,2.5,2.5h8.6c1.4,0,2.5-1.1,2.5-2.5v-4.3H29v4.3
  c0,1.4,1.1,2.5,2.5,2.5H39c-0.6-0.6-1.2-1.3-1.7-2h-5.9c-0.3,0-0.5-0.2-0.5-0.5v-5.8c0-0.3,0.2-0.5,0.5-0.5h2.8
  c-0.1-0.7-0.2-1.3-0.3-2h-2.5c-1.2,0-2.2,0.8-2.4,2h-1.2c-0.2-1.1-1.2-2-2.4-2h-8.6c-1.2,0-2.2,0.8-2.4,2h-1.4
  c-1.9,0-3.4,1.5-3.4,3.4v5.3h2V18.6z M16.3,15.7c0-0.3,0.2-0.5,0.5-0.5h8.6c0.3,0,0.5,0.2,0.5,0.5v5.8c0,0.3-0.2,0.5-0.5,0.5h-8.6
  c-0.3,0-0.5-0.2-0.5-0.5V15.7z"/>
<path d="M56.6,21.3c-0.2-0.8-0.9-1.4-1.7-1.4c0,0,0,0-0.1,0c-2.8-0.2-5.5-2-7.2-4.8c-1.8-2.7-2.3-5.9-1.4-8.6c0,0,0,0,0,0
  c0.3-0.8,0.1-1.6-0.5-2.1c-0.7-0.6-1.7-0.6-2.4,0c0,0,0,0-0.1,0c-0.8,0.7-1.5,1.4-2.1,2.3c-2.6,3.9-2.7,8.8-0.2,12.6
  c2.1,3.3,5.7,5.2,9.6,5.2c0.6,0,1.3,0,1.9-0.1c1-0.2,2-0.5,3-0.9c0,0,0,0,0.1,0C56.4,23.1,56.9,22.2,56.6,21.3z M52.3,22.4
  c-3.8,0.6-7.6-1.1-9.5-4.1c0,0,0,0,0,0c-2-3-1.9-7.2,0.2-10.4c0.4-0.6,0.8-1.1,1.3-1.6c-1,3.2-0.4,6.8,1.7,9.9
  c1.9,3.2,5,5.3,8.3,5.7C53.6,22.1,53,22.3,52.3,22.4z"/>
<g>
  <path d="M9.5,41.2v15.5c0,1.9,1.5,3.4,3.4,3.4h30.6c1.9,0,3.4-1.5,3.4-3.4V41.2H9.5z"/>
  <path d="M9.5,39l0.8,0.2h35.9l0.7-0.2c1.3-0.5,2.2-1.8,2.2-3.2v-6.4c-0.7-0.1-1.3-0.3-2-0.5c-1.3-0.3-3.8-1.6-3.8-1.6
 c-1-0.6-2-1.2-2.9-2H10.8c-1.9,0-3.5,1.6-3.5,3.5v7C7.3,37.2,8.2,38.4,9.5,39z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "chambre"
    }
</script>

<style scoped>

</style>