<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitBainSimple" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M57.4,11.1h-2.6v4h2.6c1.1,0,2-0.9,2-2S58.5,11.1,57.4,11.1z"/>
  <path d="M50.2,7h-2.4H20.8h-5.7c-0.6,0-1.2,0.3-1.7,0.7c-0.3,0.3-0.6,0.6-0.8,1c-0.3,0.5-0.6,1.1-0.8,1.8c-0.1,0.2-0.1,0.4-0.1,0.6
 h-5c-1.1,0-2,0.9-2,2s0.9,2,2,2h4.8v0h7.3v1.4h-7.3v1.6v1.2v23.1c0,0,0,0,0,0v4.7c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.3
 c0.2,0,0.3,0.1,0.5,0.1h1.9v2.2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.2h0.4c0.2,0,0.3,0,0.5,0v6c0,0.2,0,0.3,0,0.5V56c0,0.6,0.4,1,1,1
 s1-0.4,1-1v-2.7c0.2,0,0.3,0,0.5,0h1.9V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h2.4V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h2.4V56
 c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h2.4V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h2.4V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6H45V56
 c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.6h1.9c0.2,0,0.3,0,0.5-0.1V56c0,0.6,0.4,1,1,1s1-0.4,1-1v-4.8c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4v-7.5
 c1.2-0.1,2.1-1.1,2.1-2.3V12.1C53.4,9.3,52,7,50.2,7z M41,9c0.1,0.2,0.2,0.4,0.2,0.8v10h-12v-10c0-0.3,0-0.6-0.1-0.8H41z M41.2,22.8v2.1h-12v-2.1H41.2z M49.4,50.9c0,0.3-0.2,0.5-0.5,0.5H21.3c-0.3,0-0.5-0.2-0.5-0.5V12.7c0-1.4-0.2-2.6-0.7-3.7h3.3
 c0.4,0.9,0.7,2,0.7,3.2v28.9c0,1.3,1,2.3,2.3,2.3h23V50.9z M51.4,41.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-1.8h-23
 c-0.2,0-0.3-0.1-0.3-0.3v-3.8h25.3V41.1z M51.4,15.1v17.1H26.1V12.1c0-1.1-0.2-2.2-0.5-3.2H27c0.1,0.3,0.1,0.6,0.1,0.9v15.8
 c0,0.7,0.6,1.3,1.3,1.3h13.5c0.7,0,1.3-0.6,1.3-1.3V9.8c0-0.3,0-0.6-0.1-0.9h4.6h2.4c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0
 c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.4,0.5,0.9,0.7,1.7c0,0.1,0,0.3,0.1,0.4c0,0.2,0,0.4,0,0.6V15.1z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kitbainsimple"
    }
</script>

<style scoped>

</style>