import Vuex, {Store} from 'vuex';
import Vue from 'vue';
import moment from "moment";
import {compareDates, itemDateToMoment} from "./flow/dates";
import {missingItemsQty} from "./flow/products";

Vue.use(Vuex);

function groupItemsByProduct(filteredItems) {
    const itemsByProduct = {};
    filteredItems.forEach(item => {
        itemsByProduct[item.productId] = itemsByProduct[item.productId] || [];
        itemsByProduct[item.productId].push(item);
    });
    return itemsByProduct;
}

const dateFilter = (fromDate, toDate) => (...dates) => {
    const fromDateValid = fromDate.isValid();
    const toDateValid = toDate.isValid();
    if (!fromDateValid && !toDateValid) {
        return true;
    }

    dates = dates.map(itemDateToMoment);

    if (fromDateValid && dates.filter(d => d.isSameOrAfter(fromDate)).length === 0) {
        return false;
    }

    if (toDateValid && dates.filter(d => d.isSameOrBefore(toDate)).length === 0) {
        return false;
    }

    return true;
};

const lastHistoryBefore = (productHistory1, today) => productHistory1.filter(history => history.date.isSameOrBefore(today, 'day')).slice(-1)[0];

const currentQtyOutFromHistory = (productHistory1, today) => {
    const lastHistoryBeforeToday = lastHistoryBefore(productHistory1, today);
    return lastHistoryBeforeToday ? -lastHistoryBeforeToday.qty : 0;
};

const store = new Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        products: null,
        orders: [],
        items: [],
        filters: {
            from: '',
            to: ''
        }
    },
    getters: {
        products({products}) {
            return products;
        },
        filteredItems({items}, {currentDateFilter}) {
            if (items === null) {
                return null;
            }

            return items.filter(({inDate, outDate}) => currentDateFilter(inDate, outDate));
        },
        itemsInProduct(state, {filteredItems}) {
            const itemsByProduct = groupItemsByProduct(filteredItems);
            return productId => itemsByProduct[productId] || [];
        },
        qtyIn(state, {filteredItems}) {
            return filteredItems.reduce((qtyIn, i) => qtyIn + i.in, 0);
        },
        qtyOut(state, {filteredItems}) {
            return filteredItems.reduce((qtyOut, i) => qtyOut + i.out, 0);
        },
        qtyCurrentlyOut({products}, {productHistory}) {
            if (!products) {
                return 0;
            }

            const today = moment();
            return products.reduce((qtyCurrentlyOut, p) => qtyCurrentlyOut + currentQtyOutFromHistory(productHistory(p.id) || [], today), 0);
        },
        currentDateFilter({filters: {from, to}}) {
            const fromDate = itemDateToMoment(from);
            const toDate = itemDateToMoment(to);

            return dateFilter(fromDate, toDate);
        },
        productHistory({items}) {
            const itemsByProduct = groupItemsByProduct(items);
            const historyByProduct = {};
            Object.keys(itemsByProduct).forEach(productId => {
                const movements = itemsByProduct[productId].reduce((movements, {inDate, in: inQty, outDate, out}) => {
                    if (inDate) {
                        movements.push({qty: inQty, date: itemDateToMoment(inDate)});
                    }
                    if (outDate) {
                        movements.push({qty: -out, date: itemDateToMoment(outDate)});
                    }
                    return movements;
                }, []).sort((a, b) => compareDates(a.date, b.date) || a.qty - b.qty);

                historyByProduct[productId] = movements.reduce((history, movement) => {
                    const lastQty = history.slice(-1)[0].qty;
                    return [...history, {qty: lastQty + movement.qty, date: movement.date}]
                }, [{qty: 0, date: moment(null)}]);
            });

            return productId => historyByProduct[productId];
        },
        productQtyCurrentlyOut(_, {productHistory}) {
            const today = moment();
            return productId => currentQtyOutFromHistory(productHistory(productId), today);
        },
        productMaxQtyOut({filters: {to}}, {productHistory: retrieveProductHistory, currentDateFilter}) {
            return productId => {
                const productHistory = retrieveProductHistory(productId);
                let productHistoryInPeriod = productHistory.filter(history => currentDateFilter(history.date));
                if (productHistoryInPeriod.length === 0) {
                    productHistoryInPeriod = productHistory.filter(history => dateFilter(moment(null), itemDateToMoment(to))(history.date)).slice(-1)
                }
                return -Math.min(0, ...productHistoryInPeriod.map(history => history.qty));
            }
        },
        qtyMissing(state, {filteredItems}) {
            return missingItemsQty(...filteredItems);
        },
        findOrders({orders}) {
            return ids => orders.filter(o => ids.has(o.id));
        },
        findById({orders}) {
            const cache = {};
            return id => {
                return cache[id] || orders.find(o => {
                    cache[o.id] = o;
                    return o.id === id;
                });
            };
        },
        from({filters}) {
            return filters.from;
        },
        to({filters}) {
            return filters.to;
        },
        itemsLoaded({items}) {
            return items.length !== 0;
        },
    },
    mutations: {
        setProducts(state, {products}) {
            state.products = Object.freeze(products);
        },
        setOrders(state, {orders}) {
            state.orders = Object.freeze(orders);
        },
        setItems(state, {items}) {
            state.items = Object.freeze(items);
        },
        setFilters(state, {filters}) {
            state.filters = {...state.filters, ...filters};
        }
    }
});

// Hide direct access to state, force getters
export const getters = store.getters;
export const commit = store.commit;