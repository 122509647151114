<template>
    <input type="text"
           :id="id"
           @focus="$emit('focus', $event)"
           @blur="$emit('blur', $event)"
           @input="$emit('input', $event.target.value)"
           ref="input"/>
</template>

<script>
    import uuid from "uuid/v4";
    import $ from 'jquery';

    export default {
        name: 'DatePicker',
        props: {
            id: String,
            value: String,
        },
        computed: {
            datalistId() {
                return uuid();
            }
        },
        mounted() {
            const $input = $(this.$refs.input);
            $input.datepicker();
            $input.datepicker('setDate', this.value);
            $input.on('change', event => {
                this.$emit('input', event.target.value);
            });
        },
        watch: {
            value() {
                const $input = $(this.$refs.input);
                $input.datepicker('setDate', this.value);
            }
        }
    };
</script>
