import SessionStorageStore from "./SessionStorageStore";

const store = new SessionStorageStore('easyLingeOrderSession');

class CartStore {
    static get() {
        const defaultCart = {
            bundles: [],
            products: [],
            productsQty: 0,
            total: 0,
            date: new Date()
        };

        return Object.assign(defaultCart, store.get());
    }

    static set(order) {
        store.set(order);
    }

    static addProduct(bundle, product) {
        const type = bundle ? 'bundles' : 'products';
        const {ht, tax} = product.totalPrice;
        product = {...product, dataPrice: (ht + tax) / 100, type, amount: 0};

        const order = this.get();
        const oldProduct = order[type].find(oldProduct => oldProduct.id == product.id);
        const qtyDiff = product.qty;
        if (oldProduct) {
            oldProduct.qty += qtyDiff;
        } else {
            order[type].push(product);
        }
        order.productsQty += qtyDiff;
        order.total += product.dataPrice * qtyDiff;
        store.set(order);
    }

    static empty() {
        store.set({});
    }

    static subscribe(fn) {
        store.subscribe(fn);
    }

    static unsubscribe(fn) {
        store.unsubscribe(fn);
    }
}

export default CartStore;