<template>
    <li id="currentOrderBtn" class="cartContainer blockWithLabel">
        <a href="#" @click="handleClick" class="navbar-link wrapCart currentOrderLink" id="currentOrderLink">
            <svg version="1.1" class="svg svgCart" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 483.1 483.1" style="enable-background:new 0 0 483.1 483.1;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M434.5,418.7l-27.8-313.3c-0.5-6.2-5.7-10.9-12-10.9h-58.6C336,42.4,293.6,0,241.5,0S147,42.4,146.9,94.5H88.3
                            c-6.2,0-11.4,4.7-12,10.9L48.5,418.7c0,0.4,0,0.7,0,1.1c0,34.9,32.1,63.3,71.5,63.3h243c39.4,0,71.5-28.4,71.5-63.3
                            C434.5,419.4,434.5,419.1,434.5,418.7z M241.6,24c38.9,0,70.5,31.6,70.6,70.5H170.9C171.1,55.6,202.6,24,241.6,24z M363,459H120
                            c-26,0-47.2-17.3-47.5-38.8l26.8-301.7h47.6v42.1c0,6.6,5.4,12,12,12s12-5.4,12-12v-42.1h141.2v42.1c0,6.6,5.4,12,12,12
                            s12-5.4,12-12v-42.1h47.6l26.8,301.8C410.3,441.7,389,459,363,459z"></path>
                    </g>
                </g>
            </svg>
            <span class="textLabel">{{ getTranslation('cart.cart_button') }}</span>

            <span v-if="count > 0" class="num cartCounter">
                <span id="deskCartCounter">{{ count }}</span>
            </span>
        </a>
    </li>
</template>

<script>
    import CartStore from "../store/CartStore";
    import tryGoingToCart from "../tryGoingToCart";

    export default {
        name: "CartHeaderPicto",
        created() {
            CartStore.subscribe(this.update);
            this.update(CartStore.get());
        },
        beforeDestroy() {
            CartStore.unsubscribe(this.update);
        },
        data() {
            return {
                count: 0
            }
        },
        methods: {
            update({productsQty}) {
                this.count = productsQty
            },
            handleClick(event) {
                event.preventDefault();
                tryGoingToCart();
            }
        }
    }
</script>

<style scoped>

</style>
