<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgDrapXL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M13.7,49.9c-0.8,0-1.5-0.7-1.5-1.5v-16h34.5v0.7c0.7,0.2,1.4,0.4,2,0.7v-1.7c1.3-0.5,2.3-1.8,2.3-3.3v-7.3
 c0-1.9-1.6-3.5-3.5-3.5h-36C9.6,18,8,19.6,8,21.5v7.3c0,1.5,0.9,2.8,2.3,3.3v16.2c0,1.9,1.6,3.5,3.5,3.5h14.2
 c-0.2-0.7-0.3-1.3-0.4-2H13.7z M10,21.5c0-0.8,0.7-1.5,1.5-1.5h36c0.8,0,1.5,0.7,1.5,1.5v7.3c0,0.8-0.7,1.5-1.5,1.5h-36
 c-0.8,0-1.5-0.7-1.5-1.5V21.5z"/>
  <path d="M42.6,34.4c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4S56,55.2,56,47.8C56,40.4,50,34.4,42.6,34.4z M42.6,59.2
 c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4S54,41.5,54,47.8S48.9,59.2,42.6,59.2z"/>
  <path d="M42,50.8l-2.1-3.3l2-3c0.4-0.5,0.5-0.9,0.5-1.2c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.4-0.2-0.6-0.2c-0.1,0-0.3,0-0.4,0.1
 c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.5l-1.6,2.4l-1.5-2.4c-0.3-0.5-0.5-0.8-0.7-0.9
 s-0.4-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.6,0.2S35,43.1,35,43.3c0,0.2,0,0.4,0.1,0.6s0.2,0.4,0.4,0.7l1.9,2.9l-2.2,3.3
 c-0.3,0.5-0.5,0.9-0.5,1.2c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2
 c0.1-0.1,0.2-0.2,0.2-0.3s0.2-0.3,0.3-0.5l1.9-2.9l1.8,2.8c0.2,0.3,0.4,0.6,0.5,0.7s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1
 c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5C42.4,51.3,42.2,51.1,42,50.8z"/>
  <path d="M50.8,51.3c-0.2-0.2-0.4-0.2-0.8-0.2h-4.1v-7.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3
 s-0.3,0.5-0.3,0.9v7.7c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3h4.9c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.3-0.6
 C51.1,51.6,51,51.4,50.8,51.3z"/>
  <path d="M17.7,16.7h9c0.2,0,0.4,0,0.6-0.1h4.9c0.2,0,0.4,0.1,0.6,0.1h9c0.2,0,0.4,0,0.6-0.1h5.3V9.1c0-1.4-1.1-2.5-2.5-2.5h-0.9
 V6.1c0-1.4-1.1-2.5-2.5-2.5h-9c-1.4,0-2.5,1.1-2.5,2.5v0.6h-1.1V6.1c0-1.4-1.1-2.5-2.5-2.5h-9c-1.4,0-2.5,1.1-2.5,2.5v0.6h-1.4
 c-1.4,0-2.5,1.1-2.5,2.5v7.5h5.8C17.3,16.7,17.5,16.7,17.7,16.7z M32.3,6.6V6.1c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v0.6
 v5.6c0,0.3-0.2,0.5-0.5,0.5h-9c-0.3,0-0.5-0.2-0.5-0.5V6.6z M17.2,6.6V6.1c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v0.6v5.6
 c0,0.3-0.2,0.5-0.5,0.5h-9c-0.3,0-0.5-0.2-0.5-0.5V6.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "drapxl"
    }
</script>

<style scoped>

</style>