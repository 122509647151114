<template>
    <simplebar class="wrapListRelais" data-simplebar-auto-hide="false">
        <h4 v-for="location in locations" :key="location.id">
            <img :src="location.image" style="max-width:27px" :alt="location.name">
            <div class="text">
                <div class="title">
                    <a :href="location.url" :title="location.name">{{ location.name }}</a>
                </div>
                <div class="address">
                    <p :key="info" v-for="info in location.infos">{{ info }}</p>
                </div>
                <div class="links">
                    <a :href="location.url">{{ getTranslation('details') }} ></a>
                    <a :href="`http://www.google.com/maps/place/${location.lat},${location.lng}`">{{ getTranslation('access') }} ></a>
                </div>
            </div>
        </h4>
    </simplebar>
</template>

<script>
    import simplebar from 'simplebar-vue';

    export default {
        name: "PlacesList",
        props: {
            locations: Array
        },
        components: {simplebar}
    }
</script>

<style scoped>

</style>
