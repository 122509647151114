<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKitLitL" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M14.2,50.5c-0.8,0-1.5-0.7-1.5-1.5V33h34.5v0.7c0.7,0.2,1.4,0.4,2,0.7v-1.7c1.3-0.5,2.3-1.8,2.3-3.3v-7.3
 c0-1.9-1.6-3.5-3.5-3.5H12c-1.9,0-3.5,1.6-3.5,3.5v7.3c0,1.5,0.9,2.8,2.3,3.3V49c0,1.9,1.6,3.5,3.5,3.5h14.2
 c-0.2-0.7-0.3-1.3-0.4-2H14.2z M10.5,22.2c0-0.8,0.7-1.5,1.5-1.5h36c0.8,0,1.5,0.7,1.5,1.5v7.3c0,0.8-0.7,1.5-1.5,1.5H12
 c-0.8,0-1.5-0.7-1.5-1.5V22.2z"/>
  <path d="M12.7,9.8c0-0.8,0.7-1.5,1.5-1.5h1.4v4.6c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h1.1v4.6
 c0,1.4,1.1,2.5,2.5,2.5h9c1.4,0,2.5-1.1,2.5-2.5V8.3h0.9c0.8,0,1.5,0.7,1.5,1.5v7.5h2V9.8c0-1.9-1.6-3.5-3.5-3.5h-1
 c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.2c-0.2-1.2-1.2-2.1-2.5-2.1h-9c-1.2,0-2.2,0.9-2.5,2.1h-1.5
 c-1.9,0-3.5,1.6-3.5,3.5v7.5h2V9.8z M32.8,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V6.8z M17.6,6.8c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v6.1c0,0.3-0.2,0.5-0.5,0.5h-9
 c-0.3,0-0.5-0.2-0.5-0.5V6.8z"/>
  <path d="M43.1,35.1c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4s13.4-6,13.4-13.4C56.5,41.1,50.5,35.1,43.1,35.1z M43.1,59.8
 c-6.3,0-11.4-5.1-11.4-11.4s5.1-11.4,11.4-11.4s11.4,5.1,11.4,11.4S49.4,59.8,43.1,59.8z"/>
  <path d="M46.3,51.7h-4.1v-7.3c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.5-0.3,0.9v7.7
 c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3h4.9c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.3-0.6
 C46.9,51.8,46.7,51.7,46.3,51.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kitlitl"
    }
</script>

<style scoped>

</style>