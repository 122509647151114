<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgDraps" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M51.2,55.9V37.7c1.3-0.5,2.3-1.8,2.3-3.3v-7.3c0-1.9-1.6-3.5-3.5-3.5H14c-1.9,0-3.5,1.6-3.5,3.5v7.3c0,1.5,0.9,2.8,2.3,3.3
 v18.2c0,1.9,1.6,3.5,3.5,3.5h31.5C49.7,59.4,51.2,57.8,51.2,55.9z M12.5,34.4v-7.3c0-0.8,0.7-1.5,1.5-1.5h36c0.8,0,1.5,0.7,1.5,1.5
 v7.3c0,0.8-0.7,1.5-1.5,1.5H14C13.2,35.9,12.5,35.2,12.5,34.4z M14.8,55.9v-18h34.5v18c0,0.8-0.7,1.5-1.5,1.5H16.3
 C15.4,57.4,14.8,56.7,14.8,55.9z"/>
  <path d="M20.2,22.3h9c0.2,0,0.4,0,0.6-0.1h4.9c0.2,0,0.4,0.1,0.6,0.1h9c0.2,0,0.4,0,0.6-0.1h5.3v-5.5c0-1.4-1.1-2.5-2.5-2.5h-0.9
 v-0.6c0-1.4-1.1-2.5-2.5-2.5h-9c-1.4,0-2.5,1.1-2.5,2.5v0.6h-1.1v-0.6c0-1.4-1.1-2.5-2.5-2.5h-9c-1.4,0-2.5,1.1-2.5,2.5v0.6h-1.4
 c-1.4,0-2.5,1.1-2.5,2.5v5.5h5.8C19.8,22.2,20,22.3,20.2,22.3z M34.8,13.7c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v0.6v5.6
 c0,0.3-0.2,0.5-0.5,0.5h-9c-0.3,0-0.5-0.2-0.5-0.5v-5.6V13.7z M19.7,13.7c0-0.3,0.2-0.5,0.5-0.5h9c0.3,0,0.5,0.2,0.5,0.5v0.6v5.6
 c0,0.3-0.2,0.5-0.5,0.5h-9c-0.3,0-0.5-0.2-0.5-0.5v-5.6V13.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "draps"
    }
</script>

<style scoped>

</style>