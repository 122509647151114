<template>
    <div class="wrapFloatLabel input-group">
        <slot name="input"
              :value="value"
              :id="id"
              :required="required"
              :handleFocus="handleFocus"
              :handleBlur="handleBlur"
              :handleInput="handleInput"
              :className="className">
            <FormInput :class-name="className"
                       @blur="handleBlur"
                       @focus="handleFocus"
                       @input="handleInput($event)"
                       :id="id"
                       :name="name"
                       :options="options"
                       :type="type"
                       :value="value"/>
        </slot>
        <label :for="id" class="floatLabel">
            <span class="borderMask"/>
            <span class="floatLabelContent">
                <slot name="label"/>
                <span v-if="required" class="right">*</span>
            </span>
        </label>
    </div>
</template>

<script>
    import uuid from "uuid/v4";
    import FormInput from "./FormInput";

    export default {
        name: "LabelledInput",
        components: {FormInput},
        props: {
            type: {
                type: String,
                default: 'text'
            },
            required: Boolean,
            value: String,
            initId: String,
            options: Array,
            name: String
        },
        data() {
            return {
                id: this.initId || uuid(),
                focused: false
            }
        },
        methods: {
            handleFocus() {
                this.focused = true;
            },
            handleBlur() {
                this.focused = false;
            },
            handleInput(newValue) {
                this.$emit('input', newValue)
            }
        },
        computed: {
            active() {
                return this.focused || this.value;
            },
            className() {
                return {'form-control floatLabelInput': true, 'active': this.active};
            }
        }
    }
</script>

