<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTablier" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64"  style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M56.4,41.1H46.2V28c0-0.6-0.4-1-1-1c-4.8,0-8.2-3.8-8.2-9.3l0-8.1c-0.1-1.3-1-3.7-4-3.7h-2.1c-2.9,0-3.9,2.4-4,3.8v8
 c0,5.4-3.4,9.3-8.2,9.3c-0.6,0-1,0.4-1,1v13.2H7.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h10.2v13c0,1.7,1.4,3.1,3.1,3.1h22.3
 c1.7,0,3.1-1.4,3.1-3.1v-13h10.2c0.6,0,1-0.4,1-1S57,41.1,56.4,41.1z M30.9,7.9h2.1c1.7,0,1.9,1.5,2,1.8v7H29l0-7
 C29,9.6,29.1,7.9,30.9,7.9z M44.2,56.2c0,0.6-0.5,1.1-1.1,1.1H20.9c-0.6,0-1.1-0.5-1.1-1.1V28.9c5.1-0.5,8.8-4.6,9.1-10.2h6.1
 c0.4,5.6,4.1,9.7,9.1,10.2V56.2z"/>
  <path d="M39.4,42.7H24.6c-0.6,0-1,0.4-1,1v6.1c0,2,1.6,3.7,3.7,3.7h9.4c2,0,3.7-1.6,3.7-3.7v-6.1C40.4,43.2,39.9,42.7,39.4,42.7z M38.4,49.9c0,0.9-0.8,1.7-1.7,1.7h-9.4c-0.9,0-1.7-0.8-1.7-1.7v-5.1h12.7V49.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "tablier"
    }
</script>

<style scoped>

</style>