import {itemDateToMoment} from "./dates";

export const missingItemsQty = (...filteredItems) => filteredItems.reduce((qtyMissing, item) => {
    let missing = 0;
    const today = new Date();
    if (
        itemDateToMoment(item.plannedInDate).isBefore(today) && !item.inDate
        || itemDateToMoment(item.plannedOutDate).isBefore(today) && !item.outDate
    ) {
        missing += Math.abs(item.out - item.in);
    }

    return qtyMissing + missing;
}, 0);