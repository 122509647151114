<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgDrapPlage" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M36.2,38c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7S38.8,38,36.2,38z M36.2,45.4c-1.5,0-2.7-1.2-2.7-2.7
 c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7C39,44.2,37.7,45.4,36.2,45.4z"/>
  <path d="M36.2,36.6c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C35.2,36.1,35.7,36.6,36.2,36.6z"/>
  <path d="M41.3,38.7c0.3,0,0.5-0.1,0.7-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L40.6,37c-0.4,0.4-0.4,1,0,1.4
 C40.8,38.6,41,38.7,41.3,38.7z"/>
  <path d="M45.4,41.7h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1S45.9,41.7,45.4,41.7z"/>
  <path d="M42,47.1c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L42,47.1
 z"/>
  <path d="M36.2,48.9c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C37.2,49.3,36.8,48.9,36.2,48.9z"/>
  <path d="M30.5,47.1l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4c0.4-0.4,0.4-1,0-1.4
 S30.9,46.7,30.5,47.1z"/>
  <path d="M30.1,42.7c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2C29.6,43.7,30.1,43.3,30.1,42.7z"/>
  <path d="M30.5,38.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-1.4-1.4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
 L30.5,38.4z"/>
  <path d="M57.4,10.9h-3.6v4h3.6c1.1,0,2-0.9,2-2S58.5,10.9,57.4,10.9z"/>
  <path d="M52.2,10.9c-0.3-1.5-0.9-2.7-1.8-3.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.4-0.3-0.7-0.3c-0.2-0.1-0.5-0.1-0.7-0.1H16.1
 c-0.5,0-1,0.2-1.4,0.5c-1,0.7-1.8,2-2.1,3.7h-6c-1.1,0-2,0.9-2,2s0.9,2,2,2h5.8v0h7.3v1.4h-7.3v1.6v1.2v23.1c0,1.4,1.1,2.5,2.5,2.5
 h4.3c0.2,0,0.3,0,0.5,0v12c0,1.4,1.1,2.5,2.5,2.5h27.5h0c0.4,0,0.7-0.1,1-0.2s0.6-0.3,0.8-0.5c0.5-0.5,0.7-1.1,0.7-1.8V44.3V14.9
 v-1.6v-0.4C52.4,12,52.3,11.4,52.2,10.9L52.2,10.9z M16.7,6.9c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0C17,7,17,7,17.1,7
 C16.9,7,16.8,6.9,16.7,6.9z M50.4,56.7c0,0.3-0.2,0.5-0.5,0.5H22.3c-0.3,0-0.5-0.2-0.5-0.5V12.5c0-1.4-0.2-2.6-0.7-3.7h27.6
 c0.6,0,1.6,1.5,1.6,3.7V56.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "drapplage"
    }
</script>

<style scoped>

</style>