<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgSalleDeBain" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M22.9,32.5h-4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.5,1.6h7.6v-1.8c0-1.7-0.8-2.7-1.4-2.7v-1
 C24.1,30,23.2,31.1,22.9,32.5z"/>
  <path d="M22.8,38.2L22.8,38.2c0,0.1,0,0.1,0,0.2v6.2c0,1.4,1.2,2.6,2.6,2.6h1.1V36.6h-3.8V38.2z"/>
  <path d="M52.4,32.5H50c0.1,0.4,0.1,0.8,0.1,1.3v1.8h2.3c0.9,0,1.6-0.7,1.6-1.6C54,33.2,53.3,32.5,52.4,32.5z"/>
  <path d="M48.9,32.5c0-0.2-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.4-0.6
 c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1
 c0,0-0.1,0-0.1-0.1c-0.1,0-0.3-0.1-0.4-0.1H25.2c0.2,0,0.3,0,0.5,0.1l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0l0,0
 c1.1,0.4,1.9,1.9,1.9,3.7v1.8v0.9v10.6v7.9c0,0.9,0.8,1.7,1.7,1.7h18.2c0.9,0,1.7-0.8,1.7-1.7V35.7v-1.8
 C49.1,33.4,49,32.9,48.9,32.5L48.9,32.5z M44.9,52.3H32c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h13c0.6,0,1,0.5,1,1
 C46,51.8,45.5,52.3,44.9,52.3z M44.9,48.7H32c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h13c0.6,0,1,0.5,1,1C46,48.2,45.5,48.7,44.9,48.7z"
 />
  <path d="M21.8,8.2C21.8,8.2,21.8,8.2,21.8,8.2C21.9,8.2,21.9,8.2,21.8,8.2c2.5,0,4.6,1.7,4.6,3.8c0,0,0,0,0,0
 c-3.4,0.2-6.2,1.5-7.6,3.2h17.3c-1.4-1.8-4.2-3-7.6-3.2c0,0,0,0,0,0c0-3.2-2.9-5.8-6.6-5.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
 l0,0C17.6,6,15,6.6,13.2,8.4C11,10.5,10,14.2,10,20.8v37.4c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1V20.8c0-5.8,0.8-9.2,2.5-10.9
 C16,8.6,18.2,8.1,21.8,8.2z"/>
  <path d="M20.2,20.8v1.6c0,0.6,0.5,1,1,1s1-0.5,1-1v-1.6c0-0.6-0.5-1-1-1S20.2,20.2,20.2,20.8z"/>
  <path d="M32.7,20.8v1.6c0,0.6,0.5,1,1,1s1-0.5,1-1v-1.6c0-0.6-0.5-1-1-1S32.7,20.2,32.7,20.8z"/>
  <path d="M26.4,22.4v2.1c0,0.6,0.5,1,1,1s1-0.5,1-1v-2.1c0-0.6-0.5-1-1-1S26.4,21.8,26.4,22.4z"/>
  <path d="M19.1,17.7h16.7c0.7,0,1.2-0.7,0.9-1.3H18.2C17.9,17,18.4,17.7,19.1,17.7z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "salledebain"
    }
</script>

<style scoped>

</style>