<template>
    <div class="bg-primary wrapFilterNetwork">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="row">
                        <div class="col-sm-8 col-sm-offset-2 col-md-4 col-md-offset-0 col-lg-4">
                            <div class="form-group wrapFloatLabel wrapSelect">
                                <LabelledSelect :options="[{id: null, name: 'Tous les départements'}, ...regions]" :value="selectedRegion" @input="$emit('region-change', $event)">
                                    {{ translates.your_department }}
                                </LabelledSelect>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-7 col-lg-offset-1">
                            <div class="blockCheckboxes">
                                <LabelledCheckbox v-for="type in types" :key="type.id" :checked="type.selected" @input="$emit('type-toggled', type)">
                                    {{ type.name }}
                                </LabelledCheckbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LabelledSelect from "../LabelledSelect";
    import LabelledCheckbox from "../LabelledCheckbox";

    export default {
        name: "NetworkSearchBar",
        components: {LabelledCheckbox, LabelledSelect},
        props: {
            regions: Array,
            selectedRegion: Object,
            types: Array
        }
    }
</script>

<style scoped>

</style>
