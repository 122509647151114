<template>
    <div>
        <TableCollapse v-if="qtyIn || qtyOut">
            <template v-slot:heading>
                #{{ product.id }} {{ product.name }}
                <h4><QtyInOut :qty-in="qtyIn" :qty-out="qtyOut" :qty-currently-out="qtyCurrentlyOut" :qty-missing="qtyMissing" :qty-max-out="qtyMaxOut"/></h4>
            </template>

            <template v-slot:body>
                <div class="row">
                    <div class="col-xs-12">
                        {{ getTranslation('flows.product.grouped') }}
                        <br />
                        <label>
                            {{ getTranslation('flows.product.by_date_delivery') }}
                            <input type="radio" name="focus" v-model="focus" value="out">
                        </label>
                        <br />
                        <label>
                            {{ getTranslation('flows.product.by_return_date') }}
                            <input type="radio" name="focus" v-model="focus" value="in">
                        </label>
                    </div>
                </div>
                <table class="table table-condensed">
                    <thead>
                    <tr>
                        <th v-if="focus === 'out'">{{ getTranslation('flows.product.date_delivery') }}</th>
                        <th v-else>{{ getTranslation('flows.product.return_date') }}</th>
                        <th>{{ getTranslation('flows.product.order_number') }}</th>
                        <th v-if="focus === 'in'">{{ getTranslation('flows.product.date_delivery') }}</th>
                        <th v-else>{{ getTranslation('flows.product.return_date') }}</th>
                        <th>{{ getTranslation('flows.product.client_quantity') }}</th>
                        <th>{{ getTranslation('flows.product.qty_missing') }}</th>
                        <th v-if="focus !== 'in'">{{ getTranslation('flows.product.qty_delivery') }}</th>
                        <th>{{ getTranslation('flows.product.qty_return') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in groupedItems" :key="item.id">
                        <th v-if="focus === 'out'">{{ joinDates(Array.from(item.outDates)) }}</th>
                        <th v-else>{{ joinDates(Array.from(item.inDates)) }}</th>
                        <th>
                            <a :href="item.order.links.items" target="_blank">{{ item.order.ref }}</a>
                        </th>
                        <th v-if="focus === 'in'">{{ joinDates(Array.from(item.outDates)) }}</th>
                        <th v-else>{{ joinDates(Array.from(item.inDates)) }}</th>
                        <th>{{ item.currentlyOut }}</th>
                        <th>
                            <span class="label label-danger" v-if="item.missing > 0">{{ item.missing }}</span>
                        </th>
                        <th v-if="focus !== 'in'">{{ item.out }}</th>
                        <th>{{ item.in }}</th>
                    </tr>
                    </tbody>
                </table>
            </template>
        </TableCollapse>
    </div>
</template>

<script>
    import {getters} from "../../productsFlow";
    import TableCollapse from "../generic/TableCollapse";
    import QtyInOut from "./QtyInOut";
    import {compareDates, itemDateToMoment} from "../../flow/dates";
    import {missingItemsQty} from "../../flow/products";

    export default {
        name: "CustomerProductFlow",
        components: {QtyInOut, TableCollapse},
        props: {
            product: {type: Object, required: true}
        },
        data() {
            return {
                focus: 'out',
            };
        },
        methods: {
            focusedDate(item) {
                return this.focus === 'in' ? item.inDate : item.outDate;
            },
            joinDates(dates) {
                return dates.join(' - ');
            },
            moment(date) {
                return itemDateToMoment(date);
            }
        },
        computed: {
            items() {
                return getters.itemsInProduct(this.product.id);
            },
            groupedItems() {
                const groupedItems = Object.values(this.items.reduce((groupedItems, item) => {
                    const focusedDate = this.focusedDate(item);
                    const key = focusedDate + item.orderId;

                    if (!key) {
                        return groupedItems;
                    }

                    const momentFocusedDate = itemDateToMoment(focusedDate);

                    if (!momentFocusedDate.isBetween(this.moment(getters.from), this.moment(getters.to))) {
                        return groupedItems;
                    }

                    if (!groupedItems[key]) {
                        groupedItems[key] = {...item};
                        groupedItems[key].inDates = new Set();
                        groupedItems[key].outDates = new Set();
                        groupedItems[key].currentlyOut = 0;
                        groupedItems[key].missing = 0;
                        groupedItems[key].out = 0;
                        groupedItems[key].in = 0;
                        groupedItems[key].focusedDate = momentFocusedDate;
                    }

                    groupedItems[key].out += item.out;
                    groupedItems[key].in += item.in;
                    groupedItems[key].missing += missingItemsQty(item);
                    if (item.inDate) {
                        groupedItems[key].inDates.add(item.inDate);
                        if (this.moment(item.inDate).isBefore(new Date(), 'day')) {
                            groupedItems[key].currentlyOut -= item.in;
                        }
                    }
                    if (item.outDate) {
                        groupedItems[key].outDates.add(item.outDate);
                        if (this.moment(item.outDate).isSameOrBefore(new Date(), 'day')) {
                            groupedItems[key].currentlyOut += item.out;
                        }
                    }

                    return groupedItems;
                }, {}));

                const groupedItemsWithOrder = groupedItems.map(i => ({...i, order: getters.findById(i.orderId)}));

                return groupedItemsWithOrder.sort((a, b) => {
                    return compareDates(a.focusedDate, b.focusedDate) || (b.order.id - a.order.id);
                });
            },
            qtyIn() {
                return this.items.reduce((qtyIn, i) => qtyIn + i.in, 0);
            },
            qtyOut() {
                return this.items.reduce((qtyOut, i) => qtyOut + i.out, 0);
            },
            qtyCurrentlyOut() {
                return getters.productQtyCurrentlyOut(this.product.id);
            },
            qtyMissing() {
                return missingItemsQty(...this.items);
            },
            qtyMaxOut() {
                return getters.productMaxQtyOut(this.product.id)
            }
        },
    };
</script>

<style scoped>

</style>
