import $ from "jquery";

const fixChromeAutofill = () => {
    if (navigator.userAgent.search("Chrome") >= 0) {
        setTimeout(
            () => $('input.floatLabelInput:-webkit-autofill').addClass('active'),
            100
        )
    }
};

//  ------------------------------------------------------=> FORMS FLOATING LABELS
function floatLabel() {
    fixChromeAutofill();

    // Affichage d'un masque pour afficher le label par dessus la bordure du champ
    $('.floatLabelContent').each(function() {
        $(this).parent().prepend('<span class="borderMask"></span>');
    });

    $('.floatLabelInput').each(function(){
        if(!$(this).val().length == 0 || !$(this).attr('placeholder') == 0) {
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });

    $(document).on('click focus', '.floatLabelInput', function(){
        var $this = $(this),
            $this_label = $this.next('.floatLabel');
        if($this_label.find('.active').length == 0) {
            $this.addClass('active');
        } else {
            $this.removeClass('active');
        }
    });

    $(document).on('blur change', '.floatLabelInput', function(){
        if(!$(this).val().length == 0 || !$(this).attr('placeholder') == 0) {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });

}


export default floatLabel;

//  ------------------------------------------------------=> FOCUS SEARCH FORM
function focusSearch(allowCloseOverlay){
    if (allowCloseOverlay === 'undefined') {
        const allowCloseOverlay = true;
    }
    const $pac = $('#pac-input');
    const $parentPac = $pac.closest('#home_input_container');
    if ($pac.length) {
        $parentPac.addClass('searchFocus');

        //add and remove overlay to fix attention
        if (!document.querySelector('.bodyOverlay')) {
            const overlay = document.createElement("span");
            overlay.setAttribute("class", "bodyOverlay" );
            document.body.prepend(overlay);
        }

        if (allowCloseOverlay) {
            //focus on address input
            $pac.focus();

            overlay.onclick = function() {
                this.parentNode.removeChild(this);
                $parentPac.removeClass('searchFocus');
            };
        }

        return;
    }

    var homeUrl = document.querySelector('[data-home-url]').dataset.homeUrl;

    if (window.location.href !== homeUrl) {
        window.location.href = homeUrl + '?focus=1';
    }
}

export {focusSearch};
