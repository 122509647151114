<template>
    <div>
        <span class="label label-info">{{ qtyCurrentlyOut }}</span>
        <small>
            {{ getTranslation('flows.qty_in_out.client_article')}}
        </small>
        <span class="label label-warning">{{ qtyOut }}</span>
        <small>
            {{ getTranslation('flows.qty_in_out.article_given')}}
        </small>
        <span class="label label-success">{{ qtyIn }}</span>
        <small>
            {{ getTranslation('flows.qty_in_out.article_back')}}
        </small>
        <template v-if="![null, undefined].includes(qtyMaxOut)">
            <span class="label label-primary">{{ qtyMaxOut }}</span>
            <small>
                {{ getTranslation('flows.qty_in_out.max_client_article')}}
            </small>
        </template>
        <template v-if="qtyMissing > 0">
            <span class="label label-danger">{{ qtyMissing }}</span>
            <small>
                {{ getTranslation('flows.qty_in_out.miss_article')}}
            </small>
        </template>
        <small>{{ getTranslation('flows.qty_in_out.on_period')}}</small>
    </div>
</template>
<script>
    export default {
        name: 'QtyInOut',
        props: {
            qtyIn: {},
            qtyOut: {},
            qtyCurrentlyOut: {},
            qtyMissing: {},
            qtyMaxOut: Number
        },
    };
</script>
