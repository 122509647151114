<template>
    <svg version="1.1" class="svg svgPointer" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 346.8 461" style="enable-background:new 0 0 346.8 461;" xml:space="preserve">
        <g>
 <g>
   <g>
  <path class="st0" d="M90.3,251.1c20.6,18.9,47.3,29.3,75.3,29.3c51.4,0,95.5-34.5,108-84.1H268c-15.9,0-28.8-13.2-28.8-29.4
  c0-6.4,2.1-12.4,5.5-17.2l-0.2-0.1c0,0-7.4-14.6-35.9-13.5c-33.9,1.4-59.5,58.2-102.1,58.2c-20,0-37.9-4.6-51.2-9.4
  C59,210.3,71.3,233.6,90.3,251.1"/>
                <path class="st0" d="M328.9,185.2L328.9,185.2c9.9-0.1,17.9-8.2,17.9-18.1c0-9.7-7.7-17.6-17.2-18.1C321.5,65.4,251,0,165.2,0
  C74,0,0,74,0,165.2C0,294.8,165.6,461,165.6,461S328.8,297.4,330,196h-16.9H288l-0.4,1.8c-6.4,27-21.9,51.5-43.7,69
  c-22.1,17.7-49.9,27.5-78.3,27.5c-31.4,0-61.5-11.7-84.7-32.9c-23-21.1-37.3-49.7-40.2-80.7c0-0.3,0-0.6,0-0.9
  c-0.4-4.1-0.6-8.3-0.6-12.6c0-69,56-125,125-125c59,0,108.5,40.9,121.6,96l0,0c0,0,1.5,6.3,2.1,10.8h-19.7
  c-10,0-18.1,8.1-18.1,18.1c0,10,8.1,18.1,18.1,18.1h19.8v0.1L328.9,185.2L328.9,185.2z"/>
   </g>
 </g>
  </g>
</svg>

</template>

<script>
    export default {
        name: "MapMarker"
    }
</script>

<style scoped>
  .st0 {
 fill: #00687C;
  }
</style>