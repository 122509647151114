<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgOreillerCarre" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M47.7,36.5H16.3c-1.9,0-3.5,1.6-3.5,3.5v7.6c0,1.7,1.3,3.1,2.9,3.4v4.3h0c0,0.5,0.4,0.9,0.9,1l30.6,2.9c0,0,0.1,0,0.1,0
 c0.5,0,0.9-0.4,1-0.9c0,0,0-0.1,0-0.1h0V51c1.6-0.3,2.9-1.7,2.9-3.4V40C51.2,38.1,49.6,36.5,47.7,36.5z M46.3,57.2l-28.6-2.7v-3.4
 h28.6V57.2z M49.2,47.6c0,0.8-0.7,1.5-1.5,1.5H16.3c-0.8,0-1.5-0.7-1.5-1.5V40c0-0.8,0.7-1.5,1.5-1.5h31.4c0.8,0,1.5,0.7,1.5,1.5
 V47.6z"/>
  <path d="M17.7,26.9c0-1.4,1.1-2.5,2.5-2.5h4v8.1c0,1.4,1.1,2.5,2.5,2.5H38c1.4,0,2.5-1.1,2.5-2.5v-8.1h3.3c1.4,0,2.5,1.1,2.5,2.5
 v7.7h2v-7.7c0-2.5-2-4.5-4.5-4.5h-3.3v-1.1c0-1.4-1.1-2.5-2.5-2.5H26.7c-1.4,0-2.5,1.1-2.5,2.5v1.1h-4c-2.5,0-4.5,2-4.5,4.5v7.7h2
 V26.9z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "oreillercarre"
    }
</script>

<style scoped>

</style>