import jsonFetch from "./jsonFetch";

const backendLogger = {
    async log(level, message, context) {
        const request = {level, message, context};
        try {
            await jsonFetch('/api/log', request);
        } catch (error) {
            consoleLogger.error(`${error.message} when trying to log message: ${message}`, {request, error});
        }
    },

    emergency(message, context) {
        this.log('emergency', message, context);
    },

    alert(message, context) {
        this.log('alert', message, context);
    },

    critical(message, context) {
        this.log('critical', message, context);
    },

    error(message, context) {
        this.log('error', message, context);
    },

    warning(message, context) {
        this.log('warning', message, context);
    },

    notice(message, context) {
        this.log('notice', message, context);
    },

    info(message, context) {
        this.log('info', message, context);
    },

    debug(message, context) {
        this.log('debug', message, context);
    },
};

const consoleLogger = {
    emergency(message, context) {
        this.error(message, context);
    },

    alert(message, context) {
        this.error(message, context);
    },

    critical(message, context) {
        this.error(message, context);
    },

    error(message, context) {
        console.error(message, context);
    },

    warning(message, context) {
        console.warn(message, context);
    },

    notice(message, context) {
        this.warning(message, context);
    },

    info(message, context) {
        console.info(message, context);
    },

    debug(message, context) {
        console.debug(message, context);
    },
};

export const logger = backendLogger;

export const registerGlobalErrorLogger = logger =>
    window.addEventListener('error', event =>
        logger.error(event.message, {
            filename: event.filename,
            lineno: event.lineno,
            colno: event.colno,
            stack: event.error?.stack,
        }));