<template>
    <span>
        {{ getTranslation('rgpd_mention') }}
    </span>
</template>

<script>
    export default {
        name: "RgpdMention"
    }
</script>

<style scoped>

</style>
