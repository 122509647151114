<template>
    <div>
        <NetworkSearchBar
            :regions="regions" :selected-region="selectedRegion" @region-change="setRegion"
            :types="types" @type-toggled="toggleType"
        />
        <div class="wrapBg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <Map ref="map" id="suppliersMap" :zoom="zoom" :center="center"
                             :locations="filteredLocations" @in-bounds-locations-changed="updateLocationsInBound"/>
                        <div class="blockLegendes">
                            <div class="title">{{ getTranslation('legend')}} :</div>
                            <div class="oneLegende">
                                <h4 class="">
                                    <img src="/img/icons/pointer.svg" style="max-width:18px" alt="">
                                    <span class="text-primary titleLegende">{{ getTranslation('collect_point') }}</span>
                                    <span class="desc">{{ getTranslation('laundry.available') }}</span>
                                </h4>
                            </div>
                            <div class="oneLegende">
                                <h4 class="">
                                    <img src="/img/icons/pointerBlueLight.svg" style="max-width:18px" alt="">
                                    <span class="text-blueLight titleLegende">{{ getTranslation('home_only') }}</span>
                                    <span class="desc">{{ getTranslation('address.delivery_choice') }}</span>
                                </h4>
                            </div>
                            <div class="oneLegende">
                                <h4 class="">
                                    <img src="/img/icons/pointerGreen.svg" style="max-width:18px" alt="">
                                    <span class="text-green titleLegende">{{ getTranslation('soon') }}</span>
                                    <span class="desc">{{ getTranslation('future_location') }}</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <PlacesList :locations="inBoundsLocations"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NetworkSearchBar from "./NetworkSearchBar";
    import PlacesList from "./PlacesList";
    import Map from "./Map";

    export default {
        name: "NetworkMap",
        components: {Map, PlacesList, NetworkSearchBar},
        props: {
            locations: Array
        },
        data() {
            const regions = Array.from(new Set(this.locations.map(location => location.regionName)));
            const sortedRegions = regions.sort(function(a, b){return a.localeCompare(b)});

            return {
                regions: sortedRegions.map(regionName => ({id: regionName, name: regionName})),
                selectedRegion: null,
                inBoundsLocations: this.locations,
                types: [
                    {
                        id: 'retrieval',
                        name: this.getTranslation('delivery.retrieval', {}, true),
                        selected: true
                    },
                    {
                        id: 'homeDelivery',
                        name: this.getTranslation('delivery.home_delivery', {}, true),
                        selected: true,
                    },
                    {
                        id: 'soon',
                        name: this.getTranslation('delivery.soon', {}, true),
                        selected: true
                    },
                ],
                center: {lat: 46.926108, lng: 2.394401},
                zoom: 6
            }
        },
        methods: {
            updateLocationsInBound($event) {
                this.inBoundsLocations = $event;
            },
            setRegion(region) {
                this.selectedRegion = region;

                setTimeout(() => {
                    const map = this.$refs.map;

                    if ([this.locations.length, 0].includes(map.markers.length)) {
                        const {lat, lng} = this.center;
                        map.map.setCenter(new map.google.maps.LatLng(lat, lng));
                        map.map.setZoom(this.zoom);
                    } else {
                        const bounds = new map.google.maps.LatLngBounds();
                        map.markers.forEach(marker => bounds.extend(marker.position));
                        const zoomChangeBoundsListener = map.google.maps.event.addListener(map.map, 'bounds_changed', function() {
                            if (this.getZoom() > 12) {
                                this.setZoom(12);
                            }

                            map.google.maps.event.removeListener(zoomChangeBoundsListener);
                        });
                        map.map.fitBounds(bounds);
                    }
                }, 200);
            },
            toggleType(type) {
                type.selected = !type.selected;
            },
        },
        computed: {
            filteredLocations() {
                const types = this.types.filter(type => type.selected).map(type => type.id);
                return this.locations.filter(location => {
                    return (
                        (!this.selectedRegion || location.regionName === this.selectedRegion.id)
                        && types.includes(location.type)
                    );
                });
            },

        }
    }
</script>

<style scoped>

</style>
