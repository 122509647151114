<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgServietteTable" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M22.6,9.3v46.2c0,1.9,1.6,3.5,3.5,3.5h0.1c1.9,0,3.5-1.6,3.5-3.5V5.8h-3.6C24.1,5.8,22.6,7.4,22.6,9.3z M27.7,55.5
 c0,0.8-0.7,1.5-1.5,1.5h-0.1c-0.8,0-1.5-0.7-1.5-1.5V36.6c0.4,0.2,0.9,0.4,1.6,0.4c0.6,0,1-0.4,1-1s-0.4-1-1-1
 c-1.5,0-1.6-2.4-1.6-2.6V9.3c0-0.8,0.7-1.5,1.5-1.5h1.6V55.5z"/>
  <path d="M20.1,6.6c-0.6,0-1,0.4-1,1v1.3c0,0,0,0,0,0v12.6c0,0.6-0.4,1-1,1s-1-0.4-1-1V9c0,0,0-0.1,0-0.1V7.6c0-0.6-0.4-1-1-1
 s-1,0.4-1,1v1.3c0,0,0,0,0,0v12.6c0,0.6-0.4,1-1,1s-1-0.4-1-1V9c0,0,0-0.1,0-0.1V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V23
 c0,2.2,0.7,3.4,1.2,4.1c0.2,0.3,0.4,0.6,0.4,0.7v27.6c0,2,1.5,3.6,3.5,3.6c1.9,0,3.4-1.7,3.4-3.8V27.7c0-0.4,0.2-0.8,0.5-1.3
 c0.4-0.8,1-1.7,1-3.1V7.6C21.1,7,20.7,6.6,20.1,6.6z M18.4,25.4c-0.4,0.6-0.8,1.4-0.8,2.3v27.6c0,0.9-0.5,1.8-1.4,1.8
 c-0.9,0-1.5-0.7-1.5-1.6V27.8c0-0.7-0.3-1.2-0.6-1.8c-0.3-0.4-0.5-0.9-0.7-1.6h5.5C18.8,24.8,18.6,25.1,18.4,25.4z"/>
  <path d="M50.8,27.9V17.5c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1L43.3,6C43,5.5,42,5.5,41.7,6
 l-7.3,10.9c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1v10.4c-1.1,0.3-2,1.3-2,2.4v3.2
 c0,1.2,0.8,2.2,2,2.4v11.6c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1l7.3,10.9
 c0.2,0.3,0.5,0.4,0.8,0.4s0.6-0.2,0.8-0.4l7.3-10.9c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1V36
 c1.1-0.2,2-1.3,2-2.5v-3.2C52.8,29.2,51.9,28.2,50.8,27.9z M42.5,8.4l1.1,1.6l-5.8,8l-0.9-1.1c0,0,0,0,0,0L42.5,8.4z M36.2,19.2
 l3.3,4c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c0.4-0.4,0.5-1,0.1-1.4l-2-2.4l5.7-7.8l3.5,5.2l-9.2,10.9h-2.9V19.2z M48.8,19.3v8.5h-7.1L48.8,19.3z M42.5,56.8L36.6,48c0.1-0.1,0.2-0.1,0.2-0.2l0.9-1.2l6,8.3L42.5,56.8z M44.9,53.1L39,44.9l2.1-2.8
 c0.3-0.4,0.2-1.1-0.2-1.4c-0.4-0.3-1.1-0.2-1.4,0.2l-3.3,4.5v-9.3h3.4l8.6,11.7c0,0.1,0.1,0.1,0.1,0.1L44.9,53.1z M48.8,45.2
 l-6.7-9.1h6.7V45.2z M50.8,33.6c0,0.3-0.2,0.5-0.5,0.5H34.7c-0.3,0-0.5-0.2-0.5-0.5v-3.2c0-0.3,0.2-0.5,0.5-0.5h15.6
 c0.3,0,0.5,0.2,0.5,0.5V33.6z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "serviettetable"
    }
</script>

<style scoped>

</style>