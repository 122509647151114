import SessionStorageStore from "./SessionStorageStore";

const store = new SessionStorageStore('deliverySelected');

class DeliverySelectedStore {
    static get() {
        return store.get();
    }

    static set(deliveryMode) {
        return store.set(deliveryMode);
    }
}
export default DeliverySelectedStore