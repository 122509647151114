<template>
    <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            style="enable-background:new 0 0 64 64;"
            xml:space="preserve"
            inkscape:version="0.91 r13725"
            sodipodi:docname="el_couette s.svg"><metadata><rdf:RDF><cc:Work
         rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title/></cc:Work></rdf:RDF></metadata>
        <defs/>
        <sodipodi:namedview
                pagecolor="#ffffff"
                bordercolor="#666666"
                borderopacity="1"
                objecttolerance="10"
                gridtolerance="10"
                guidetolerance="10"
                inkscape:pageopacity="0"
                inkscape:pageshadow="2"
                inkscape:window-width="2560"
                inkscape:window-height="1417"
                showgrid="false"
                inkscape:zoom="5.2149125"
                inkscape:cx="-23.975545"
                inkscape:cy="18.849047"
                inkscape:window-x="-8"
                inkscape:window-y="-8"
                inkscape:window-maximized="1"
                inkscape:current-layer="Calque_1"/>
        <g
                transform="translate(-1.454502,-7.3440665)"><path
       sodipodi:nodetypes="cccsssscssccsscssccscccsssssssss"
       d="m 30.325424,18.767797 c 0,-0.8 0.7,-1.5 1.5,-1.5 L 32.8,17.2 l 0,4.6 c 0,1.4 1.1,2.5 2.5,2.5 l 9,0 c 1.4,0 2.5,-1.1 2.5,-2.5 l 0,-4.6 0.9,0 c 0.8,0 1.5,0.7 1.5,1.5 l 0.112829,14.542294 1.881356,1.254238 L 51.2,18.7 c 6.99e-4,-1.9 -1.6,-3.5 -3.5,-3.5 l -1,0 C 46.5,14 45.5,13.1 44.2,13.1 l -9,0 C 34,13.1 33,14 32.7,15.2 l -1.174576,0.0678 c -1.9,0 -3.5,1.6 -3.5,3.5 l 0,5.5 2.271186,0 z M 34.8,15.7 c 0,-0.3 0.2,-0.5 0.5,-0.5 l 9,0 c 0.3,0 0.5,0.2 0.5,0.5 l 0,6.1 c 0,0.3 -0.2,0.5 -0.5,0.5 l -9,0 c -0.3,0 -0.5,-0.2 -0.5,-0.5 z"
       inkscape:connector-curvature="0" />
            <path
                    d="m 31.861328,25.976562 -5.691406,0.09375 0,11.220704 a 15,15 0 0 1 1.589844,-0.103516 15,15 0 0 1 15,15 15,15 0 0 1 -1.873047,7.253906 l 8.804687,-0.04102 C 51.263992,59.393112 52.5,57.570333 52.5,55.242188 l 0.04883,-19.523438 -1.308594,-0.619141 -12.974609,1.875 c -4.568214,0.660563 -6.336576,-0.635579 -6.353516,-3.228515 l -0.05078,-7.769532 z m 2.337891,1.0625 0.15039,6.492188 c 0.01549,0.929434 1.312179,1.85673 2.632813,1.677734 l 11.263672,-1.52539 -14.046875,-6.644532 z"
                    inkscape:connector-curvature="0"/>
            <g transform="translate(-21.338983,4.6881332)"/>
            <g transform="translate(61.116108,49.069489)"><path
         inkscape:connector-curvature="0"
         d="m -33.355932,-10.281356 c -7.4,0 -13.4,6 -13.4,13.4 0,7.4 6,13.4 13.4,13.4 7.4,0 13.4,-6 13.4,-13.4 0,-7.4 -6,-13.4 -13.4,-13.4 z m 0,24.8 c -6.3,0 -11.4,-5.1 -11.4,-11.4 0,-6.3 5.1,-11.4 11.4,-11.4 6.3,0 11.4,5.1 11.4,11.4 0,6.3 -5.1,11.4 -11.4,11.4 z"
         />
                <path
                        inkscape:connector-curvature="0"
                        d="m -30.055932,2.918644 c -0.3,-0.2 -0.7,-0.4 -1.2,-0.6 -0.5,-0.2 -1,-0.3 -1.5,-0.4 -0.5,-0.1 -0.8,-0.2 -1,-0.3 -0.2,-0.1 -0.4,-0.1 -0.6,-0.2 -0.2,-0.1 -0.3,-0.2 -0.5,-0.4 -0.2,-0.19999996 -0.2,-0.29999996 -0.2,-0.49999996 0,-0.3 0.2,-0.6 0.5,-0.8 0.3,-0.2 0.7,-0.3 1.3,-0.3 0.6,0 1,0.1 1.2,0.3 0.3,0.2 0.5,0.5 0.7,0.9 0.1,0.3 0.3,0.49999996 0.4,0.59999996 0.1,0.1 0.3,0.2 0.5,0.2 0.3,0 0.5,-0.1 0.6,-0.3 0.1,-0.19999996 0.3,-0.39999996 0.3,-0.69999996 0,-0.3 -0.1,-0.5 -0.2,-0.8 -0.1,-0.3 -0.4,-0.5 -0.6,-0.80000004 -0.3,-0.3 -0.7,-0.5 -1.1,-0.6 -0.4,-0.2 -1,-0.2 -1.6,-0.2 -0.8,0 -1.4,0.1 -2,0.3 -0.6,0.2 -1,0.6 -1.3,1.00000004 -0.3,0.4 -0.5,0.9 -0.5,1.5 0,0.59999996 0.1,1.09999996 0.4,1.49999996 0.3,0.4 0.7,0.7 1.2,0.9 0.5,0.2 1.1,0.4 1.8,0.6 0.5,0.1 1,0.2 1.3,0.3 0.3,0.1 0.6,0.3 0.8,0.5 0.2,0.2 0.3,0.5 0.3,0.8 0,0.4 -0.2,0.8 -0.6,1 -0.4,0.2 -0.9,0.4 -1.5,0.4 -0.4,0 -0.8,-0.1 -1.1,-0.2 -0.3,-0.1 -0.5,-0.3 -0.6,-0.5 -0.1,-0.2 -0.3,-0.5 -0.4,-0.8 -0.1,-0.3 -0.2,-0.5 -0.4,-0.6 -0.1,-0.1 -0.3,-0.2 -0.5,-0.2 -0.3,0 -0.5,0.1 -0.7,0.3 -0.2,0.2 -0.2,0.2 -0.2,0.4 0,0.4 0.1,0.9 0.4,1.3 0.3,0.4 0.7,0.8 1.1,1.1 0.6,0.4 1.5,0.6 2.4,0.6 0.8,0 1.5,-0.1 2.1,-0.4 0.6,-0.3 1.1,-0.7 1.4,-1.1 0.3,-0.5 0.5,-1 0.5,-1.6 0,-0.5 -0.1,-0.9 -0.3,-1.3 -0.1,-0.4 -0.3,-0.7 -0.6,-0.9 z"
                /></g></g></svg>

</template>

<script>
    export default {
        name: "couette-s"
    }
</script>

<style scoped>

</style>