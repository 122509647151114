<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgTapisBain" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <path d="M23.1,48.2c0,0.6,0.4,1,1,1h15.8c0.6,0,1-0.4,1-1s-0.4-1-1-1H24.1C23.6,47.2,23.1,47.7,23.1,48.2z"/>
  <path d="M23.1,14.9c0,0.6,0.4,1,1,1h15.8c0.6,0,1-0.4,1-1s-0.4-1-1-1H24.1C23.6,13.9,23.1,14.3,23.1,14.9z"/>
  <path d="M27.1,22.2c-0.3,0.9-0.5,1.8-0.5,2.7c0,0.7,0,1.1,0.2,1.5c0.2,0.5,0.5,0.8,1,1c0.9,0.2,1.7,0.1,2.4-0.6
 c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.8-0.8,1.3-0.9c1-0.4,1.9-0.3,2.7,0.3c0.4,0.3,0.7,0.6,1.1,0.9c0.4,0.3,0.7,0.6,1.2,0.7
 c0.6,0.2,1.3,0.2,1.9,0.1c0.5-0.1,0.9-0.3,1.3-0.7c0.6-0.8,0.7-1.7,0.5-2.6c-0.2-0.8-0.8-1.3-1.6-1.6c-0.4-0.2-0.9-0.2-1.4-0.2
 c-0.7,0-1.5-0.2-2.1-0.4c-0.7-0.3-1.4-0.6-2.1-1c-1.1-0.5-2.1-0.8-3.3-0.8c-0.7,0-1.4,0.2-1.9,0.6C27.9,20.5,27.4,21.3,27.1,22.2z"
 />
  <path d="M25.3,26.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.6,0-1.2,0.2-1.6,0.6c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.5,0.3,0.8,0.7,0.9
 c0.8,0.4,2,0.2,2.6-0.5C25.8,27.1,25.7,26.5,25.3,26.1z"/>
  <path d="M25.1,23.7c-0.3-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.7,0.1-1,0.3c-0.4,0.3-0.4,0.8,0,1.1c0.5,0.5,1.7,0.3,2.1-0.2
 C25.5,24.3,25.4,24,25.1,23.7z"/>
  <path d="M25.4,21.8c-0.2-0.2-0.5-0.3-0.8-0.3c0,0,0,0,0,0c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.4,0.5
 c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.5,0.2c0.4,0.1,0.7,0,1-0.2C25.7,22.5,25.7,22.1,25.4,21.8z"/>
  <path d="M26.1,20.7c-0.3-0.3-0.9-0.5-1.2-0.3c-0.3,0.2-0.4,0.4-0.2,0.7c0.2,0.3,0.5,0.3,0.9,0.4c0.1,0,0.2,0,0.4-0.1
 C26.3,21.3,26.3,21,26.1,20.7z"/>
  <path d="M27,20.2c0.1-0.1,0.1-0.4,0-0.5c-0.2-0.1-0.3-0.2-0.6-0.2c0,0,0,0,0,0c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.2-0.2,0.4,0,0.6
 C26.3,20.5,26.8,20.5,27,20.2z"/>
  <path d="M26.8,35.8c-0.2,0.5-0.2,0.9-0.2,1.5c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.8,1.7,1.6,2.2c0.6,0.4,1.2,0.6,1.9,0.6
 c1.2,0,2.3-0.3,3.3-0.8c0.7-0.3,1.4-0.7,2.1-1c0.7-0.3,1.4-0.4,2.1-0.5c0.5,0,0.9,0,1.4-0.2c0.8-0.3,1.4-0.7,1.6-1.6
 c0.2-0.9,0.1-1.8-0.5-2.6c-0.3-0.4-0.8-0.6-1.3-0.7c-0.6-0.1-1.3-0.1-1.9,0.1c-0.4,0.2-0.8,0.4-1.2,0.7c-0.3,0.3-0.7,0.6-1.1,0.9
 c-0.8,0.6-1.8,0.7-2.7,0.3c-0.5-0.2-0.9-0.5-1.3-1c-0.4-0.4-0.8-0.9-1.2-1.2c-0.7-0.6-1.5-0.8-2.4-0.6C27.3,35,26.9,35.3,26.8,35.8
 z"/>
  <path d="M22.1,35.1c0,0.3,0.2,0.6,0.4,0.8c0.5,0.4,1,0.6,1.6,0.6c0.5,0,0.9-0.1,1.2-0.4c0.4-0.4,0.5-1,0-1.4
 c-0.6-0.6-1.8-0.9-2.6-0.5C22.3,34.4,22,34.7,22.1,35.1z"/>
  <path d="M25.2,37.5c-0.4-0.6-1.5-0.7-2.1-0.2c-0.4,0.3-0.4,0.8,0,1.1c0.3,0.2,0.7,0.3,1,0.3c0.4,0,0.7-0.1,0.9-0.3
 C25.4,38.2,25.5,37.9,25.2,37.5z"/>
  <path d="M25.4,40.4c0.3-0.3,0.3-0.6,0-0.9c-0.3-0.2-0.7-0.3-1-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.2,0.1-0.3,0.3-0.3,0.5
 c0,0.3,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.1,0.7,0.2c0,0,0,0,0,0C24.9,40.6,25.2,40.6,25.4,40.4z"/>
  <path d="M26,40.8c-0.1-0.1-0.3-0.1-0.4-0.1c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.1,0.6,0.2,0.7c0.4,0.2,1,0,1.2-0.3
 C26.3,41.3,26.3,41,26,40.8z"/>
  <path d="M27,42.5c0.1-0.1,0.1-0.4,0-0.5c-0.3-0.3-0.8-0.3-1.1-0.1c-0.2,0.2-0.2,0.5,0,0.6c0.1,0.1,0.3,0.1,0.5,0.2c0,0,0,0,0,0
 C26.7,42.7,26.9,42.7,27,42.5z"/>
  <path d="M23.8,7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V10h-1.2c-0.2,0-0.3,0-0.5,0.1V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1v4.8c0,0,0,0,0,0.1
 c0,0,0,0,0,0.1v39c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1v4.8c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.5c0.2,0,0.3,0.1,0.5,0.1h1.2v2.4
 c0,0.6,0.4,1,1,1s1-0.4,1-1V54h1.7v2.4c0,0.6,0.4,1,1,1s1-0.4,1-1V54h1.7v2.4c0,0.6,0.4,1,1,1s1-0.4,1-1V54h1.7v2.4
 c0,0.6,0.4,1,1,1s1-0.4,1-1V54h1.7v2.4c0,0.6,0.4,1,1,1s1-0.4,1-1V54h1.7v2.4c0,0.6,0.4,1,1,1s1-0.4,1-1V54h1.2c0.2,0,0.3,0,0.5,0
 v2.5c0,0.6,0.4,1,1,1s1-0.4,1-1v-4.8c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2v-39c0-0.1,0-0.1,0-0.1V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V10
 c-0.2,0-0.3,0-0.5,0h-1.2V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V10h-1.7V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V10h-1.7V7.6c0-0.6-0.4-1-1-1
 s-1,0.4-1,1V10h-1.7V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V10h-1.7V7.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V10h-1.7V7.6z M43.4,12
 c0.3,0,0.5,0.2,0.5,0.5v39c0,0.3-0.2,0.5-0.5,0.5H20.6c-0.3,0-0.5-0.2-0.5-0.5v-39c0-0.3,0.2-0.5,0.5-0.5H43.4z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "tapisbain"
    }
</script>

<style scoped>

</style>