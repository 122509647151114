<template>

<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" class="svg svgKit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g>
  <polygon points="28.9,16.6 20,16.6 19.8,18.6 29.2,18.6   "/>
  <path d="M17.4,24.4c0.2-3.8,0.9-13.1,4.3-16.5c0.8-0.8,1.7-1.2,2.8-1.2s1.9,0.4,2.8,1.2c3.5,3.5,4.2,12.7,4.3,16.5l0,0
 c0.8-0.2,1.2-0.2,2-0.4c-0.2-4.3-1-13.6-4.9-17.5c-1.2-1.2-2.6-1.8-4.2-1.8s-3,0.6-4.2,1.8c-4,4-4.8,13.9-4.9,17.9
 c-0.6,0.3-1.1,1-1.1,1.8c0,1.1,0.9,2,2,2s2-0.9,2-2C18.4,25.4,18,24.8,17.4,24.4z"/>
  <path d="M56.9,39.9c0.8-0.5,1.6-1.4,1.9-3.1c0.9-4.8-3.2-7.3-5.5-7.9L41,25.2c-0.1,0-1.2-0.4-2.2,0c0,0,0,0,0,0c0,0,0,0,0,0
 l-15.1,5.4c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.3-1.2,0.9-1.4,2.2c-0.2,0.9,0,1.7,0.5,2.4c0.1,0.2,0.3,0.4,0.4,0.5
 c-0.9,0.6-1.7,1.6-1.9,3.1c-0.4,2.7,0.1,4.6,1.5,5.9c-0.8,0.7-1.3,1.7-1.5,3c-0.6,4,0.7,6.2,4.4,7.4l12.2,3.8
 c0.1,0,0.9,0.3,1.8,0.3c0.4,0,0.7,0,1.1-0.1c0,0,0,0,0.1,0l0.1,0c0,0,0,0,0.1,0l15-5.4c0,0,0.1,0,0.1-0.1c0.7-0.3,1.2-0.9,1.3-1.7
 c0.3-1.2,0.1-2.2-0.6-3c0.9-0.4,1.8-1.3,2.1-3.2C59.2,43.5,58.5,41.5,56.9,39.9z M41.7,45.5l12.8-4.6c0.9,0.5,0.9,0.9,0.8,1.7
 c0,0.1-0.1,0.2-0.2,0.3l-12.7,4.6C42.4,46.7,42.2,46,41.7,45.5z M42.7,27.8l-6,2.3c-0.5,0.2-0.8,0.8-0.6,1.3
 c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.2,0,0.4-0.1l8.4-3.2l6.8,2.1c0.2,0.1,4.9,1.4,4.1,5.6c-0.2,1.3-0.8,1.7-1.1,1.8l-1.4,0.5
 c0,0,0,0,0,0l-10.5,3.8c0-0.1,0.1-0.2,0.1-0.4c0.9-4.8-3.2-7.3-5.5-7.9L28,31.2L39.5,27c0.3-0.1,0.8,0,0.9,0L42.7,27.8z M23,39.2
 c0.3-2.2,2.1-2.2,2.6-2.1l12.2,3.8c0.5,0.2,1.1-0.1,1.3-0.7c0.2-0.5-0.1-1.1-0.7-1.3L26,35.2l-0.1,0c-0.3,0-1.4-0.4-1.8-1
 c-0.2-0.3-0.2-0.5-0.2-0.9c0.1-0.4,0.2-0.7,0.4-0.8c0.3-0.2,0.9-0.1,1.1,0l12.3,3.8c0.2,0.1,4.9,1.4,4.1,5.6
 c-0.2,0.9-0.5,1.4-1,1.7c-0.7,0.4-1.8,0.2-2.6,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L26,39.8c-0.5-0.2-1.1,0.1-1.3,0.7
 c-0.2,0.5,0.1,1.1,0.7,1.3l12.3,3.8c0,0,0,0,0,0c3,0.9,2.9,1.5,2.7,2.5c0,0.2-0.1,0.3-0.2,0.3c-0.4,0.2-1.3,0.1-1.9-0.1L26,44.4
 C23.2,43.6,22.5,42.3,23,39.2z M38.3,57.2L26,53.4c-2.8-0.9-3.5-2.1-3-5.2c0.3-2.1,1.4-2.1,1.7-2.1c0.3,0.1,0.5,0.2,0.8,0.3
 l12.2,3.8c0.1,0,0.9,0.3,1.8,0.3c0.4,0,0.7,0,1.1-0.1c0,0,0,0,0.1,0l0.1,0c0,0,0,0,0.1,0l15.1-5.4c0,0,0.1,0,0.1-0.1
 c0.4-0.2,0.7-0.5,0.9-0.8c0.1,0.5,0.1,1-0.1,1.5c-0.2,1.3-0.8,1.7-1.1,1.8l-1.4,0.5c0,0,0,0,0,0L39.6,53c-0.4-0.2-0.9-0.3-1.4-0.5
 c0,0,0,0,0,0c0,0,0,0,0,0L26,48.7c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.7,1.3l12.3,3.8h0c3,0.9,2.8,1.5,2.6,2.5
 c0,0.2-0.1,0.3-0.2,0.3C39.7,57.5,38.8,57.3,38.3,57.2z M54.5,49.8c0.9,0.5,0.9,0.9,0.8,1.7c0,0.1-0.1,0.2-0.2,0.3l-12.7,4.6
 c0-0.8-0.2-1.5-0.7-2L54.5,49.8z"/>
  <path d="M36.7,18.6c0.6,0,1.1,0.4,1.1,1l0.3,3.6c0.7-0.1,1.3-0.1,2-0.2l-0.3-3.5c-0.1-1.6-1.5-2.8-3.1-2.8h-2.1l0.3,2H36.7z"/>
  <path d="M9.5,42.9c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.8l2.9-22.1c0.1-0.6,0.5-1,1.1-1h1.9l0.3-2c0,0-0.1,0-0.1,0h-2.1
 c-1.6,0-2.9,1.2-3.1,2.7L6.4,41.4c-0.1,0.9,0.2,1.8,0.8,2.4c0.6,0.7,1.4,1,2.3,1h8.8c0-0.6,0-1.3,0-2H9.5z"/>
</g>
</svg>

</template>

<script>
    export default {
        name: "kit"
    }
</script>

<style scoped>

</style>